import * as React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import { SensorConnection } from "../../Components/AssetForms/SensorConnection";
import { SensorLocation } from "../../Components/AssetForms/SensorLocation";
import { DoorInformation } from "../../Components/AssetForms/DoorInformation";
import { SensorEnabler } from "../../Components/AssetForms/SensorEnable";
import { SensorReceive } from "../../Components/AssetForms/SensorReceive";
import { GapCheck } from "../../Components/AssetForms/GapCheck";
import { Confirm } from "../../Components/AssetForms/Confirm";
import { getSite } from "../../Store/Features/Sites";
import { getFloor } from "../../Store/Features/Floors";
import { getDoor, updateDoor } from "../../Store/Features/Door";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { Paper } from "@mui/material";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { addSensor, updateSensor } from "../../Store/Features/Sensors";
import Check from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { getCodesByQuery } from "../../Store/Features/device_codes";
import { toast } from "react-toastify";

const steps = [
  "Select Door Device",
  "Sensor Mounting",
  "Door Information",
  "Gap Check",
  "Sensor Enable",
  "Sensor Receive",
  
  "Confirm",
];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const qrCodeRegex = /^HF-\d{3}-\d{4}-\d{10}$/;
const nfcCode = /^[A-Z]{2}\d{16}$/;

export default function AddAsset() {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const { siteId, assetId, floorId } = useParams();
  const loading1 = useSelector((state) => state.doors.loading);
  const loading = useSelector((state) => state.sensors.loading);
  const loading2 = useSelector((state) => state.codes.loading);

  const [commission, setCommission] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState({
    site: null,
    floor: null,
    door: null,
  });

  const [form, setForm] = useState({
    qrCode: "",
    nfcCode: "",
    location: "",
    width: "",
    height: "",
    direction: "",
    top_left_measurement: "",
    top_right_measurement: "",
    hinge_top_measurement: "",
    swing_top_measurement: "",
    bottom_right_measurement: "",
    hinge_bottom_measurement: "",
    swing_bottom_measurement: "",
    bottom_left_measurement: "",
    rfidCode: "",
    doorId: "",
    sensor_code: "",
    sensorId: "",
    sensor: {},
    closed: false,
    fit: false,
  });

  useEffect(() => {
    if (data.door) {
      setForm((prev) => ({
        ...prev,
        ["width"]: data.door?.width,
        ["height"]: data.door?.height,
        ["direction"]: data.door?.direction,
        ["top_left_measurement"]: data.door?.top_left_measurement,
        ["top_right_measurement"]: data.door?.top_right_measurement,
        ["hinge_top_measurement"]: data.door?.hinge_top_measurement,
        ["swing_top_measurement"]: data.door?.swing_top_measurement,
        ["hinge_bottom_measurement"]: data.door?.hinge_bottom_measurement,
        ["swing_bottom_measurement"]: data.door?.swing_bottom_measurement,
        ["bottom_left_measurement"]: data.door?.bottom_left_measurement,
        ["bottom_right_measurement"]: data.door?.bottom_right_measurement,
      }));
    }
    
  }, [data.door]);

  const [qrCodeValid, setQrCodeValid] = useState(false);
  const [nfcCodeValid, setnfcCodeValid] = useState(false);

  const totalSteps = () => {
    return steps.length;
  };

  useEffect(()=>{
    if(activeStep == 5) {
      if(commission) {
        
        dispatch(
          updateSensor({
            id: form.sensorId,
            data: {
              ...form.sensor,
              location: form.location,
              door: form.sensor.door.id,
              commission_flag: 1,
            },
          })
        ).then((res) => {
          if (!res.error) {
            const newActiveStep =
              isLastStep() && !allStepsCompleted()
                ? // steps.findIndex((step, i) => !(i in completed));
                  navigate("/")
                : activeStep + 1;
            setActiveStep(newActiveStep);
          }
        });
      }
    }
  },[commission])

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    if (activeStep == 1) {
      dispatch(
        updateSensor({
          id: form.sensorId,
          data: {
            ...form.sensor,
            location: form.location,
            door: form.sensor.door.id,
            commission_flag: 0,
          },
        })
      ).then((res) => {
        if (!res.error) {
          const newActiveStep =
            isLastStep() && !allStepsCompleted()
              ? // steps.findIndex((step, i) => !(i in completed));
                navigate("/")
              : activeStep + 1;
          setActiveStep(newActiveStep);
        }
      });
    }
    if (activeStep == 2) {
      dispatch(
        updateDoor({
          id: data.door.id,
          data: {
            description: form.doorId,
            direction: form.direction,
            height: form.height,
            width: form.width,
            length: form.length,
            top_left_measurement: form.top_left_measurement,
            top_right_measurement: form.top_right_measurement,
            hinge_top_measurement: form.hinge_top_measurement,
            swing_top_measurement: form.swing_top_measurement,
            hinge_bottom_measurement: form.hinge_bottom_measurement,
            swing_bottom_measurement: form.swing_bottom_measurement,
            bottom_left_measurement: form.bottom_left_measurement,
            bottom_right_measurement: form.bottom_right_measurement,
            assignee: data.door.assigneeId,
            floor: data.door.floorId,
          },
        })
      ).then((res) => {
        if (!res.error) {
          const newActiveStep =
            isLastStep() && !allStepsCompleted()
              ? // steps.findIndex((step, i) => !(i in completed));
                navigate("/")
              : activeStep + 1;
          setActiveStep(newActiveStep);
        }
      });
    }
    if (activeStep == 6) {
      dispatch(
        updateDoor({
          id: data.door.id,
          data: {
            description: form.doorId,
            direction: form.direction,
            height: form.height,
            width: form.width,
            length: form.length,
            top_left_measurement: form.top_left_measurement,
            top_right_measurement: form.top_right_measurement,
            hinge_top_measurement: form.hinge_top_measurement,
            swing_top_measurement: form.swing_top_measurement,
            hinge_bottom_measurement: form.hinge_bottom_measurement,
            swing_bottom_measurement: form.swing_bottom_measurement,
            bottom_left_measurement: form.bottom_left_measurement,
            bottom_right_measurement: form.bottom_right_measurement,
            door_image: null,
            assignee: data.door.assigneeId,
            floor: data.door.floorId,
          },
        })
      ).then((res) => {
        if (!res.error) {
          const newActiveStep =
            isLastStep() && !allStepsCompleted()
              ? // steps.findIndex((step, i) => !(i in completed));
                navigate("/")
              : activeStep + 1;
          setActiveStep(newActiveStep);
        }
      });
    }
    if (activeStep == 3) {
      dispatch(
        updateDoor({
          id: data.door.id,
          data: {
            description: form.doorId,
            direction: form.direction,
            height: form.height,
            width: form.width,
            length: form.length,
             top_left_measurement: form.top_left_measurement,
            top_right_measurement: form.top_right_measurement,
            hinge_top_measurement: form.hinge_top_measurement,
            swing_top_measurement: form.swing_top_measurement,
            hinge_bottom_measurement: form.hinge_bottom_measurement,
            swing_bottom_measurement: form.swing_bottom_measurement,
            bottom_left_measurement: form.bottom_left_measurement,
            bottom_right_measurement: form.bottom_right_measurement,
            door_image: null,
            assignee: data.door.assigneeId,
            floor: data.door.floorId,
          },
        })
      ).then((res) => {
        if (!res.error) {
          const newActiveStep =
            isLastStep() && !allStepsCompleted()
              ? // steps.findIndex((step, i) => !(i in completed));
                navigate("/")
              : activeStep + 1;
          setActiveStep(newActiveStep);
        }
      });
    }
    if (
      activeStep != 0 ||
      (activeStep != 1 && activeStep != 2 && activeStep != 5 && activeStep != 6)
    ) {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // steps.findIndex((step, i) => !(i in completed));
            navigate("/")
          : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  useEffect(() => {
    dispatch(getSite(siteId)).then((res) => {
      setData((prev) => ({
        ...prev,
        site: res.payload,
      }));
    });
    dispatch(getFloor(floorId)).then((res) => {
      setData((prev) => ({
        ...prev,
        floor: res.payload,
      }));
    });
    dispatch(getDoor(assetId)).then((res) => {
      setForm((prev) => ({
        ...prev,
        doorId: res.payload.description,
      }));
      setData((prev) => ({
        ...prev,
        door: res.payload,
      }));
    });
  }, []);
  useEffect(() => {
    if (qrCodeRegex.test(form.qrCode)) {
      setQrCodeValid(true);
    }
  }, [form.qrCode]);

  useEffect(() => {
    if (nfcCode.test(form.nfcCode)) {
      setnfcCodeValid(true);
    }
  }, [form.nfcCode]);

  useEffect(() => {
    document.title = "Sensor Commissioning";
  }, []);

  return (
    <Box
      className="commossoining"
      sx={{
        display: "flex",
        justifyContent: "center",
        aligItems: "center",
        height: "100%",
        gap: "15px",
        padding: "0px 15px",
      }}
    >
      <Paper
        className="commissioningPaper"
        sx={{ width: "70%", height: "500px" }}
      >
        <div className="StepperTitle">
          <Stepper nonLinear activeStep={activeStep}>
            {steps?.map((label, index) => (
              <Step
                key={label}
                completed={completed[index]}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <StepButton
                  color="inherit"
                  onClick={ completed[index] ? handleStep(index) : (() => { return false;}) }
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <Headings>{label}</Headings>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="innerContainer">
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 && (
                <React.Fragment>
                  <SensorConnection
                    data={data}
                    form={form}
                    setForm={setForm}
                    nfcCodeValid={nfcCodeValid}
                    qrCodeValid={qrCodeValid}
                  />
                </React.Fragment>
              )}
              {activeStep === 1 && (
                <React.Fragment>
                  <SensorLocation data={data} form={form} setForm={setForm} />
                </React.Fragment>
              )}
              {activeStep === 2 && (
                <React.Fragment>
                  <DoorInformation data={data} form={form} setForm={setForm} />
                </React.Fragment>
              )}
              {activeStep === 4 && (
                <React.Fragment>
                  <SensorEnabler data={data} form={form} setForm={setForm} />
                </React.Fragment>
              )}
              {activeStep === 5 && (
                <React.Fragment>
                <SensorReceive setActiveStep= {setActiveStep} data={data} form={form} setForm={setForm} check = {commission} setCheck={setCommission} />
                </React.Fragment>
              )}
              {activeStep === 3 && (
                <React.Fragment>
                  <GapCheck data={data} form={form} setForm={setForm} />
                </React.Fragment>
              )}
              {activeStep === 6 && (
                <React.Fragment>
                  <Confirm data={data} form={form} setForm={setForm} />
                </React.Fragment>
              )}

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <button
                  className="button customButtomOverAll"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Back
                </button>
                <Box sx={{ flex: "1 1 auto" }} />
                <button
                  className="button customButtomOverAll"
                  onClick={handleNext}
                  disabled={activeStep == 5 ? !commission : false}
                >
                  {loading1 ? (
                    <CircularProgress />
                  ) : loading ? (
                    <CircularProgress />
                  ) : loading2 ? (
                    <CircularProgress />
                  ) : activeStep == 6 ? (
                    "Finish"
                  ) : (
                    "Next"
                  )}
                </button>
              </Box>
            </React.Fragment>
          )}
        </div>
      </Paper>
      <Paper
        className="detailPaper"
        sx={{
          width: "30%",
          height: "500px",
          padding: "15px 17px",
          boxSizing: "border-box",
        }}
      >
        <div className="detail">
          <Headings textTransform={"capitalize"}>
            {data?.site?.organization?.name}
          </Headings>
          <Headings>{data.site?.name}</Headings>
          <Headings>Floor {data.floor?.floor_no}</Headings>
          <Headings>{data.door?.description}</Headings>
          <Headings>{form.sensor_code}</Headings>
        </div>
      </Paper>
    </Box>
  );
}

