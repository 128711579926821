import React from 'react';
// import jsCookie from 'js-cookie';
import {Navigate, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";


const bypassRoles = [process.env.REACT_APP_ADMIN_ROLE_ID];
function matchPathToRoute(path, routes) {
    for (const routePath of routes) {
        const regexPattern = routePath
            .replace(/:[^/]+/g, '([^/]+)')
            .replace(/\//g, '\\/')
            .replace(/\*/g, '.*')
            // This is for optional parameters
            // .replace(/\?/g, '(?:\\/([^/]+))?');

        const regex = new RegExp(`^${regexPattern}$`);
        const match = path.match(regex);

        if (match) {
            // Extract any dynamic parameters from the path
            const params = match.slice(1);// .filter(param => param !== undefined);
            return { routePath, params };
        }
    }
    return null; // No match found
}

const ProtectedRouteRole = ({ children }) => {

    // const token = jsCookie.get('token');
    const user = useSelector(state => state.auth.user);
    let location = useLocation().pathname;

    if( bypassRoles.includes(user.roleId) ){
        return children;
    }
    else{
        let routes = roleRoutes(user.roleId);
        if( routes.length > 0){
            if( matchPathToRoute(location, routes) ){
                return children;
            }
        }
    }
    return <Navigate to="/" state={{ from: location }} replace />;

    // if (!getRoutes(user.roleId,location)) {
    //     return <Navigate to="/" state={{ from: location }} replace />;
    // }
    // return children;

};

export default ProtectedRouteRole;

const roleRoutes = (role) => {
    switch(role) {
        case process.env.REACT_APP_USER_ROLE_ID:
            return ["/","/sites","/sites/:siteId","/sites/:siteId/floor/:floorId/asset/:assetId","/report","/doors","/webhooks","/profile","/sites/:siteId/floor/:floorId"]
        case process.env.REACT_APP_ORG_ADMIN_ROLE_ID:
            return ["/","/sites","/sites/:siteId","/sites/:siteId/floor/:floorId/asset/:assetId","/report","/doors","/webhooks","/profile","/users","/users/add","/users/edit/:slug","/sites/:siteId/floor/:floorId",
            "/organizations/:organizationId/users", "/organizations/:organizationId/users/add", "/organizations/:organizationId/users/:slug"]
        case process.env.REACT_APP_INSPECTION_ROLE_ID:
            return ["/","/profile","sites/:siteId/floor/:floorId/asset/:assetId"]
        case process.env.REACT_APP_AGENT_ROLE_ID:
            return ["/","/profile","/sites/:siteId/floor/:floorId/asset/:assetId/sensor/addSensor"]
        default:
            return []
    }
};

const getRoutes = (role,location) => {
    switch(role) {
        case process.env.REACT_APP_ADMIN_ROLE_ID:
            return true;
        // case process.env.REACT_APP_USER_ROLE_ID:
        //     return roleRoutes(role).includes(location)
        // case process.env.REACT_APP_ORG_ADMIN_ROLE_ID:
        //     return roleRoutes(role).includes(location)
        // case process.env.REACT_APP_INSPECTION_ROLE_ID:
        //     return roleRoutes(role).includes(location)
        // case process.env.REACT_APP_AGENT_ROLE_ID:
        //     return roleRoutes(role).includes(location)
        default:
            return roleRoutes(role).includes(location);
    }
}


export const AdminProtectedRoute = ({ children }) => {

  // const token = jsCookie.get('token');
  const user = useSelector(state => state.auth.user);
  let location = useLocation().pathname;

  
  if (!user.roleId === process.env.REACT_APP_ADMIN_ROLE_ID) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;

};

export const CustomerProtectedRoute = ({ children }) => {

    // const token = jsCookie.get('token');
    const user = useSelector(state => state.auth.user);
    let location = useLocation().pathname;
  
    
    if (!user.roleId === process.env.REACT_APP_USER_ROLE_ID) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
  
  };

export const OrgAdminProtectedRoute = ({ children }) => {

    // const token = jsCookie.get('token');
    const user = useSelector(state => state.auth.user);
    let location = useLocation().pathname;


    if (!user.roleId === process.env.REACT_APP_ORG_ADMIN_ROLE_ID) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;

};

export const InspectionProtectedRoute = ({ children }) => {

    // const token = jsCookie.get('token');
    const user = useSelector(state => state.auth.user);
    let location = useLocation().pathname;


    if (!user.roleId === process.env.REACT_APP_INSPECTION_ROLE_ID) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;

};

export const InstallProtectedRoute = ({ children }) => {

    // const token = jsCookie.get('token');
    const user = useSelector(state => state.auth.user);
    let location = useLocation().pathname;


    if (!user.roleId === process.env.REACT_APP_AGENT_ROLE_ID) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;

};