import { createSlice } from '@reduxjs/toolkit';
import { postApi, getApi, deleteApi, putApi, uploadService } from "../../Utils/serviceWorker";
import { createAsyncThunk } from '@reduxjs/toolkit';
import jsCookie from 'js-cookie';
import { toast } from 'react-toastify';

export const getNotes = createAsyncThunk(
  'notes/get',
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`notes`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getNote = createAsyncThunk(
  'note/get',
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`notes/${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addNote = createAsyncThunk(
  'note/add',
  async (credentials, thunkAPI) => {
    try {
      const response = await postApi(`notes`, true, { data: credentials });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateNote = createAsyncThunk(
  'note/update',
  async (credentials, thunkAPI) => {
    try {
      const response = await putApi(`notes/${credentials.id}`, true, credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteNote = createAsyncThunk(
  'note/delete',
  async (credentials, thunkAPI) => {
    try {
      const response = await deleteApi(`notes/${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


export const getNotesOfDoor = createAsyncThunk(
  'note/sensor',
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`notes?door=${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  value: [],

  loading: false,
  error: null
}

export const Notes = createSlice({
  name: 'Notes',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getNotesOfDoor.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getNotesOfDoor.fulfilled, (state, action) => {
      state.loading = false;
   
      state.value = action.payload.rows;
    });
    builder.addCase(getNotesOfDoor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;


    });
    builder.addCase(updateNote.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateNote.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Updated Notes Successfully");
    });
    builder.addCase(updateNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(addNote.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addNote.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Created Notes Successfully");
    });
    builder.addCase(addNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(deleteNote.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteNote.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Note Deleted successfully");
    });
    builder.addCase(deleteNote.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

  },
});