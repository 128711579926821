import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TextField, Button, Grid, MenuItem } from "@mui/material";
import { Headings } from "../../Shared/Fonts/Fonts";
import { getRole, updateRole, addRoles } from "../../Store/Features/Roles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

const AddRolePage = () => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.roles.loading);
  const navigate = useNavigate();
  const { slug } = useParams();
  const isEdit = slug !== undefined;
  const [formData, setFormData] = useState({
    title: "",
    permissions: ""
  });
  const [errors, setErrors] = useState({});



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    let valid = true;

    if (!formData.title.trim()) {
      newErrors.name = 'Name is required';
      valid = false;
    }

    if (!formData.permissions.trim()) {
      newErrors.permissions = 'Please write permissions';
      valid = false;
    }


    setErrors(newErrors);
    return valid;
  };

  useEffect(() => {
    // If it's an edit operation, you can fetch user data using 'slug' and set it in the form.
    if (isEdit) {
      dispatch(getRole(slug)).then(res => {
        setFormData({
          title: res.payload.title,
          permissions: res.payload.permissions
        });
      });

    }
  }, [isEdit, slug]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    if (isEdit) {
      dispatch(updateRole({ data: { id: slug, data: formData } })).then((res) => {
        if (!res.error) {
          navigate("/roles");
        }

      });
    } else {
      dispatch(addRoles({ data: formData })).then((res) => {
   
        if (!res.error) {
          navigate("/roles");
        }
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="OverAllSites">
      <Headings fontSize={24}>{isEdit ? "Edit Role" : "Add Role"}</Headings>
      <form onSubmit={handleFormSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>

            <TextField
              label="Title"
              variant="filled"
              fullWidth
              name="title"
              value={formData.title}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Permissions"
              variant="filled"
              fullWidth
              name="permissions"
              value={formData.permissions}
              onChange={handleChange}
              error={!!errors.permissions}
              helperText={errors.permissions}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <TextField
              label="Email"
              variant="filled"
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Phone Number"
              variant="filled"
              fullWidth
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              label="User Role"
              variant="filled"
              fullWidth
              name="buildingType"
              value={formData.buildingType}
              onChange={handleChange}
              error={!!errors.buildingType}
              helperText={errors.buildingType}
            > */}
          {/* {buildingTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid> */}
          <Grid item xs={12}>

            <Button className="customButtomOverAll" disabled={loading} type="submit" variant="contained" color="primary">
              {loading ? <CircularProgress color="inherit" /> : isEdit ? "Update" : "Create"}
            </Button>
          </Grid>

        </Grid>
      </form >


    </div >
  );
};

export default AddRolePage;
