import React, { useState } from "react";

import { Divider } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import { Headings, SubHeadings } from "../../Shared/Fonts/Fonts";
import {getStatusMessage} from "../../Utils/Functions/getStatusMessages";
import "./Maps.css";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";


  // switch (status) {
  //   case 'compliant':
  //     return 'green';
  //   case 'warning':
  //     return 'yellow';
  //   case 'fault':
  //     return 'red';
  //   default:
  //     return 'blue';
  // }


export const MapMarker = (props) => {
  const { status, Location, country, solution, sector, name, id, Error } = props;
    const { Component, color } = useStatus(status);
  return (
    <div
      className="marker-layout-Map"
      style={{ borderTop: `1.5px solid ${color}` }}
    >
      <div className="HeadingMarker">
        <div className="HeadingAddress">
          <Headings fontSize={16}>{name}</Headings>
          <Component />
          
        </div>

        <SubHeadings fontSize={14} color={color}>
          {getStatusMessage(status)}
        </SubHeadings>
      </div>
      <Divider />
      <div className="BottomSection">
        <div className="fx-space p-0">
          <SubHeadings fontSize={14} color={"black"}>
            Solution
          </SubHeadings>
          <SubHeadings
            fontFamily={"Futura PT Book"}
            fontSize={14}
            color={"black"}
            fontWeight={400}
          >
            {solution}
          </SubHeadings>
        </div>
        <div className="fx-space p-0">
          <SubHeadings fontSize={14} color={"black"}>
            Sector
          </SubHeadings>
          <SubHeadings
            fontFamily={"Futura PT Book"}
            fontSize={14}
            color={"black"}
            fontWeight={400}
          >
            {sector}
          </SubHeadings>
        </div>
      </div>
    </div>
  );
};

const useStatus = (stat) => {
  let status = parseInt(stat);
  if (status >= 200 && status < 300) {
    const Component = () => {
      return (
        <CheckCircleOutlineOutlinedIcon sx={{ width: 16, height: 16, color: "#34A853" }} />
      );
    };
    return {
      Component,
      color: "#34A853",
    };
  } else if (status >= 300 && status < 400) {
    const Component = () => {
      return (
        <WarningAmberIcon
          sx={{ color: "rgba(221, 173, 1, 1)", width: "16px", heigth: "16px" }}
        />
      );
    };
    return {
      Component,
      color: "rgba(221, 173, 1, 1)",
    };
  } else if (status >= 400 && status < 500) {
    const Component = () => {
      return (
        <WarningAmberIcon
          sx={{ color: "rgba(235, 67, 53, 1)", width: "16px", heigth: "16px" }}
        />
      );
    };
    return {
      Component,
      color: "red",
    };
  } else {
    const Component = () => {
      return (
        <CheckCircleOutlineOutlinedIcon sx={{ width: 16, height: 16, color: "#FFA500" }} />
      );
    };
    return {
      Component,
      color: "#FFA500",
    };
  }
};
