import { createSlice } from "@reduxjs/toolkit";
import { postApi, getApi, putApi } from "../../Utils/serviceWorker";
import { createAsyncThunk } from "@reduxjs/toolkit";
import jsCookie from "js-cookie";
import { toast } from "react-toastify";
export const login = createAsyncThunk(
  "user/login",
  async (credentials, thunkAPI) => {
    try {
      const response = await postApi("auth/signin/local", false, credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const register = createAsyncThunk(
  "user/register",
  async (credentials, thunkAPI) => {
    try {
      const response = await postApi("auth/signup", false, credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const profile = createAsyncThunk(
  "user/profile",
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi("auth/me", true, credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const verifyEmail = createAsyncThunk(
  "user/verfiy",
  async (credentials, thunkAPI) => {
    try {
      const response = await putApi(
        `auth/verify-email?token=${credentials}`,
        false,
        { token: credentials }
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const resetEmail = createAsyncThunk(
  "user/reset",
  async (credentials, thunkAPI) => {
    try {
      const response = await postApi(`auth/send-password-reset-email`, false, {
        email: credentials,
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (credentials, thunkAPI) => {
    try {
      const response = await putApi(`auth/password-reset`, false, credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState= {
  user: null,
  token: null,
  loading: false,
  error: null,
  initialLoading: false,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: () => initialState
  },

  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
    
      jsCookie.set("token", action.payload.token);
      state.user = action.payload.user
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(register.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Verification Email has been sent, Please Verify Email");
    });
    builder.addCase(register.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(profile.pending, (state) => {
      state.loading = true;
      state.initialLoading = true;
      state.error = null;
    });
    builder.addCase(profile.fulfilled, (state, action) => {


      state.user = action.payload;
      state.loading = false;
    });
    builder.addCase(profile.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(verifyEmail.pending, (state) => {
      state.loading = true;

      state.error = null;
    });
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Email is Verified,You Can Login.");
    });
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(resetEmail.pending, (state) => {
      state.loading = true;

      state.error = null;
    });
    builder.addCase(resetEmail.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Password Reset is Sent to Your Email");
    });
    builder.addCase(resetEmail.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;

      state.error = null;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Password has been Reset");
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = false;
    });
  },
});
