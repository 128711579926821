import { makeStyles } from '@mui/styles';
import loaderImg from '../../Assets/Spinner.png';

const useStyles = makeStyles({
  loader: {
    width: 80,
    height: 80,
    borderRadius: '50%',
    backgroundImage: `url(${loaderImg})`,
    backgroundSize: 'contain',
    animation: '$spin 1s linear infinite'
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
});
export const ImageLoader = () => {
  const classes = useStyles();

  return <div className={classes.loader}></div>;
}

