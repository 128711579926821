import React, { useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import SiteDrawer from "../Drawers/SiteDrawer";
import "../../Screens/Site/Sites";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import sortIcon from "../../Assets/sortIcon.svg";

export function SortedDescendingIcon() {
  return <img src={sortIcon} className="icon" />;
}

export function SortedAscendingIcon() {
  return <img src={sortIcon} className="icon" />;
}

export function UnsortedIcon() {
  return <img src={sortIcon} className="icon" />;
}

export const Table = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {
    rows,
    columns,
    rowSelection,
    loading,
    unique_identifier,
    height,
    handleRowClick,
    getRowClassName,
    serverSide,
    paginationModel,
    setPaginationModel,
    matches,
    columnVisibilityModel
  } = props;
  const [site, setSite] = useState();
  const handleEnter = (e) => {
    setSite(rows[e.currentTarget.getAttribute("data-rowindex")]);
    setOpen(true);
  };
  const handleLeave = (e) => {
    setOpen(false);
  };

  return (
    <>
      <div style={{ height: height ? null : 450, width: "100%" }}>
        <DataGrid
          rows={loading ? [] : rows}
          columns={columns}
          sx={{
            padding: "10px 30px 10px 30px",
            width: "100%",
            backgroundColor: "white",
            border: "none",
            borderRadius: "0px",
            "& .MuiDataGrid-iconButtonContainer": {
              visibility: "visible",
            },
            "& .MuiDataGrid-sortIcon": {
              opacity: "inherit !important",
            },
            "& .MuiDataGrid-cell": {
              padding: "15px 0px 15px 0px",
              color: "#212B36",
              fontFamily: "Futura PT Book !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              fontFamily: "Futura PT Book",
              fontWeight: "450",
              fontSize: 16,
              padding: "0px 0px",
            },
            "& .MuiTablePagination-select": {
              background: "#EEE",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            '& > .MuiDataGrid-columnSeparator': {
              display: 'none',
            }
          }}
          pageSizeOptions={[5, 10,20,25,50,100]}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          headerCellProps={{
            tabIndex: -1,
            "aria-hidden": true
          }}
          disableColumnMenu // Hide the column menu
          disableColumnSelector
          renderHeader={(params) => (
            <strong>
              {params.colDef.headerName}
              <ArrowDownward />
            </strong>
          )}
          pageSize
          autoHeight
          rowHeight={matches ? 74 : 54}
          loading={loading}
          onRowClick={handleRowClick}
          // autoHeight
          // onRowSelectionModelChange={(newRowSelectionModel) => {
          //   handleRowClick(newRowSelectionModel);
          // }}
          columnVisibilityModel={columnVisibilityModel}
          hideSortIcons={false}
          disableRowSelectionOnClick={rowSelection}
          paginationModel={paginationModel}
          paginationMode={serverSide ? "server" : "client"}
          onPaginationModelChange={setPaginationModel}
          getRowId={
            unique_identifier
              ? (row) => {
               
                return row.data[unique_identifier]
                  ? row.data[unique_identifier]
                  : row.id;
              }
              : null
          }
          slotProps={
            rowSelection && {
              row: {
                onMouseEnter: handleEnter,
                onMouseLeave: handleLeave,
              },
            }
          }

          isRowSelectable={false}

          // slots={{
          //   toolbar: GridToolbar,
          //   columnSortedDescendingIcon: SortedDescendingIcon,
          //   columnSortedAscendingIcon: SortedAscendingIcon,
          //   columnUnsortedIcon: UnsortedIcon,
          // }}
          getRowClassName={getRowClassName}
        />
      </div>
      <SiteDrawer open={open} setOpen={setOpen} site={site} />
    </>
  );
};
