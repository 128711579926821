import React from 'react';

import { Navigate, useLocation } from "react-router-dom";

import jsCookie from 'js-cookie';


const UnProtectedRoute = ({ children }) => {
  const token = jsCookie.get('token');


  let location = useLocation().pathname;


  if (token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;

};

export default UnProtectedRoute;