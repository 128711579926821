import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TextField, Button, Grid, MenuItem } from "@mui/material";
import { Headings } from "../../Shared/Fonts/Fonts";
import { v4 as uuidv4 } from "uuid";
import {
  getSensor,
  updateSensor,
  addSensor,
} from "../../Store/Features/Sensors";
import { getDoorsofFloor, uploadDoorImage } from "../../Store/Features/Door";
import { getSites } from "../../Store/Features/Sites";
import { useDispatch, useSelector } from "react-redux";
import { getFloorsOfSite, getFloor } from "../../Store/Features/Floors";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {getOrganization} from "../../Store/Features/Customer"
import {API_URL} from "../../Utils/Functions/utils";

const AddSensorPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.sensors.loading);
  const sites = useSelector((state) => state.sites.value);
  const [site, setSite] = useState(null);
  const [floor, setFloor] = useState(null);
  const [doors, setDoors] = useState([]);
  const [floors, setFloors] = useState([]);
  const navigate = useNavigate();
  const { slug } = useParams();
  const [orgName,setOrgName] = useState()
  const isEdit = slug !== undefined;
  const [formData, setFormData] = useState({
    description: "",
    sensor_code: "",
    x_coordinates: null,
    y_coordinates: null,
    location: "",
    status: "",
    door: "",
    qr_code:"",
    commission_flag: ""
  });
  const [errors, setErrors] = useState({});

  const handleImage = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    let valid = true;
 

    if (!formData.sensor_code) {
     
      newErrors.sensor_code = "Sensor Code is required";
      valid = false;
    }
    
    if (!formData.location) {
  
      newErrors.location = "location is required";
      valid = false;
    }
    // if (!formData.status) {
    //   console.log("status");
    //   newErrors.status = "status is required";
    //   valid = false;
    // }

    if (formData.door.length == 0) {
     
      newErrors.door = "door is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  useEffect(() => {
    // If it's an edit operation, you can fetch user data using 'slug' and set it in the form.
    if (isEdit) {
      dispatch(getSensor(slug)).then((res) => {
        if(!res.error) {
          setFormData({
            description: res.payload.description,
            sensor_code: res.payload.sensor_code,
            x_coordinates: res.payload.x_coordinates,
            y_coordinates: res.payload.y_coordinates,
            location: res.payload.location,
            status: res.payload.status,
            door: res.payload.door?.id,
            door_picture: res.payload.door_picture,
            qr_code:res.payload?.qr_code,
            commission_flag: res.payload?.commission_flag,
          });
          setFloor(res.payload.door?.floorId);
          dispatch(getFloor(res.payload.door?.floorId)).then((res) => {
            setSite(res.payload?.site?.id);
            if(res.payload?.site?.organizationId) {
              
              dispatch(getOrganization(res.payload?.site?.organizationId)).then(res => {
                setOrgName(res.payload?.name)
              })
            }
          });
        }
       
      });
    }
  }, [isEdit, slug]);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }
    if (isEdit) {
      dispatch(updateSensor({ id: slug, data: formData })).then(
        (res) => {
          if (!res.error) {
            navigate("/devices");
          }
        }
      );
    } else {
      dispatch(addSensor({ data: formData })).then((res) => {
   
        if (!res.error) {
          navigate("/devices");
        }
      });
    }
  };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  useEffect(() => {
    dispatch(getSites());
  }, []);

  useEffect(() => {
    if (site) {
      dispatch(getFloorsOfSite(site)).then((res) => {
        setFloors(res.payload.data);
      });
    }
  }, [site]);

  useEffect(() => {
    if (floor) {
      dispatch(getDoorsofFloor(floor)).then((res) => {
        setDoors(res.payload.data);
      });
    }
  }, [floor]);

  useEffect(() => {
    document.title = slug ? "Edit Sensor" : "Add Sensor";
  }, []);

  return (
    <div className="OverAllSites">
      <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
        <IconButton onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon />
        </IconButton>
        <Headings fontSize={16}>
          {isEdit ? "Edit Device" : "Add Device"}
        </Headings>
      </div>
      <div className="Addsitetabs">
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Device Code"
                variant="filled"
                fullWidth
                name="sensor_code"
                value={formData.sensor_code}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                error={!!errors.sensor_code}
                helperText={errors.sensor_code}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>



              <TextField
                label="Nfc Code"
                variant="filled"
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="nfc_code"
                value={formData.nfc_code}
                onChange={handleChange}
                error={!!errors.sensor_code}
                helperText={errors.sensor_code}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>



<TextField
  label="Qr Code"
  variant="filled"
  InputLabelProps={{ shrink: true }}
  fullWidth
  name="qr_code"
  value={formData.qr_code}
  onChange={handleChange}
  error={!!errors.sensor_code}
  helperText={errors.sensor_code}
/>
</Grid>
           
          

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Status"
                variant="filled"
                fullWidth
                type="status"
                name="status"
                value={formData.status}
                onChange={handleChange}
                error={!!errors.yCoordinate}
                InputLabelProps={{ shrink: true }}
                helperText={errors.yCoordinate}
              />
            </Grid>

            {/* <Grid item xs={6}>
            <TextField
              label="Email"
              variant="filled"
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Phone Number"
              variant="filled"
              fullWidth
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
            />
          </Grid>*/}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                select
                label="Location"
                variant="filled"
                fullWidth
                name="location"
                value={formData.location}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                error={!!errors.location}
                helperText={errors.location}
              >
                <MenuItem value={"Top"}>Top</MenuItem>
                <MenuItem value={"Side"}>Side</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                select
                label="Commissioned"
                variant="filled"
                fullWidth
                name="commission_flag"
                value={formData.commission_flag}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              
              >
                <MenuItem value={0}>Not Commissioned</MenuItem>
                <MenuItem value={1}>Commissioned</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                select
                label="Site"
                variant="filled"
                fullWidth
                name="site"
                value={site}
                onChange={(e) => setSite(e.target.value)}
                error={!!errors.site}
                helperText={orgName ? `Site of organization ${orgName}` : ``}
                InputLabelProps={{ shrink: true }}
              >
                {sites?.map((option) => (
                  <MenuItem key={option.data.id} value={option.data.id}>
                    {option.data.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                select
                label="Floor"
                variant="filled"
                fullWidth
                sx={{ display: site ? null : "none" }}
                name="floor"
                value={floor}
                onChange={(e) => setFloor(e.target.value)}
                error={!!errors.floor}
                helperText={errors.floor}
                InputLabelProps={{ shrink: true }}
              >
                {floors?.map((option) => (
                  <MenuItem key={option.data.id} value={option.data.id}>
                    {option.data.description}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                select
                label="Door"
                variant="filled"
                fullWidth
                sx={{ display: floor ? null : "none" }}
                name="door"
                value={formData.door}
                onChange={handleChange}
                error={!!errors.door}
                InputLabelProps={{ shrink: true }}
                helperText={errors.door}
              >
                {doors?.map((option) => (
                  <MenuItem key={option.data.id} value={option.data.id}>
                    {option.data.description}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>

              <Button className="customButtomOverAll" type="submit" variant="contained" color="primary">
                {loading ? <CircularProgress disabled={loading} color="inherit" /> : isEdit ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form >
      </div>
    </div >
  );
};

export default AddSensorPage;

const ImageUploadButton = ({ onChange, name, path }) => {
  const dispatch = useDispatch();

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    const extension = extractExtensionFrom(selectedFile.name);
    const id = uuidv4();
    const filename = `${id}.${extension}`;
    const privateUrl = `${path}/${filename}`;
    const uri = `/file/upload/${path}`;
    const publicUrl = API_URL()+`file/download?privateUrl=${privateUrl}`;
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("filename", filename);

    dispatch(uploadDoorImage(formData)).then((res) => {
      onChange("door_picture", {
        id: id,
        name: selectedFile.name,
        sizeInBytes: selectedFile.size,
        privateUrl,
        publicUrl,
        new: true,
      });
    });
  };

  // const handleUpload = () => {
  //   // You can implement the image upload logic here
  //   if (selectedImage) {
  //     // Example: Send the selectedImage to your backend for processing
  //     console.log('Uploading image:', selectedImage.name);
  //   }
  // };

  return (
    <div>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="image-upload-input"
        type="file"
        onChange={handleImageChange}
      />
      <label htmlFor="image-upload-input">
        <Button className="customButtomOverAll" variant="contained" component="span">
          Upload Image
        </Button>
      </label>
    </div>
  );
};

function extractExtensionFrom(filename) {
  if (!filename) {
    return null;
  }

  const regex = /(?:\.([^.]+))?$/;
  return regex.exec(filename)[1];
}
