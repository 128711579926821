import React from "react";
import "./Door.css";
import DoorBackOutlinedIcon from "@mui/icons-material/DoorBackOutlined";
import DoorFrontOutlinedIcon from "@mui/icons-material/DoorFrontOutlined";
import { Headings } from "../../Shared/Fonts/Fonts";
import {getStatusMessage} from "../../Utils/Functions/getStatusMessages"
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import { Divider } from "@mui/material";

export const DoorCardUtil = (props) => {
    const {
        title,
        time
      } = props;
  

  return (
    <div className="doorCardUtils" >
      <div className="doorCardUpper">
        
        <Headings color = {"white"} fontSize={16} fontWeight={450}>
          {title || "N/A"}
          </Headings>
      </div>
      <Divider sx = {{width: "100%",background:"white"}} />
      <div className="doorCardDown">
          <Headings color = {"white"} fontSize={14} fontWeight={450} fontFamily= {"Futura Pt Book"}>
          {time || "N/A"}
          </Headings> 
      </div>
    </div>
  );
};


