import { createSlice } from '@reduxjs/toolkit';
import { postApi, getApi, deleteApi, putApi, uploadService } from "../../Utils/serviceWorker";
import { createAsyncThunk } from '@reduxjs/toolkit';
import jsCookie from 'js-cookie';
import { toast } from 'react-toastify';

// export const getFloors = createAsyncThunk(
//   'floors/get',
//   async (credentials, thunkAPI) => {
//     try {
//       const response = await getApi(`floors`, true);
//       return response;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.response.data);
//     }
//   }
// );

export const getFloor = createAsyncThunk(
  'floor/get',
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`floors/${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addFloor = createAsyncThunk(
  'floor/add',
  async (credentials, thunkAPI) => {
    try {
      const response = await postApi(`floors`, true, credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateFloor = createAsyncThunk(
  'floor/update',
  async (credentials, thunkAPI) => {
    try {
      const response = await putApi(`floors/${credentials.id}`, true, credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteFloor = createAsyncThunk(
  'floors/delete',
  async (credentials, thunkAPI) => {
    try {
      const response = await deleteApi(`floors/${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


export const getFloorsOfSite = createAsyncThunk(
  'floor/Sites',
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`floors?site=${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  value: [],

  loading: false,
  error: null
}

export const Floors = createSlice({
  name: 'Floors',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    // builder.addCase(getFloors.pending, (state) => {
    //   state.loading = true;
    //   state.error = null;
    // });
    // builder.addCase(getFloors.fulfilled, (state, action) => {
    //   state.loading = false;
    
    //   state.value = action.payload.data;
    // });
    // builder.addCase(getFloors.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;


    // });
    builder.addCase(updateFloor.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateFloor.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Updated Floor Successfully");
    });
    builder.addCase(updateFloor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(addFloor.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addFloor.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Created Floor Successfully");
    });
    builder.addCase(addFloor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getFloorsOfSite.pending, (state) => {
      state.loading = true;
      toast.loading("Getting Floors",{
        toastId: 'success1',
      });
      state.error = null;
    });
    builder.addCase(getFloorsOfSite.fulfilled, (state, action) => {
      toast.dismiss();
      state.loading = false;

    });
    builder.addCase(getFloorsOfSite.rejected, (state, action) => {
      toast.dismiss();
      state.loading = false;
      state.error = action.payload;
    });


  },
});