import React, { useState } from "react";
import svg from "../../../Assets/up.svg";
import collapseUp from "../../../Assets/collapseup.svg";
import { Headings, SubHeadings } from "../../Fonts/Fonts";
import "./Navigations.css";
import { Paper } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import collapseDown from "../../../Assets/collapsedown.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";

export const AppBar = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [openNotification, setOpenNotification] = useState(false);
  const [sortOptions, setSortOptions] = useState([
    { title: "Newest to Oldest", selected: false, code: "otn" },
    { title: "Oldest to Newest", selected: false, code: "nto" },
  ]);
  const [openSort, setOpenSort] = useState(false);
  const [notifications, setNotifications] = useState([
    {
      status: "Not Compliance",
      message: "Door Gap ++",
      date: "March 14, 2023",
      time: "8:21pm",
      siteId: "000d214f-e3d2-4bf3-9d45-467d3ec69767",
      floorId: "c0f57ff4-20c1-45f2-9690-67ea968a06b5",
      doorId: "2b5eb6ba-c15b-4ff3-adaf-50afe82d3320",
      name: "Nottingham Res 14",
    },
    {
      status: "Warning",
      message: "Door Gap ++",
      date: "March 15, 2023",
      time: "8:22pm",
      siteId: "000d214f-e3d2-4bf3-9d45-467d3ec69767",
      floorId: "c0f57ff4-20c1-45f2-9690-67ea968a06b5",
      doorId: "2b5eb6ba-c15b-4ff3-adaf-50afe82d3320",
      name: "Nottingham Res 15",
    },
  ]);
  const location = useLocation().pathname;

  const selectOption = (index) => {
    let temp = [...sortOptions];
    temp[index].selected = true;
    for (let i = 0; i < temp.length; i++) {
      if (i !== index) {
        temp[i].selected = false;
      }
    }
    setSortOptions(temp);
  };

  const oldestToNewest = () => {
    const sortedOldestToNewest = [...notifications].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB; // Compare in normal order for oldest to newest
    });
    setNotifications(sortedOldestToNewest);
  };

  const newstToOldest = () => {
    const sortedNewestToOldest = [...notifications].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA; // Compare in reverse order for newest to oldest
    });
    setNotifications(sortedNewestToOldest);
  };

  return (
    <div
      className={`notification ${location == "/" ? null : "hidden"}  ${user?.roleId == process.env.REACT_APP_INSPECTION_ROLE_ID ||
        user?.roleId == process.env.REACT_APP_ADMIN_ROLE_ID ||
        user?.roleId == process.env.REACT_APP_AGENT_ROLE_ID ||
        user?.roleId == process.env.REACT_APP_USER_ROLE_ID ||
        user?.roleId == process.env.REACT_APP_ORG_ADMIN_ROLE_ID
        ? "hidden"
        : null
        }`}
    >
      <div
        className={`AppBar `}
        onClick={(e) => {
          setOpenNotification(!openNotification);
          setOpenSort(false);
        }}
      >
        <div className="appbar-first-section">
          <img src={openNotification ? collapseDown : collapseUp} />
          <div className="appbar-first-section-text">
            <Headings>Notifications</Headings>
            <div className="notifiers" />
          </div>
        </div>
        <div
          className={`appbar-second-section ${openNotification ? null : "hidden"
            }`}
          style={{ zIndex: "999999" }}
          onClick={(e) => {
            e.stopPropagation();
            setOpenSort(!openSort);
          }}
        >
          <SubHeadings fontFamily={"Futura PT Book"}>Sort By</SubHeadings>
          <img src={svg} />
        </div>
      </div>
      <div className="down-section">
        <Paper
          className={`sort ${openNotification ? (openSort ? null : "hidden") : "hidden"
            }`}
        >
          {sortOptions?.map((option, i) => {
            return (
              <Sort
                {...option}
                Function={() => {
                  selectOption(i);
                  option.code == "otn" ? oldestToNewest() : newstToOldest();
                  setOpenSort(false);
                }}
              />
            );
          })}
        </Paper>
        <div
          className={`notification-content ${openNotification ? null : "hidden"
            } `}
        >
          {notifications?.map((notification) => (
            <Notification {...notification} />
          ))}
        </div>
      </div>
    </div>
  );
};

const Notification = (props) => {
  const { status, message, name, date, time, siteId, floorId, doorId } = props;
  const navigate = useNavigate();
  return (
    <div
      className="notification-single"
      style={{
        borderLeft: `2px solid ${status == "Not Compliance"
          ? "#EB4335"
          : status == "Warning"
            ? "#DDAD01"
            : null
          }`,
      }}
      onClick={() =>
        navigate(`sites/${siteId}/floor/${floorId}/asset/${doorId}`)
      }
    >
      <div className="notification-single-upper">
        <div className="notification-single-upper-first">
          <Headings>{name}</Headings>
          <WarningAmberIcon
            sx={{ color: getStatus(status).color, fontSize: 16 }}
          />
        </div>
        <Headings color={getStatus(status).color}>{message}</Headings>
      </div>
      <div className="notification-single-down">
        <SubHeadings
          fontFamily={"Futura PT Book"}
          color="#919EAB"
          fontSize={"14px"}
        >
          {date}
        </SubHeadings>
        <SubHeadings
          fontFamily={"Futura PT Book"}
          color="#919EAB"
          fontSize={"14px"}
        >
          {time}
        </SubHeadings>
      </div>
    </div>
  );
};

const Sort = (props) => {
  const { title, Function, selected } = props;
  return (
    <button
      className="sortButton"
      onClick={Function}
      style={{
        backgroundColor: selected ? "#F1F7FA" : "transparent",
        fontFamily: "Futura PT Book",
      }}
    >
      {title}
    </button>
  );
};

const getStatus = (stat) => {
  let status = parseInt(stat);
  if (status >= 200 && status < 300) {
    return {
      color: "#34A853",
      background: "rgba(52, 168, 83, 0.1)",
    };
  } else if (status >= 300 && status < 400) {
    return {
      color: "rgba(221, 173, 1, 1)",
      background: "rgba(221, 173, 1, 0.1)",
    };
  } else if (status >= 400 && status < 500) {
    return {
      color: "#EB4335",
    };
  } else {
    return {
      color: "#34A853",
      background: "rgba(52, 168, 83, 0.1)",
    };
  }
};
