import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useParams } from "react-router-dom";
import { TextField, Button, Grid, MenuItem } from "@mui/material";
import { Headings } from "../../Shared/Fonts/Fonts";
import { getOrganization, updateOrganization, addOrganization } from "../../Store/Features/Customer";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import { getRoles } from "../../Store/Features/Roles";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { IconButton } from "@mui/material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Input from '@mui/material/Input';


var currentTime = new Date();
const AddOrganizationPage = () => {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const dispatch = useDispatch();
  const roles = useSelector(state => state.roles.value);
  const isEdit = organizationId !== undefined;
  const loading = useSelector(state => state.customers.loading);
  const [formData, setFormData] = useState({ name: "", email: "", contact: "", address: "" });
  const [errors, setErrors] = useState({});


  const handleAvatar = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    let valid = true;

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
      valid = false;
    }

    if (!formData.contact.trim()) {
      newErrors.firstName = 'Contact is required';
      valid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
      valid = false;
    }

    if (!formData.address.trim()) {
      newErrors.firstName = 'Address is required';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  useEffect(() => {
    // If it's an edit operation, you can fetch user data using 'slug' and set it in the form.
    if (isEdit) {
      dispatch(getOrganization(organizationId)).then(res => {

        setFormData({
          name: res.payload.name, email: res.payload.email, contact: res.payload.contact, address: res.payload.address
        });
      }
      );

      // Perform an API call or any necessary data fetching to get user details based on 'slug'
      // Set the fetched data to the formData state.
      // Example: fetchDataAndSetFormData(slug);
    }
  }, [isEdit, organizationId]);

  useEffect(() => {
    dispatch(getRoles());
  }, []);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (isEdit) {
      dispatch(updateOrganization({ id: organizationId, data: formData })).then(res => {
        if (!res.error) {
          navigate("/organizations");
        }
      });
    } else {
      dispatch(addOrganization({ data: formData })).then(res => {
        if (!res.error) {
          navigate("/organizations");
        }
      });
    }
  };

  useEffect(() => {
    document.title = organizationId ? "Edit User" : "Add User";
  }, []);



  return (
    <div className="OverAllSites">
      <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
        <IconButton onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon />
        </IconButton>

        <Headings fontSize={16}>{isEdit ? "Edit Organisation" : "Add Organisation"}</Headings>
      </div>
      <div className="Addsitetabs">
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>

              <TextField
                label="Name"
                variant="filled"
                fullWidth
                InputLabelProps={{ shrink: true }}
                name="name"
                value={formData.name}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
              />

            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Contact"
                variant="filled"
                fullWidth
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                error={!!errors.contact}
                helperText={errors.contact}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Email"
                InputLabelProps={{ shrink: true }}
                variant="filled"
                fullWidth
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Address"
                variant="filled"
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="address"
                value={formData.address}
                onChange={handleChange}
                error={!!errors.address}
                helperText={errors.address}
              />
            </Grid>




            <Grid item xs={12}>
              <Button className="customButtomOverAll" disabled={loading} type="submit" variant="contained" color="primary">
                {loading ? <CircularProgress color="inherit" /> : isEdit ? "Update" : "Create"}
              </Button>
            </Grid>

          </Grid>
        </form>
      </div>

    </div >
  );
};

export default AddOrganizationPage;


