import React, { useState, useEffect } from "react";
import { Headings, SubHeadings, Text } from '../../Shared/Fonts/Fonts';
import "./Forms.css";

import Divider from '@mui/material/Divider';

export const SensorLocation = ({ form, setForm, data }) => {

  return (
    <div className="SensorConnection">
      <div className="Content">
        <div className="SensorInfo" style={{ gap: 50 }}>
          <Headings >Sensor Location?</Headings>
          <div className="ButtonsGroup">
            <button className="locationButton" style={{ backgroundColor: form.location == "Top" ? "blue" : null }} onClick={(e) => setForm({ ...form, ["location"]: "Top" })}>Top</button>
            <button className="locationButton" style={{ backgroundColor: form.location == "Side" ? "blue" : null }} onClick={(e) => setForm({ ...form, ["location"]: "Side" })}>Side</button>
          </div>
        </div>

      </div>
    </div>
  );
};


