import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Roles.css";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import { useNavigate } from "react-router-dom";
import { Table } from "../../Components/Table/Tables";
import { getStatusMessage } from "../../Utils/Functions/getStatusMessages";

import {
  Button,
  Menu,
  MenuItem,
  IconButton,
  Modal,
  TextField,
  Paper,
  Box,
  CircularProgress,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Edit from "../../Assets/Edit.svg";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";

import {
  getSensors,
  deleteSensor,
  getFloorOdSensor,
  getSensorOfOrg,
  scheduleDownlinkMessage,
} from "../../Store/Features/Sensors";
import { useDispatch, useSelector } from "react-redux";
import search from "../../Assets/search.svg";
import { getWebhooks } from "../../Store/Features/Webhook";
import { QrCodeScannerOutlined } from "@mui/icons-material";
const Sensors = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.sensors);
  const sensors = useSelector((state) => state.sensors.value);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const { user } = useSelector((state) => state.auth);
  const matches = useMediaQuery("(max-width:769px)");
  const [isOpen, setIsOpen] = useState(false);
  const [sensor, setSensor] = useState();
  // async function getSite(floorId) {
  //   let data1 = "";
  //   let data = await dispatch(getFloor(floorId)).then((res) => {
  //     data1 = res.payload.siteId;
  //     // return res.payload.siteId;
  //   });

  //   return data1;
  // }
  // useEffect(() => {
  //   if (sensors.length > 0) {
  //     sensors.map((sensor) => {
  //       if (sensor.door != undefined) {
  //         dispatch(getFloorOdSensor(sensor?.door?.floorId));
  //       }

  //     });
  //   }
  // }, [loading]);

  // Function to handle changes in the search input
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter the sites array based on the search query
  const filteredSensors =
    sensors?.length > 0 &&
    sensors.filter((sensor) => {
      // Iterate through each field in the site object
      let each = false;
      for (const key in sensor) {
        if (sensor.hasOwnProperty(key)) {
          // address sector data status
          // door sensor_code x_coordinates y_coordinates location createdById
          if (
            key == "door" ||
            key == "sensor_code" ||
            key == "location" ||
            key == "floor"
          ) {
            if (key == "door") {
              const value = sensor[key]?.description + "";
              // Check if the field value contains the search query (case-insensitive)
              if (
                value &&
                value.toString().toLowerCase().match(searchQuery.toLowerCase())
              ) {
                each = true; // Return true if a match is found
              }
            } else if (key == "floor") {
              const value =
                sensor[key]?.description + sensor[key]?.site.name + "";
      
              // Check if the field value contains the search query (case-insensitive)
              if (
                value &&
                value.toString().toLowerCase().match(searchQuery.toLowerCase())
              ) {
                each = true; // Return true if a match is found
              }
            }
            // .noOfSensors
            else {
            }
            const value = sensor[key] + "";
            // Check if the field value contains the search query (case-insensitive)
            if (
              value &&
              value.toString().toLowerCase().match(searchQuery.toLowerCase())
            ) {
              each = true; // Return true if a match is found
            }
          } else if (!searchQuery) {
            const value = sensor[key] + "";
            // Check if the field value contains the search query (case-insensitive)
            if (
              value &&
              value.toString().toLowerCase().match(searchQuery.toLowerCase())
            ) {
              each = true; // Return true if a match is found
            }
          }
        }
      }
      return each;
    });

  const handleDeleteRole = (id) => {
    dispatch(deleteSensor(id)).then(() => {
      dispatch(getSensors());
    });
  };
  useEffect(() => {
    document.title = "Sensors";
  }, []);

  const columns = [
    {
      field: "door",
      headerName: "Door",
      flex: 1,
      renderCell: (params) => <p>{params.row.door?.description}</p>,
    },
    {
      field: "sensor_code",
      headerName: "Device Code",
      flex: 1,
      renderCell: (params) => (
        <Headings
          fontSize={16}
          color={"#212B36"}
          fontFamily="Futura PT Book"
          fontWeight={400}
          textTranformation="capitalize"
        >
          {params.row.sensor_code.split("-")[1].toUpperCase()}
        </Headings>
      ),
    },

    {
      field: "admin_sensor_code",
      headerName: "Admin Device Code",
      flex: 1,
      hideable: true,
      renderCell: (params) => (
        <Headings
          fontSize={16}
          color={"#212B36"}
          fontFamily="Futura PT Book"
          fontWeight={400}
          textTranformation="capitalize"
        >
          {params.row.sensor_code}
        </Headings>
      ),
    },
    {
      field: "siteName",
      headerName: "Site",
      flex: 1,
      renderCell: (params) => (
        <Headings
          fontSize={16}
          color={"#212B36"}
          fontFamily="Futura PT Book"
          fontWeight={400}
          textTranformation="capitalize"
        >
          {params.row.siteName}
        </Headings>
      ),
    },

    // params.row.door?.floorId
    {
      field: "floorName",
      headerName: "Floor",
      flex: 1,
      renderCell: (params) => (
        <Headings
          fontSize={16}
          color={"#212B36"}
          fontFamily="Futura PT Book"
          fontWeight={400}
          textTranformation="capitalize"
        >
          {params.row.floorName}
        </Headings>
      ),
    },
    { field: "location", headerName: "location", flex: 0.5 },
    // { field: "status", headerName: "doorPi", flex: 1 },
    // { field: "createdById", headerName: "Commissioned By", flex: 1 },
    {
      field: "status",
      headerName: "Sensor Status",
      flex: 1,
      renderCell: (params) => {
        const { color, background } = getStatus(params.row.status);
    
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 10, padding: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // minWidth: "74px",
                height: "25px",
                backgroundColor: background,
                padding: "2px 20px",
                boxSizing: "border-box"
              }}
            >
              <Headings
                fontFamily="Futura PT"
                color={color}
                fontSize={16}
                fontWeight={200}
                textTranformation="capitalize"
              >
                {getStatusMessage(params.row.status)}
              </Headings>
            </div>
            <img
              style={{
                width: 50,
                height: 50,
                border: "1px solid transparent",
                display: params.row.data?.door_image[0]?.publicUrl
                  ? null
                  : "none",
              }}
              src={params.row.data?.door_image[0]?.publicUrl}
            />
          </div>
        );
      },
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: "actions",
      headerName: "",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <ActionsDropdown
          roleId={params.row.id}
          onDelete={handleDeleteRole}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          device_id={params.row.sensor_code}
          setSensor={setSensor}
        />
      ),
    },
  ];

  const mobileColumns = [
    {
      field: "data",
      headerName: "Device Information",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <Headings
              fontSize={16}
              color={"#212B36"}
              fontFamily="Futura PT"
              fontWeight={400}
              textTranformation="capitalize"
            >
              {params.row.sensor_code}
            </Headings>
            <Headings
              fontSize={16}
              color={"#212B36"}
              fontFamily="Futura PT Book"
              fontWeight={400}
              textTranformation="capitalize"
            >
              {params.row.location}
            </Headings>
          </div>
        );
      },

      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    },
    {
      field: "site",
      headerName: "Site",
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              padding: "10px",
            }}
          >
            <Headings
              fontFamily="Futura PT"
              fontSize={16}
              fontWeight={400}
              textTranformation="capitalize"
            >
              {params.row.siteName}
            </Headings>
            <Headings
              fontFamily="Futura PT Book"
              fontSize={16}
              fontWeight={400}
              textTranformation="capitalize"
            >
              {params.row.floorName}
            </Headings>
          </div>
        );
      },
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: "status",
      headerName: "Device Status",
      flex: 1,
      renderCell: (params) => {
        const { color, background } = getStatus(params.row.status);
    
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 10, padding: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // minWidth: "74px",
                height: "25px",
                backgroundColor: background,
                padding: "2px 20px",
                boxSizing: "border-box"
              }}
            >
              <Headings
                fontFamily="Futura PT"
                color={color}
                fontSize={16}
                fontWeight={200}
                textTranformation="capitalize"
              >
                {getStatusMessage(params.row.status)}
              </Headings>
            </div>
            <img
              style={{
                width: 50,
                height: 50,
                border: "1px solid transparent",
                display: params.row.data?.door_image[0]?.publicUrl
                  ? null
                  : "none",
              }}
              src={params.row.data?.door_image[0]?.publicUrl}
            />
          </div>
        );
      },
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
   
      {
          field: "actions",
          headerName: "",
          sortable: false,

          flex: 0.2,
          renderCell: (params) => (
            <ActionsDropdown
              siteId={params.row.id}
              onDelete={handleDeleteRole}
            />
          ),
        }
     
  ];

  const handleRowClick = (e) => {
    if (
      e.row.siteId == null ||
      e.row.floorId == null ||
      e.row.door.id == null
    ) {
      if (e.row.door.id == null) {
        toast.error("Door for the sensor is deleted");
      }
      if (e.row.floorId == null) {
        toast.error("Floor for the sensor is deleted");
      }
      if (e.row.siteId == null) {
        toast.error("Site for the sensor is deleted");
      }
    } else {
      navigate(
        `/sites/${e.row.siteId}/floor/${e.row.floorId}/asset/${e.row.door.id}`
      );
    }
  };

  useEffect(() => {
    if (user != null) {
      if (
        user.roleId == process.env.REACT_APP_ORG_ADMIN_ROLE_ID ||
        user.roleId == process.env.REACT_APP_USER_ROLE_ID
      ) {
        dispatch(getSensorOfOrg(user.organizationId));
      } else {
        dispatch(getSensors());
      }
    }
  }, []);

  return (
    <>
      <div className="OverAllSites">
        <div className="searchspace">
          <div className="Heading">
            <Headings fontSize={24}>Devices</Headings>
            {(user?.roleId == process.env.REACT_APP_ADMIN_ROLE_ID ||
              user?.roleId == process.env.REACT_APP_ORG_ADMIN_ROLE_ID) && (
              <button
                className="addSiteButton customButtomOverAll"
                onClick={() => navigate("/devices/addDevice")}
              >
                <Headings>Add Device</Headings>
              </button>
            )}
          </div>
          <div className="inputdiv">
            <img src={search} alt="No search" className="searchimage" />
            <input
              className="searchinput"
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div style={{ height: 400, width: "100%" }}>
          <Table
            rows={filteredSensors}
            columns={matches ? mobileColumns : columns}
            rowSelection={false}
            loading={loading}
            handleRowClick={handleRowClick}
            matches={matches}
            columnVisibilityModel = {{
              actions: user.roles.id == process.env.REACT_APP_ADMIN_ROLE_ID ? true : false
            }}
          />
        </div>
      </div>
      <CustomModal isOpen={isOpen} setIsOpen={setIsOpen} device_id={sensor} />
    </>
  );
};

export default Sensors;

const ActionsDropdown = ({
  roleId,
  onDelete,
  isOpen,
  setIsOpen,
  device_id,
  setSensor,
}) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    onDelete(roleId);
    handleClose();
  };
  const openModal = () => {
    setSensor(device_id);
    setIsOpen(true);
  };

  return (
    <div>
      <IconButton variant="outlined" onClick={handleClick}>
        <img src={Edit} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon fontSize="small" />
          Delete
        </MenuItem>
        <MenuItem onClick={() => navigate(`/devices/${roleId}`)}>
          <EditIcon fontSize="small" />
          Edit
        </MenuItem>
        <MenuItem onClick={openModal}>
          {/* <VisibilityIcon fontSize="small" /> */}
          Downlink
        </MenuItem>
      </Menu>
    </div>
  );
};

const CustomModal = ({ device_id, isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const { floorId } = useParams();
  const { loading } = useSelector((state) => state.webhooks);
  const loading1 = useSelector((state) => state.sensors.loading);
  const [application_id, setApplication_id] = useState();

  const [data, setData] = useState();
  // const handleEditFloor = () => {
  //   dispatch(updateFloor({ id: floorId, data: floorData })).then((res) => {
  //     if(!res.error) {
  //       handleClose()
  //     }
  //   });
  // };
  const [downlink, setDownLink] = useState({
    device_type: null,
    protocol_ver: null,
    param_id: null,
    param_value: null,
  });

  const params = [
    { label: "Reserved", value: 0 },
    { label: "Regular Reporting Interval", value: 1 },
    { label: "Measurement Interval", value: 2 },
    { label: "Door Open Timeout", value: 3 },
    { label: "Acknowledge Reset Flag", value: 4 },
  ];

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      if (device_id) {
        dispatch(
          getWebhooks(`limit=1&Sensor=${device_id}&field=createdAt&sort=desc`)
        ).then((res) => {
          if (!res.error) {
            if (res.payload.rows?.length > 0) {
              setData(res.payload.rows[0]);
            }
          }
        });
      }
    }
  }, [isOpen, device_id]);
  useEffect(() => {
    if (data) {
      setDownLink((prevData) => ({
        ...prevData,
        ["device_type"]: data?.Data.uplink_message.decoded_payload.deviceType,
        ["protocol_ver"]: data?.Data.uplink_message.decoded_payload.protocolVer,
      }));
      setApplication_id(
        data?.Data.end_device_ids.application_ids.application_id
      );
    }
  }, [data]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Update the floorData state based on the field name
    // Assuming the keys in floorData match the field names
    setDownLink((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeSelect = (e) => {
    setDownLink((prevData) => ({ ...prevData, ["param_id"]: e.value }));
  };
  const handleSchedule = () => {
    dispatch(
      scheduleDownlinkMessage({
        data: {
          application_id: application_id,
          payload: downlink,
          device_id: device_id,
        },
      })
    ).then((res) => {
      if (!res.error) {
        setIsOpen(false);
      }
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        border: "none",
      }}
    >
      <Paper sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: "center",
          }}
        >
          {/* <Select options={params} onChange={handleChangeSelect} style = {{zIndex: 3212}} /> */}
          <TextField
            label="Params Value"
            name="param_value"
            value={downlink.param_value}
            onChange={handleChange}
            fullWidth
            id="outlined-basic"
            variant="filled"

            // variant="outlined"
            // id="outlined-basic"
            // classes={{ root: "outlined-input" }}
          />
          <button
            className="button customButtomOverAll"
            onClick={handleSchedule}
            style={{ marginLeft: 0 }}
            disabled={loading ? loading : loading1 ? loading1 : false}
          >
            {loading1 ? (
              <CircularProgress />
            ) : (
              <Headings>Send Message</Headings>
            )}
          </button>
        </Box>
      </Paper>
    </Modal>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  heigth: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const getRestriction = (value) => {
  switch (value) {
    case 0:
      return {
        min: 0,
        max: 32000,
      };
    case 1:
      return {
        min: 10,
        max: 86400,
      };
    case 2:
      return {
        min: 30,
        max: 65535,
      };
    case 3:
      return {
        min: 1,
        max: 1440,
      };
    case 4:
      return {
        min: 0,
        max: 1,
      };
  }
};



const getStatus = (stat) => {
  let status = parseInt(stat);
  if (status >= 200 && status < 300) {
    return {
      color: "#34A853",
      background: "rgba(52, 168, 83, 0.1)",
    };
  } else if (status >= 300 && status < 400) {
    return {
      color: "rgba(221, 173, 1, 1)",
      background: "rgba(221, 173, 1, 0.1)",
    };
  } else if (status >= 400 && status < 500) {
    return {
      color: "rgba(235, 67, 53, 1)",
      background: "rgba(235, 67, 53, 0.1)",
    };
  } else {
    return {
      color: "#34A853",
      background: "rgba(52, 168, 83, 0.1)",
    };
  }
};