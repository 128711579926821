import * as React from "react";
import { Layout } from "../Shared/Layout/Layout";
import { DashBaord } from "../Screens/Dashboard/Dashboard";
import { Sites } from "../Screens/Site/Sites";
import FloorMap from "../Screens/Floor/Floors";
import { AuthLayout } from "../Shared/Layout/AuthLayout";
import { Login } from "../Screens/Auth/Login";
import Register from "../Screens/Auth/Register";
import ProtectedRoute from "../Utils/RoutesProtection/ProtectedRoutes";
import UnProtectedRoutes from "../Utils/RoutesProtection/UnProtectedRoutes";
import Floor from "../Screens/Floor/Floor";
import CanvasLoader from "../Components/Canvas/Canvas";
import { Asset } from "../Screens/Asset/Asset";
import AddSiteFormWizard from "../Screens/Site/AddSite";
import ReportGeneration from "../Screens/Report/Report";
import AddAsset from "../Screens/Asset/AddSensor";
import Users from "../Screens/Users/Users";
import AddUserPage from "../Screens/Users/AddUser";
import Roles from "../Screens/Roles/Roles";
import AddRolePage from "../Screens/Roles/AddRole";
import Profile from "../Screens/Users/Profile";
import Sensors from "../Screens/Sensor/Sensors";
import Doors from "../Screens/Door/Door";
import AddDoorPage from "../Screens/Door/AddDoor";
import AddSensorPage from "../Screens/Sensor/AddSensor";
import { AssetInspection } from "../Screens/Asset/InspectionAsset";
import { Webhooks } from "../Screens/Webhooks/Webhooks";
import { VerifyScreen } from "../Screens/Auth/verfiyToken";
import Organization from "../Screens/Orgnization/Organization";
import AddOrganizationPage from "../Screens/Orgnization/AddOrganization";
import { ResetEmail } from "../Screens/Auth/ResetEmail";
import { ResetPassword } from "../Screens/Auth/PasswordReset";
import AddDevice from "../Screens/Asset/AddDevice";
import { DevicePage } from "../Screens/Asset/Sensor";
import RegisteredDevices from "../Screens/DeviceEUI/DeviceEUI";
import { createBrowserRouter, Link } from "react-router-dom";
import RegisteredDevicesPage from "../Screens/DeviceEUI/AddCodeMapping";
import ProtectedRouteRole from "../Utils/RoutesProtection/RoleProtection";
import { AdminProtectedRoute } from "../Utils/RoutesProtection/RoleProtection";


export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      // <ProtectedRoute>
      //   <AdminProtectedRoute>
      //     <Layout />
      //   </AdminProtectedRoute>
      // </ProtectedRoute>
        <ProtectedRoute>
          <ProtectedRouteRole>
            <Layout />
          </ProtectedRouteRole>
        </ProtectedRoute>
    ),
    children:  [
      {
        path: "/",
        element: <DashBaord />,
      },
      {
        path: "/sites",
        element: <Sites />,
      },
      {
        path: "sites/edit/:siteId",
        element: <AddSiteFormWizard />,
      },
      {
        path: "/sites/:siteId",
        element: <FloorMap />,
      },
      {
        path: "/sites/:siteId/floor/add",
        element: <Floor />,
      },
      {
        path: "/sites/:siteId/floor/:floorId",
        element: <Floor />,
      },
      {
        path: "sites/:siteId/floor/:floorId/asset/:assetId",
        element: <Asset />,
      },
      {
        path: "sites/addSite",
        element: <AddSiteFormWizard />,
      },

      {
        path: "/report",
        data: {name: "report"},
        element: <ReportGeneration />,
      },
      {
        path: "/sites/:siteId/floor/:floorId/asset/:assetId/sensor/addSensor",
        element: <AddAsset />,
      },
      {
        path: "/sites/:siteId/floor/:floorId/asset/:assetId/device/:device_code",
        element: <DevicePage />,
      },
      {
        path: "/devices/addDevice",
        element: <AddDevice />,
      },
      {
        path: "/users",
        element: <Users />,
      },
      {
        path: "/users/add",
        element: <AddUserPage />,
      },
      {
        path: "/users/edit/:slug",
        element: <AddUserPage />,
      },
      {
        path: "/installation_manager/edit/:slug",
        element: <AddUserPage />,
      },

      {
        path: "/installation_manager",
        element: <Roles />,
      },
      {
        path: "/installation_manager/add",
        element: <AddRolePage />,
      },
      {
        path: "/installation_manager/:slug",
        element: <AddRolePage />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/devices",
        element: <Sensors />,
      },
      {
        path: "/doors",
        element: <Doors />,
      },
      {
        path: "/doors/add",
        element: <AddDoorPage />,
      },
      {
        path: "/doors/:slug",
        element: <AddDoorPage />,
      },
      {
        path: "/devices/add",
        element: <AddSensorPage />,
      },
      {
        path: "/devices/:slug",
        element: <AddSensorPage />,
      },
      {
        path: "/inspection/doors/:assetId",
        element: <AssetInspection />,
      },
      {
        path: "/webhooks",
        element: <Webhooks />,
      },
      {
        path: "/organizations",
        element: <Organization />,
      },
      {
        path: "/organizations/:organizationId",
        element: <AddOrganizationPage />,
      },
      {
        path: "/organizations/add",
        element: <AddOrganizationPage />,
      },
      {
        path: "/organizations/:organizationId/users/",
        element: <Users />,
      },
      {
        path: "/organizations/:organizationId/users/add",
        element: <AddUserPage />,
      },
      {
        path: "/organizations/:organizationId/users/:slug",
        element: <AddUserPage />,
      },
      {
        path: "/registeredDevices",
        element: <RegisteredDevices />,
      },
      {
        path: "/registeredDevices/register",
        element: <RegisteredDevicesPage />,
      },
      {
        path: "/registeredDevices/:deviceCodeId",
        element: <RegisteredDevicesPage />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <UnProtectedRoutes>
        <AuthLayout />
      </UnProtectedRoutes>
    ),
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "verifyEmail",
        element: <VerifyScreen />,
      },
      {
        path: "/reset-password",
        element: <ResetEmail />,
      },
      {
        path: "/passwordReset",
        element: <ResetPassword />,
      },
    ],
  },
]);
