import React from 'react';
import { Headings } from '../Fonts/Fonts';
import "./Custom.css";

export const Button = ({ title, href, color, hide }) => {
  return (
    <button className="customButton" disabled={hide} >
      <a className = {hide ? "achor-disable": null}  style={{ fontSize: 13, lineHeight: 26, fontWeigth: 500, textTranform: 'capitalize', color: hide ?  "grey": color, fontFamily: "Futura PT", textDecoration: "none" }} href={href} target="_blank" download>
        {title}
      </a>

    </button>
  );
};