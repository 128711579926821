import React, { useState, useEffect } from "react";
import { Headings, SubHeadings, Text } from '../../Shared/Fonts/Fonts';
import "./Forms.css";
import SensorEnable from "../../Assets/SensorEnable.png";


import Divider from '@mui/material/Divider';

export const SensorEnabler = ({ form, setForm, data }) => {

  return (
    <div className="SensorConnection" style={{ justifyContent: "flex-start" }}>
      <div className="Content" style={{ width: "100%" }} >
        <div className="SensorInfo" style={{ gap: 50, flexDirection: "row", alignItems: "flex-end", justifyContent: "center", width: "80%" }}>
          <img className="sensorImage" src={SensorEnable} />
          <div className="sensorEnableText">
            <Headings >Use tool to press button for 5 seconds.</Headings>
            <Headings >Wait for fast Red/Green flash.</Headings>
            <Headings >Close the door fully & press Next.</Headings>
          </div>

        </div>

      </div>
    </div>
  );
};


