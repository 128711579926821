import React, { useRef, useState, useEffect } from "react";
import { Headings } from "../../Shared/Fonts/Fonts";
import { fabric } from "fabric";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Alert from "@mui/material/Alert";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./Floor.css";
import CloseIcon from "@mui/icons-material/Close";

import CustomTooltip from "../../Shared/CustomElement/ToolTip";
import AssetDrawer from "../../Components/Drawers/AssetDrawer";
import placeholderImage from "../../Assets/pin.svg";
import MarkerModal from "../../Components/Modal/MarkerModal";
import { useParams, useNavigate } from "react-router-dom";
import CanvasLoader from "../../Components/Canvas/Canvas";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import Select from "../../Shared/CustomElement/DropDown";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../Components/Modal/FloorModal";
import { getFloorsOfSite, deleteFloor } from "../../Store/Features/Floors";
import { addFloor, getFloor, updateFloor } from "../../Store/Features/Floors";
import { getDoorsofFloor } from "../../Store/Features/Door";
import CircularProgress from "@mui/material/CircularProgress";
import { getSites } from "../../Store/Features/Sites";
import SwipeableEdgeDrawer from "../../Components/Drawers/Swipeable";
import useMediaQuery from "@mui/material/useMediaQuery";
import Switch from "@mui/material/Switch";

const floors = [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
];

let i = 1;

const Floor = () => {
  const [open1, setOpen1] = React.useState(false);

  const handleClick = () => {
    setOpen1(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen1(false);
  };
  const { height, width } = useWindowDimensions();

  const canvasContainer = useRef(null);
  const navBar = useRef(null);
  const floorLayout = useRef(null);
  const toolBar = useRef(null);

  const canvasRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [canvasData, setCanvasData] = useState(null);
  const { loading } = useSelector((state) => state.floors);
  const [selectedImage, setSelectedImage] = useState(null);
  const { floorId, siteId } = useParams();
  const [canvas, setCanvas] = useState(null);

  const [showPopup, setShowPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ left: 0, top: 0 });
  const [markers, setMarkers] = useState([]);
  const [text, setText] = useState("");
  const [drawingMode, setDrawingMode] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [asset, setAsset] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const { value } = useSelector((state) => state.sites);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [canvasWidth, setCanvasWidth] = useState(1000);

  const [canvasHeight, setCanvasHeight] = useState(700);
  const matches = useMediaQuery("(max-width:769px)");
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [editing, setEditing] = useState(false);
  const [floors, setFloors] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [isEditMode, setisEditMode] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isDraggingObj, setIsDraggingObj] = useState(false);
  const [doorAdded, setDoorAdded] = useState(false);
  const [floorData, setFloorData] = useState({
    description: "",
    site: siteId,
    floor_no: null,
    floor_data: null,
  });
  useEffect(() => {
    dispatch(getSites());
  }, []);

  useEffect(() => {
    dispatch(getFloorsOfSite(siteId)).then((res) => {
      setFloors(res.payload.data);
    });
  }, []);

  useEffect(() => {

    if (floorId) {
      _loadCanvasData();
    }
    
  }, []);

  /**=====================================================**/
  const _loadCanvasData = async () => {
    await dispatch(getFloor(floorId))
        .then(async (res) => {
          setFloorData({
            description: res.payload.description,
            site: siteId,
            floor_no: res.payload.floor_no,
            floor_data: res.payload.floor_data,
          });
        //return res?.payload?.floor_data;
          if (res?.payload?.floor_data) {
            try {
              // Create a new Fabric.js canvas
              const canvas = new fabric.Canvas(canvasRef.current);
              await canvas.loadFromJSON(res?.payload?.floor_data, async () => {
                canvas.renderAll.bind(canvas)
                setCanvasListeners(canvas);

                let backgroundImage = canvas.backgroundImage;
                if (!selectedImage) {
                  setSelectedImage(backgroundImage);
                }
                if (backgroundImage) {
                  setCanvasBackground(canvas, backgroundImage);
                }

                const canvas_objects = canvas.getObjects();
                let _canvas_objects = [...canvas_objects];

                await loadMarkers(canvas, _canvas_objects);

                matches ? resizeCanvasToParent(canvas) : resizeCanvasToBackground(canvas);

              });
              canvas.renderAll();

              setCanvas(canvas);
              setCanvasData(canvas.toJSON());
            }
            catch (error) {
              console.error("Error loading canvas data:", error);
            }
          }
    });
  }

  const loadMarkers = async (canvas, _canvas_objects) => {
    await dispatch(getDoorsofFloor(floorId))
        .then(async (floorDoorsResponse) => {
          if (floorDoorsResponse?.payload?.data?.length > 0) {
            await floorDoorsResponse.payload.data.forEach((door) => {
              let founded_door = _canvas_objects.find(obj => obj?.asset?.id === door?.data?.id || obj.asset.data?.id === door?.data?.id);
              if(founded_door) {
              // Create markers and render them on the canvas
              let marker = createMarkerFromDoor(founded_door, door);
              canvas.add(marker);
              }
            });
          }
        });
  };
  const createMarkerFromDoor = (founded_door, door) => {

    const { color } = getStatus(founded_door.status);
    if (founded_door.type === "group") {
      founded_door.asset = door;
      founded_door.getObjects()[0].fill = color;
      founded_door.selectable = isEditMode;
      founded_door.evented = true;
      founded_door.on("mousedblclick", handleMarkerDoubleClick);
      founded_door.on("mouseover", handleMarkerMouseEnter);
      founded_door.on("mouseout", handleMarkerMouseLeave);
      founded_door.on("moving", moveHandler);
      founded_door.hasControls = false;
      if(matches){
        founded_door.on("mousedown", handleSingleClick);
      }
    }
    return founded_door;
  };

  const setCanvasBackground = (canvas, backgroundImage) => {
    console.log("images", {w: backgroundImage.width, h: backgroundImage.height});
    canvas.setBackgroundImage(
        backgroundImage,
        canvas.renderAll.bind(canvas)
    );
    // canvas.centerObject(backgroundImage);
  };

  const resizeCanvasToParent = (canvas) => {
    let canvasContainerRef = canvasContainer.current;
    const containerWidth = canvasContainerRef.clientWidth;
    const containerHeight = canvasContainerRef.clientHeight;

    const scaleX = containerWidth / canvas.width;
    const scaleY = containerHeight / canvas.height;

    canvas.setWidth(containerWidth);
    canvas.setHeight(containerHeight);
    // canvas.setZoom(0.5);

    // canvas.backgroundImage.scaleToWidth(containerWidth);
    // canvas.backgroundImage.scaleToHeight(containerHeight);
    console.log('resizing', {w: containerWidth, h: containerHeight, container: canvasContainer});

    // canvas.getObjects().forEach((obj) => {
    //   obj.scaleX *= scaleX;
    //   obj.scaleY *= scaleY;
    //   obj.left *= scaleX;
    //   obj.top *= scaleY;
    //   obj.setCoords();
    // });
  }

  const resizeCanvasToBackground = (canvas) => {

    let backgroundImage = canvas.backgroundImage;
    canvas.setWidth(backgroundImage.width);
    canvas.setHeight(backgroundImage.height );

    // canvas.backgroundImage.scaleToWidth(containerWidth);
    // canvas.backgroundImage.scaleToHeight(containerHeight);
    console.log('resizing to image', {w: backgroundImage.width, h: backgroundImage.height});

    // canvas.getObjects().forEach((obj) => {
    //   obj.scaleX *= scaleX;
    //   obj.scaleY *= scaleY;
    //   obj.left *= scaleX;
    //   obj.top *= scaleY;
    //   obj.setCoords();
    // });
  }


  /**=====================================================**/


  //My code
  useEffect(() => {
    if (floorId) {
      // if (i == 1) {
      //   i++;
      //   loadCanvasData();
      // }
      setEditing(true);
    } else if (canvasRef.current && selectedImage) {

      const newCanvas = new fabric.Canvas(canvasRef.current, {
        width: matches ? width : width,
        height: height,
      });

      const reader = new FileReader();
      newCanvas.on("mouse:down", (opt) => {
        var evt = opt.e;

        if (!opt.target) {
          newCanvas.isDragging = true;
          newCanvas.selection = false;
          newCanvas.lastPosX = evt.clientX;
          newCanvas.lastPosY = evt.clientY;
        }
      });
      newCanvas.on("mouse:move", (opt) => {
        if (newCanvas.isDragging) {
          var e = opt.e;
          var vpt = newCanvas.viewportTransform;
          vpt[4] += e.clientX - newCanvas.lastPosX;
          vpt[5] += e.clientY - newCanvas.lastPosY;
          newCanvas.requestRenderAll();
          newCanvas.lastPosX = e.clientX;
          newCanvas.lastPosY = e.clientY;
        }
      });
      newCanvas.on("mouse:up", (opt) => {
        // on mouse up we want to recalculate new interaction
        // for all objects, so we call setViewportTransform
        newCanvas.setViewportTransform(newCanvas.viewportTransform);
        newCanvas.isDragging = false;
        newCanvas.selection = true;
      });
      newCanvas.on("mouse:wheel", function (opt) {
        var delta = opt.e.deltaY;
        var zoom = newCanvas.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;
        newCanvas.setZoom(zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();
      });

      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;

        image.onload = () => {
          const fabricImage = new fabric.Image(image);
          // fabricImage.set({
          //   scaleX: matches ? 1.4 : 1,
          //   scaleY: matches ? 1 : 1,
          // });

          newCanvas.setWidth(fabricImage.width);
          newCanvas.setHeight(fabricImage.height);

          newCanvas.setBackgroundImage(
            fabricImage,
            newCanvas.renderAll.bind(newCanvas),
            {
              backgroundImageOpacity: 1,
              backgroundImageStretch: true,
            }
          );
          // newCanvas.centerObject(fabricImage);
          // newCanvas.setWidth(matches ? newCanvas.width : fabricImage.width);
          // newCanvas.setHeight(matches ? newCanvas.height : fabricImage.height);
          // newCanvas.setZoom(matches ? 0.5 : 1);

          // Update the canvas data
          setCanvasData(newCanvas.toJSON());
        };
      };

      reader.readAsDataURL(selectedImage);
      newCanvas.hoverCursor = "default";
      // Update the fabric canvas instance
      // newCanvas.on("touchstart", function (event) {
      //   handleTouch(event);
      // });

      // newCanvas.on("touchmove", function (event) {
      //   handleTouch(event);
      // });

      newCanvas.renderAll();
      setCanvas(newCanvas);
    }
  }, [canvasRef, selectedImage, floorId]);
  useEffect(() => {
    document.title = "Floor";
  }, []);

  const setCanvasListeners = (canvas) => {
    let isTouchPanning = false;
    let lastTouchPos = { x: 0, y: 0 };
  
    // Variables for touch zooming
    let lastDist = 0;


   matches && canvas.on("mouse:down", (opt) => {
      var evt = opt.e;

      if (!opt.target) {
        canvas.isDragging = matches ? false : true;
        canvas.selection = false;
        canvas.lastPosX = evt.clientX;
        canvas.lastPosY = evt.clientY;
      }
    });

    matches && canvas.on("mouse:move", (opt) => {
      if (canvas.isDragging) {
        var e = opt.e;
        var vpt = canvas.viewportTransform;
        vpt[4] += e.clientX - canvas.lastPosX;
        vpt[5] += e.clientY - canvas.lastPosY;
        canvas.requestRenderAll();
        canvas.lastPosX = e.clientX;
        canvas.lastPosY = e.clientY;
      }
    });

    matches && canvas.on("mouse:up", (opt) => {
      // on mouse up we want to recalculate new interaction
      // for all objects, so we call setViewportTransform
      canvas.setViewportTransform(canvas.viewportTransform);
      canvas.isDragging = false;
      canvas.selection = true;
    });

    matches && canvas.on("mouse:wheel", function (opt) {
      var delta = opt.e.deltaY;
      var zoom = canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvas.setZoom(zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
      // resizeCanvasToParent(canvas);
    });

    // !matches &&  canvas.on("touchstart", function (event) {
    //   handleTouch(event);
    // });

    // !matches && canvas.on("touchmove", function (event) {
    //   handleTouch(event);
    // });

    canvas.on("touchstart", function (opt) {
      if (opt.e.touches.length === 1) {
        isTouchPanning = !opt.target;  // Start panning if the touch is not on a target
        lastTouchPos.x = opt.e.touches[0].clientX;
        lastTouchPos.y = opt.e.touches[0].clientY;
  
        if (opt.target && opt.target.selectable) {
          handleTouch(opt.e);
        }
      } else if (opt.e.touches.length === 2) {
        isTouchPanning = false;
        lastDist = getTouchDist(opt.e.touches);
      }
    });
  
    canvas.on("touchmove", function (opt) {
      if (isTouchPanning && opt.e.touches.length === 1) {
        var e = opt.e.touches[0];
        var vpt = canvas.viewportTransform;
        vpt[4] += e.clientX - lastTouchPos.x;
        vpt[5] += e.clientY - lastTouchPos.y;
        canvas.requestRenderAll();
        lastTouchPos.x = e.clientX;
        lastTouchPos.y = e.clientY;
      } else if (opt.e.touches.length === 2) {
        var newDist = getTouchDist(opt.e.touches);
        var zoom = canvas.getZoom();
        zoom *= newDist / lastDist;
        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;
        canvas.setZoom(zoom);
        lastDist = newDist;
      }
  
      if (opt.target && opt.target.selectable) {
        handleTouch(opt.e);
      }
  
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
  
    canvas.on("touchend", function (opt) {
      isTouchPanning = false;
    });

    

    canvas.hoverCursor = "default";
  }

  const getHeightForCanvas = () => {
    let canvasContainerRef = canvasContainer.current;
    let navBarRef = navBar.current;
    // first we will set layout containers height
    let availableHeight = canvasContainerRef.offsetHeight - navBarRef.offsetHeight;
    let floorLayoutRef = floorLayout.current;
    floorLayoutRef.style.height = availableHeight + "px";
    let toolBarRef = toolBar.current;
    return (floorLayoutRef.offsetHeight - toolBarRef.offsetHeight);
  }
  const handleCancelModal = () => {
    if (selectedMarker) {
      // Remove the selected marker from the canvas
      canvas.remove(selectedMarker);
      setCanvasData(canvas.toJSON());
      setSelectedMarker(null);
    }
    setModalOpen(false);
  };

  const handleImageUpload = (event) => {
    const file = event;

    if (file && file.type.includes("image")) {
      setSelectedImage(file);
      setImageError("");
    } else {
      setSelectedImage(null);
      setImageError("Please select an image");
      return;
    }

    setSelectedImage(file);

    // Create a temporary image to get its dimensions and calculate the aspect ratio
    const tempImage = new Image();
    tempImage.src = URL.createObjectURL(file);

    tempImage.onload = () => {
      const imgWidth = tempImage.width;
      const imgHeight = tempImage.height;

      // Calculate the aspect ratio of the uploaded image
      const aspectRatio = imgWidth / imgHeight;

      // Calculate the maximum width and height for the canvas
      const maxWidth = 1000; // Set the maximum width of the canvas here
      const maxHeight = 700; // Set the maximum height of the canvas here

      // Calculate the canvas size based on the aspect ratio
      // if (aspectRatio >= maxWidth / maxHeight) {
      //   setCanvasWidth(maxWidth);
      //   setCanvasHeight(maxWidth / aspectRatio);
      // } else {
      //   setCanvasHeight(maxHeight);
      //   setCanvasWidth(maxHeight * aspectRatio);
      // }

      // Now you can proceed with the rest of the logic for adding the image to the canvas.
    };
    setIsOpen(false);
  };

  useEffect(() => {
    if (floorData.floor_data != null && !floorId) {
      dispatch(addFloor({ data: floorData })).then((res) => {
        if (!res.error) {
          --i;
          navigate(`/sites/${siteId}`);
        }
      });
    }
  }, [floorData.floor_data]);

  function DeleteFloor() {
    dispatch(deleteFloor(floorId)).then((res) => {
      if (!res.error) {
        navigate(-1);
      }
    });
  }

  useEffect(() => {
    if (floorData.floor_data != null && isEdit) {
      dispatch(updateFloor({ id: floorId, data: floorData })).then((res) => {
        if (!res.error) {
          if (!doorAdded) {
            --i;
            setIsEdit(false);
            navigate(`/sites/${siteId}`);
          } else {
            setIsEdit(false);
            setDoorAdded(false);
          }
        }
      });
    }
  }, [floorData.floor_data]);

  const handleTouch = (event) => {
    if (event.target && event.target.selectable) {
      var touch = event.targetTouches[0];
      var offsetX = touch.clientX - canvas._offset.left;
      var offsetY = touch.clientY - canvas._offset.top;
      event.target.set({
        left: offsetX,
        top: offsetY,
      });
      canvas.renderAll();
    }
  }
  
  const getTouchDist = (touches) => {
    return Math.sqrt(
      Math.pow(touches[0].clientX - touches[1].clientX, 2) +
      Math.pow(touches[0].clientY - touches[1].clientY, 2)
    );
  }
  var moveHandler = function (evt) {
    var movingObject = evt.target;
    evt.e.stopPropagation();
  };

  const handleAddMarker = () => {
    if (canvas && (isSubmitted || editing)) {
      const markerImageUrl = placeholderImage;

      //
      fabric.loadSVGFromURL(markerImageUrl, function (objects, options) {
        const icon = fabric.util.groupSVGElements(objects, options);

        icon.set({
          left: canvas.width - canvas.width / 2,
          top: canvas.height - canvas.height / 2,
          hasControls: false,
        });

        icon.getObjects()[0].fill = "#DD6E0F";

        icon.on("mousedblclick", handleMarkerDoubleClick);
        icon.on("mouseover", handleMarkerMouseEnter);
        icon.on("mouseout", handleMarkerMouseLeave);
        icon.on("moving", moveHandler);
        {
          matches && icon.on("mousedown", handleSingleClick);
        }

        setSelectedMarker(icon);
        setModalOpen(true);
        const updatedMarkers = [...markers, icon];

        canvas.add(icon);

        setMarkers(updatedMarkers);
        setCanvasData(canvas.toJSON());
        canvas.renderAll();
      });
    } else if (!isSubmitted) {
      handleClick();
    }
  };

  const handleSaveMarkerData = (data) => {
    if (selectedMarker) {
      // Attach the data to the selected marker
      selectedMarker.asset = data;
      // canvas.add(selectedMarker)
      // setCanvasData(canvas.toJSON());
      // canvas.renderAll();
      // const index = canvas
      //   .getObjects()
      //   .findIndex((ele) => ele == selectedMarker);
      // canvas.getObjects()[index].asset = data;
      setIsEdit(true);
      setDoorAdded(true);
      setModalOpen(false);
      const canvasData = canvas.toJSON();
      for (let i = 0; i < canvasData.objects.length; i++) {
        canvasData.objects[i].asset = canvas.getObjects()[i].asset;
      }

      // Close the modal after saving data
      setCanvasData(canvas.toJSON());
      setFloorData((prevData) => ({
        ...prevData,
        floor_data: JSON.stringify(canvasData),
      }));
    }
  };

  const handleSingleClick = (e) => {
    setIsDraggingObj(true);
    e.e.stopPropagation();
    setAsset(e.target.asset);
    const status = e.target.asset.status ? e.target.asset.status : "OK";
    setPopupPosition({
      left: 30,
      bottom: 120,
      background: getStatus(status).background,
    });
    setShowPopup(true);
    setOpen(true);
  };

  const onChangeSite = (e) => {
    if (e != 0) {
      navigate(`/sites/${siteId}/floor/${e}`);
      window.location.reload(false);
    }
  };

  const handleMarkerDoubleClick = (e) => {
    // setPopupPosition({ left: e.e.clientX - 100, top: e.e.clientY - 110, background: "linear-gradient(0deg, rgba(235, 67, 53, 0.1), rgba(235, 67, 53, 0.1)), linear-gradient(0deg, #FFFFFF, #FFFFFF" });
    // setShowPopup(true);

    e.e.stopPropagation();

    if (user?.roleId == process.env.REACT_APP_AGENT_ROLE_ID) {
      navigate(
        `/sites/${siteId}/floor/${floorId}/asset/${
          e.target.asset.data ? e.target.asset.data.id : e.target.asset.id
        }/sensor/addSensor`
      );
    } else {
      navigate(
        `/sites/${siteId}/floor/${floorId}/asset/${
          e.target.asset.data ? e.target.asset.data.id : e.target.asset.id
        }`
      );
    }
  };

  const handleMarkerMouseEnter = (e) => {
    e.e.stopPropagation();
    
    setAsset(e.target.asset);
    const status = e.target.asset.status ? e.target.asset.status : "OK";
    setPopupPosition({
      left: e.e.clientX - 100,
      top: e.e.clientY - 110,
      background: getStatus(status).background,
    });
    setShowPopup(true);
    setOpen(true);
  };

  const handleMarkerMouseLeave = (e) => {
    setAsset(null);
    setOpen(false);
    setShowPopup(false);
  };

  const handleCanvasSubmit = () => {
    if (canvas) {
      const canvasData = canvas.toJSON();
      for (let i = 0; i < canvasData.objects.length; i++) {
        canvasData.objects[i].asset = canvas.getObjects()[i].asset;
      }

      if (floorId) {
        setIsEdit(true);
      }

      setFloorData((prevData) => ({
        ...prevData,
        floor_data: JSON.stringify(canvasData),
      }));
      setIsSubmitted(true);
    }
  };

  useEffect(() => {
    if (canvas) {
      canvas.forEachObject(function (obj) {
        obj.selectable = isEditMode; // Toggle selectability of objects
        obj.evented = true; // Toggle events on objects (like click, hover)
      });
      canvas.renderAll();
    }
  }, [isEditMode]);

  useEffect(() => {
    setIsEdit(false);
  }, []);

  const action = (
    <React.Fragment>
      <Button
        className="customButtomOverAll"
        color="secondary"
        size="small"
        onClick={handleClose}
      >
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="flex-space">
      <div className="canvas-container" ref={canvasContainer}>
        <div className="navBar w-100 .p-h-10 bs" ref={navBar} >
          <div className="TitleBack">
            <IconButton onClick={() => navigate(-1)}>
              <KeyboardBackspaceIcon />
            </IconButton>
            <Headings fontSize={18}>
              {value?.find((ele) => ele.data.id == siteId)?.data.name}
            </Headings>
          </div>
          <div className="nav-end">
            <Select
              options={floors}
              onChange={onChangeSite}
              value={floorId}
              title={"Floors"}
              key={"description"}
              placeholder={"Floors"}
              area={"floors"}
            />
          </div>
        </div>

        <div className="floorlayout" ref={floorLayout}>
          <div className="toolbar" ref={toolBar}>
            {user?.roleId != process.env.REACT_APP_AGENT_ROLE_ID && (
              <>
                {(isEditMode || !floorId) && (
                  <button
                    className="button customButtomOverAll"
                    onClick={() => setIsOpen(true)}
                  >
                    {floorId ? "Edit " : "Add "} Floor
                  </button>
                )}

                {isEditMode && (
                  <>
                    <button
                      className="button customButtomOverAll"
                      onClick={DeleteFloor}
                    >
                      Delete Floor
                    </button>
                    {selectedImage && (
                      <>
                        <div>
                          <button
                            className="button customButtomOverAll"
                            onClick={handleAddMarker}
                          >
                            Add Door
                          </button>
                        </div>
                      </>
                    )}
                    {canvasData && (
                      <button
                        className="button customButtomOverAll"
                        onClick={handleCanvasSubmit}
                      >
                        {loading ? <CircularProgress /> : "Submit"}
                      </button>
                    )}
                  </>
                )}
              </>
            )}

            <div>
              {user?.roleId != process.env.REACT_APP_USER_ROLE_ID &&
                user?.roleId != process.env.REACT_APP_ORG_ADMIN_ROLE_ID && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isEditMode}
                        onChange={(e) => {
                          setisEditMode(!isEditMode);
                        }}
                      />
                    }
                    label={isEditMode ? "Edit" : "View"}
                  />
                )}
            </div>
          </div>

          <div className="canvas-wrapper">
            <canvas ref={canvasRef}></canvas>
          </div>
        </div>

        {showPopup && (
          <CustomTooltip
            data={asset}
            position={popupPosition}
            floorNumber={floorData?.floor_no}
            setShowPopUp={setShowPopup}
          />
        )}

        {selectedMarker && (
          <MarkerModal
            isOpen={isModalOpen}
            onRequestClose={handleCancelModal}
            onSave={handleSaveMarkerData}
            canvas={canvas}
          />
        )}
      </div>
      <AssetDrawer open={true} setOpen={setOpen} asset={asset} />
      <Snackbar
        open={open1}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Please Submit Floor First"
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">Please Submit Floor First</Alert>
      </Snackbar>
      <CustomModal
        floorData={floorData}
        isOpen={isOpen}
        imageError={imageError}
        setIsOpen={setIsOpen}
        handleImageUpload={handleImageUpload}
        setFloorData={setFloorData}
      />
      <SwipeableEdgeDrawer />
    </div>
  );
};
export default Floor;

const getStatus = (stat) => {
  let status = parseInt(stat);

  if (status >= 200 && status < 300) {
    const Component = () => {
      return (
        <WarningAmberIcon sx={{ width: 16, height: 16, color: "#34A853" }} />
      );
    };
    return {
      Component,
      color: "#34A853",
      background:
        "linear-gradient(0deg, var(--green-10, rgba(52, 168, 83, 0.10)) 0%, var(--green-10, rgba(52, 168, 83, 0.10)) 100%), var(--White, #FFF)",
    };
  } else if (status >= 300 && status < 400) {
    const Component = () => {
      return (
        <WarningAmberIcon
          sx={{ color: "rgba(221, 173, 1, 1)", width: "16px", heigth: "16px" }}
        />
      );
    };
    return {
      Component,
      color: "rgba(221, 173, 1, 1)",
      background:
        "linear-gradient(0deg, var(--yellow-10, rgba(221, 173, 1, 0.10)) 0%, var(--yellow-10, rgba(221, 173, 1, 0.10)) 100%), var(--White, #FFF)",
    };
  } else if (status >= 400 && status < 500) {
    const Component = () => {
      return (
        <WarningAmberIcon
          sx={{ color: "rgba(235, 67, 53, 1)", width: "16px", heigth: "16px" }}
        />
      );
    };
    return {
      Component,
      color: "#EB4335",
      background:
        "linear-gradient(0deg, var(--red-10, rgba(235, 67, 53, 0.10)) 0%, var(--red-10, rgba(235, 67, 53, 0.10)) 100%), var(--White, #FFF)",
    };
  } else {
    const Component = () => {
      return (
        <WarningAmberIcon sx={{ width: 16, height: 16, color: "#34A853" }} />
      );
    };
    return {
      Component,
      color: "#DD6E0F",
      background:
        "linear-gradient(0deg, var(--green-10, rgba(52, 168, 83, 0.10)) 0%, var(--green-10, rgba(52, 168, 83, 0.10)) 100%), var(--White, #FFF)",
    };
  }
};

function useWindowDimensions() {
  const hasWindow = typeof window !== "undefined";

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
}
