import React, { useEffect, useRef, useState } from 'react';
import "./Asset.css";
import AssetDrawer from "../../Components/Drawers/AssetDrawer";
import placeholderImage from "../../Assets/mappin.png";
import { getStatusMessage, getBatteryStatus, getStatusMessages } from "../../Utils/Functions/getStatusMessages";
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from "@mui/material/useMediaQuery";
import MarkerModal from "../../Components/Modal/MarkerModal";
import { useParams, useNavigate } from "react-router-dom";
import { Paper } from "@mui/material";
import NotesModal from "../../Components/Modal/Notes";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import Select from "../../Shared/CustomElement/DropDown";
import { Headings, SubHeadings } from "../../Shared/Fonts/Fonts";
import Divider from '@mui/material/Divider';
import { Button } from "../../Shared/CustomElement/Button";
import { AssetSidePanel } from "../../Components/Drawers/AssetSidePanel";
import { getSensorsbyDoor } from "../../Store/Features/Sensors";
import { useDispatch } from 'react-redux';
import { getDoor } from "../../Store/Features/Door";
import { getFloor } from '../../Store/Features/Floors';
import { useSelector } from 'react-redux';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { getSites } from "../../Store/Features/Sites";


export const AssetInspection = () => {
  const navigate = useNavigate();
  const { siteId, floorId, assetId } = useParams();
  const { value } = useSelector(state => state.sites);
  const path = useParams().pathName;
  const dispatch = useDispatch();
  const [sensors, setSensors] = useState([]);
  const [door, setDoor] = useState({});
  const matches = useMediaQuery("(max-width:769px)");
  const [floor, setFloor] = useState({});
  const [doors, setDoors] = useState([]);


  // const currentURL = window.location.href;
  // const urlSegments = currentURL.split('/');
  // const idFromURL = urlSegments[urlSegments.length - 1];
  // let canvasData = JSON.parse(localStorage.getItem('canvas')); 
  // console.log(canvasData)
  // const indexToRemove = canvasData.objects.findIndex(obj => obj.asset.id === idFromURL);
  // canvasData.objects.splice(indexToRemove, 1);
  // console.log('removed', canvasData)










  // useEffect(() => {
  //   dispatch(getFloor(floorId)).then(res => {
  //     setFloor(res.payload);
  //   });
  // }, []);
  useEffect(() => {
    dispatch(getDoor(assetId)).then(res => {

      setDoor(res.payload);
    });;
  }, []);
  useEffect(() => {
    dispatch(getSensorsbyDoor(assetId)).then(res => {
      setSensors(res.payload.rows);
    });
  }, []);


  return (
    <div className="AssetPage">
      <div className="StatusPart">
        <div className="navBar">
          <div className="navBar w-100 .p-h-10 bs">
            <div className="TitleBack">
              <IconButton onClick={() => navigate(-1)}>
                <KeyboardBackspaceIcon />
              </IconButton>
              <Headings fontSize={18}>
                {value.find((ele) => ele.data.id == siteId)?.data.name}
              </Headings>
            </div>
            {/* <div className="nav-end">
              <Select
                options={doors}
                onChange={onChangeSite}
                value={assetId}
                title={"Doors"}
                area={"doors"}
              />
            </div> */}
          </div>
          {/* <div className="ButtonsGorupAsset"> */}
          {/* <button className="deleteButton" onClick={handleDeleteMarker}>Delete Door</button> */}
          {/* <button className="deleteButton" onClick={() => navigate(`/sites/${siteId}/floor/${floorId}/asset/${assetId}/sensors/addSensor`)}>Add Sensor</button> */}
          {/* </div> */}
        </div>

        <div className="Documents p-v-10">
          <div className="options" >
            <Button color={"#105998"} title={"Manufacture Certificate"} hide={door?.manufacturer_certificate?.length > 0 ? false : true} href={`${door.manufacturer_certificate?.length > 0 ? door?.manufacturer_certificate[0].publicUrl : null}`} />
            <Button color={"#105998"} title={"Install Certificate"} hide={door?.installation_certificate?.length > 0 ? false : true} href={`${door.installation_certificate?.length > 0 ? door?.installation_certificate[0].publicUrl : null}`} />
            <Button color={"#105998"} title={"Maintenance Records"} hide={door?.maintaince_records
              ?.length > 0 ? false : true} href={`${door.maintaince_records?.length > 0 ? door?.maintaince_records[0].publicUrl : null}`} />
          </div>
        </div>
        {matches ? (
          <div style={{ width: "100%" }}>
            <Accordion
              sx={{
                background: "transparent",
                borderBottom: "1px solid black",
              }}
              elevation={0}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ width: "100%" }}
              >
                <Headings>Status Report</Headings>
              </AccordionSummary>
              <AccordionDetails>
                <div className="status-content">
                  <DoorReport {...door} />
                  <DoorUtilisation {...door} />
                  <DoorUtilisationInterval {...door} />
                </div>
              </AccordionDetails>
            </Accordion>
            {sensors?.map(sensor => {
              return (
                <Accordion
                  sx={{
                    background: "transparent",
                    borderBottom: "1px solid black",
                  }}
                  elevation={0}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    sx={{ width: "100%" }}
                  >
                    <Headings>
                      {`${sensor.location} Sensors`} {sensor.sensor_code}
                    </Headings>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TopSensor {...sensor} />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        ) : (
          <div className="status">
            <Headings fontSize={18} fontWeight={800}>
              Status Report
            </Headings>
            <div className="status-content">
              <DoorReport {...door} />
              <DoorUtilisation {...door} />
              <DoorUtilisationInterval {...door} />
            </div>
            <div className="sensor-content">
              {sensors?.map(sensor => {
                return <TopSensor {...sensor} />;
              })}
            </div>
          </div>
        )}
      </div>
      {/* <AssetSidePanel
      siteName={floor?.site?.name}
      assetName={door?.description}
      floorNumber={floor?.floor_number}
      SitesLocation={
        floor?.site?.address.split(" ")[0] +
        " " +
        (floor?.site?.address.split(" ")[1] || "")
      }
      image={
        door.door_image
          ? door.door_image.length > 0
            ? door.door_image[0].publicUrl
            : null
          : null
      }
    /> */}
    </div>
  );
};


const DoorReport = (props) => {
  const {
    status,
    last_inspection,
    due_inspection,
    week_Utilisation,
    lifetime_Utilisation,
    report_interval,
    open_interval,
  } = props;
  return (
    <Paper className="reportCards" sx={{ padding: "25px 32px 25px 32px", border: '1px solid rgba(238, 238, 238, 1)', gap: "15px", flexDirection: "column", display: "flex", width: "25%", borderRadius: "" }}>
      <Tooltip title={`${getStatusMessages(status)}`} >
        <div className="DoorReport-Upper">
          <div style={{ width: 7.03, height: 7.03, backgroundColor: getStatus(status).color, borderRadius: "50%" }} />
          <Headings fontSize={16} color="rgba(99, 115, 129, 1)" fontWeigth={450}>Report</Headings>
          <Headings fontSize={16} color="rgba(33, 43, 54, 1)" fontWeigth={450}>{getStatusMessage(status)}</Headings>
        </div>
      </Tooltip>
      <div className="DoorReport-down">
        <Headings fontSize={16} color="rgba(99, 115, 129, 1)" fontWeigth={450}>
          Compliance Inspection
        </Headings>
        <div className="DoorReport-Upper">
          <div
            style={{
              width: 7.03,
              height: 7.03,
              backgroundColor: "rgba(52, 168, 83, 1)",
              borderRadius: "50%",
            }}
          />
          <Headings
            fontSize={16}
            color="rgba(99, 115, 129, 1)"
            fontWeigth={450}
          >
            Last
          </Headings>
          <Headings fontSize={16} color="rgba(33, 43, 54, 1)" fontWeigth={450}>
            {last_inspection || "N/A"}
          </Headings>
        </div>
        <div className="DoorReport-Upper">
          <div
            style={{
              width: 7.03,
              height: 7.03,
              backgroundColor: "rgba(52, 168, 83, 1)",
              borderRadius: "50%",
            }}
          />
          <Headings
            fontSize={16}
            color="rgba(99, 115, 129, 1)"
            fontWeigth={450}
          >
            Due
          </Headings>
          <Headings fontSize={16} color="rgba(33, 43, 54, 1)" fontWeigth={450}>
            {due_inspection || "N/A"}
          </Headings>
        </div>
      </div>
    </Paper>
  );
};



const DoorUtilisation = (props) => {
  const { week_Utilisation, lifetime_Utilisation, createdAt } = props;
  return (
    <Paper
      className="reportCards"
      sx={{
        padding: "25px 32px 25px 32px",
        border: "1px solid rgba(238, 238, 238, 1)",
        gap: "15px",
        flexDirection: "column",
        display: "flex",
        width: "25%",
        borderRadius: "",
      }}
    >
      <div className="DoorReport-down">
        <Headings fontSize={16} color="rgba(99, 115, 129, 1)" fontWeigth={450}>
          Door utilisation
        </Headings>
        <div className="DoorReport-Upper">
          <div
            style={{
              width: 7.03,
              height: 7.03,
              backgroundColor: "rgba(52, 168, 83, 1)",
              borderRadius: "50%",
            }}
          />
          <Headings
            fontSize={16}
            color="rgba(99, 115, 129, 1)"
            fontWeigth={450}
          >
            Last Week
          </Headings>
          <Headings fontSize={16} color="rgba(33, 43, 54, 1)" fontWeigth={450}>
            {week_Utilisation || "N/A"}
          </Headings>
        </div>
        <div className="DoorReport-Upper">
          <div
            style={{
              width: 7.03,
              height: 7.03,
              backgroundColor: "rgba(52, 168, 83, 1)",
              borderRadius: "50%",
            }}
          />
          <Headings
            fontSize={16}
            color="rgba(99, 115, 129, 1)"
            fontWeigth={450}
          >
            Lifetime
          </Headings>
          <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
            <Headings
              fontSize={16}
              color="rgba(33, 43, 54, 1)"
              fontWeigth={450}
            >
              {lifetime_Utilisation || "N/A"}{" "}
            </Headings>
            <Headings
              Headings
              fontSize={14}
              color="rgba(99, 115, 129, 1)"
              fontWeigth={450}
            >
              ({createdAt?.split("T")[0]})
            </Headings>
          </div>
        </div>
      </div>
    </Paper>
  );
};

const DoorUtilisationInterval = (props) => {
  const { report_interval, open_interval } = props;
  return (
    <Paper
      className="reportCards"
      sx={{
        padding: "25px 32px 25px 32px",
        border: "1px solid rgba(238, 238, 238, 1)",
        gap: "15px",
        flexDirection: "column",
        display: "flex",
        width: "25%",
        borderRadius: "",
      }}
    >
      <div className="DoorReport-down">
        <Headings fontSize={16} color="rgba(99, 115, 129, 1)" fontWeigth={450}>
          Door utilisation
        </Headings>
        <div className="DoorReport-Upper">
          <div
            style={{
              width: 7.03,
              height: 7.03,
              backgroundColor: "rgba(52, 168, 83, 1)",
              borderRadius: "50%",
            }}
          />
          <Headings
            fontSize={16}
            color="rgba(99, 115, 129, 1)"
            fontWeigth={450}
          >
            Report Interval
          </Headings>
          <Headings fontSize={16} color="rgba(33, 43, 54, 1)" fontWeigth={450}>
            {report_interval || "N/A"}
          </Headings>
        </div>
        <div className="DoorReport-Upper">
          <div
            style={{
              width: 7.03,
              height: 7.03,
              backgroundColor: "rgba(52, 168, 83, 1)",
              borderRadius: "50%",
            }}
          />
          <Headings
            fontSize={16}
            color="rgba(99, 115, 129, 1)"
            fontWeigth={450}
          >
            Open Timeout
          </Headings>
          <Headings fontSize={16} color="rgba(33, 43, 54, 1)" fontWeigth={450}>
            {open_interval || "N/A"}
          </Headings>
        </div>
      </div>
    </Paper>
  );
};

const TopSensor = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    status,
    location,
    sensor_code,
    id,
    battery,
    complianceDate,
    complianceDue,
    open_interval,
    report_interval,
    lifetime_Utilisation,
    week_Utilisation,
  } = props;
  const matches = useMediaQuery("(max-width:769px)");
  return (
    <>
      <div className="reportCards" style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        width: "31%",
      }} onClick={() => setIsOpen(true)}>
        <div style={{ display: 'flex', gap: 10 }}>
          {!matches && (
            <>
              <Headings
                textTransform="capitalize"
                fontSize={16}
                fontWeight={500}
              >
                {location} Sensor
              </Headings>
              <Headings
                textTransform="capitalize"
                fontSize={16}
                fontWeight={800}
                color={"#637381"}
              >
                {sensor_code}
              </Headings>
            </>
          )}
        </div>
        <Paper sx={{
          padding: "25px 32px 25px 32px",
          border: "1px solid rgba(238, 238, 238, 1)",
          gap: "15px",
          flexDirection: "column",
          display: "flex",
          // width: "100%",
        }}>
          <Tooltip title={`${getStatusMessages(status)}`} >
            <div className="DoorReport-Upper">

              <div style={{ width: 7.03, height: 7.03, backgroundColor: getStatus(status).color, borderRadius: "50%" }} />
              <Headings fontSize={16} color="rgba(99, 115, 129, 1)" fontWeigth={450}>Report</Headings>
              <Headings textTransform="capitalize" fontSize={16} color="rgba(33, 43, 54, 1)" fontWeigth={450}>{getStatusMessage(status)}</Headings>

            </div>
          </Tooltip>
          <div className="DoorReport-Upper">
            <div style={{ width: 7.03, height: 7.03, backgroundColor: getBatteryStatus(status).color, borderRadius: "50%" }} />
            <Headings fontSize={16} color="rgba(99, 115, 129, 1)" fontWeigth={450}>Battery</Headings>
            <Headings fontSize={16} color="rgba(33, 43, 54, 1)" fontWeigth={450}>{getBatteryStatus(status).message}</Headings>
          </div>
          <div className="DoorReport-down">
            <Headings
              fontSize={16}
              color="rgba(99, 115, 129, 1)"
              fontWeigth={450}
            >
              Compliance Inspection
            </Headings>
            <div className="DoorReport-Upper">
              <Headings
                fontSize={16}
                color="rgba(99, 115, 129, 1)"
                fontWeigth={450}
              >
                Last
              </Headings>
              <Headings
                fontSize={16}
                color="rgba(33, 43, 54, 1)"
                fontWeigth={450}
              >
                {complianceDate || "N/A"}
              </Headings>
            </div>
            <div className="DoorReport-Upper">
              <Headings
                fontSize={16}
                color="rgba(99, 115, 129, 1)"
                fontWeigth={450}
              >
                Due
              </Headings>
              <Headings
                fontSize={16}
                color="rgba(33, 43, 54, 1)"
                fontWeigth={450}
              >
                {complianceDue || "N/A"}
              </Headings>
            </div>
          </div>
        </Paper>

      </div >
      <NotesModal open={isOpen} setOpen={setIsOpen} id={id} />
    </>
  );
};

const getStatus = (stat) => {
  let status = parseInt(stat);
  if (status >= 200 && status < 300) {
    return {
      color: "#34A853",
      background: "rgba(52, 168, 83, 0.1)",
    };
  } else if (status >= 300 && status < 400) {
    return {
      color: "rgba(221, 173, 1, 1)",
      background: "rgba(221, 173, 1, 0.1)",
    };
  } else if (status >= 400 && status < 500) {
    return {
      color: "rgba(235, 67, 53, 0.1)",
    };
  } else {
    return {
      color: "#34A853",
      background: "rgba(52, 168, 83, 0.1)",
    };
  }
};