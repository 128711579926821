import React from "react";
import "./Door.css";
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';

export const Door = ({ form, setForm }) => {


  const items = Array.from({ length: 10 }, (_, i) => (
    <Hinges key={i} />
  ));


  return (
    <div className="DoorPage">
      <div className="doorContainer" style = {{flexDirection: form.direction == "CW" ? "row-reverse" : "row" }}>
        <div className="door" >

        </div>
        <div className="hinges" >
          {items}
        </div>
        <Gap tabindex="1" side={"north"} subPostion={"left"} form={form} setForm={setForm} name="top_left_measurement" />
        <Gap tabindex="3" side={"east"} subPostion={"Top"} form={form} setForm={setForm} name="top_right_measurement" />
        <Gap tabindex="2" side={"north"} subPostion={"Right"} form={form} setForm={setForm} name={form.direction == "CCW" ? "hinge_top_measurement" : "swing_top_measurement"} />
        <Gap tabindex="7" side={"west"} subPostion={"Bottom"} form={form} setForm={setForm} name={form.direction == "CCW" ? "swing_bottom_measurement" : "hinge_bottom_measurement"} />
        <Gap tabindex="5" side={"south"} subPostion={"right"} form={form} setForm={setForm} name="bottom_right_measurement" />
        <Gap tabindex="6" side={"south"} subPostion={"left"} form={form} setForm={setForm} name="bottom_left_measurement" />
        <Gap tabindex="4" side={"east"} subPostion={"Bottom"} form={form} setForm={setForm} name={form.direction == "CCW" ? "hinge_bottom_measurement" : "swing_bottom_measurement"} />
        <Gap tabindex="8" side={"west"} subPostion={"Top"} form={form} setForm={setForm} name={form.direction == "CCW" ? "swing_top_measurement" : "hinge_top_measurement"} />
        {/* <div className="hinges" style = {{display: form.direction == "CCW" ? null : "none"}}>
          {items}
        </div> */}
      </div>
      <div className="DoorBottom" />
    </div>
  );
};

const Hinges = () => (
  <div className="hinge">

  </div>
);


const Gap = ({ side, subPostion, form, setForm, name, tabindex }) => {


  return (
    <div className="gapContainer" style={getPosition(side, subPostion)}>
      <div className="gapContainerInner" style={getArrowPosition(side)}>
        {side == "east" ? <WestIcon /> : side == "west" ? <EastIcon /> : side == "south" ? <NorthIcon /> : <SouthIcon />}

        <input tabIndex={tabindex} style={{ width: 80 }} value = {form[name]} placeHolder={name} onChange={(e) => setForm({ ...form, [name]: e.target.value })} />
      </div>
    </div>
  );
};


const getPosition = (side, subposition) => {
  let position = {};

  if (side === 'north') {
    position.top = '-15px';
    position.left = subposition == "left" ? "0%" : "65%";

  }

  if (side === 'south') {
    position.bottom = '-59.5px';
    position.left = subposition == "left" ? "0%" : "65%";
  }

  if (side === 'east') {
    position.top = subposition == "Top" ? "20%" : "80%";
    position.right = '-111px';
  }

  if (side === 'west') {
    position.left = '-111px';
    position.top = subposition == "Top" ? "20%" : "80%";
  }

  return position;
};

const getArrowPosition = (side) => {
  if (side == "west") {
    return { flexDirection: "row-reverse" };
  }
  else if (side == "east") {
    return { flexDirection: "row" };
  } else if (side == "north") {
    return { flexDirection: "column-reverse", alignItems: "center" };
  } else if (side == "south") {
    return { flexDirection: "column", alignItems: "center" };
  }
};


