import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TextField, Button, Grid, MenuItem } from "@mui/material";
import { Headings } from "../../Shared/Fonts/Fonts";
import { v4 as uuidv4 } from "uuid";

import {
  getDoor,
  updateDoor,
  addDoor,
  uploadDoorImage,
} from "../../Store/Features/Door";
import { getSites } from "../../Store/Features/Sites";
import { useDispatch, useSelector } from "react-redux";
import { getFloorsOfSite, getFloor } from "../../Store/Features/Floors";
import { IconButton } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { getUsers } from "../../Store/Features/Users";
import { Select } from "@material-ui/core";
import { getOrganization } from "../../Store/Features/Customer";
import {API_URL} from "../../Utils/Functions/utils";

const AddDoorPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.doors.loading);
  const sites = useSelector((state) => state.sites.value);
  const { value } = useSelector((state) => state.users);
  const [installationManagers, setInstallationManagers] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [site, setSite] = useState(null);
  const [floors, setFloors] = useState([]);
  const [orgName, setOrgName] = useState();
  const [doors, setDoors] = useState([]);
  const navigate = useNavigate();
  const { slug } = useParams();
  const isEdit = slug !== undefined;
  const [formData, setFormData] = useState({
    description: "",
    width: "",
    height: "",
    length: "",
    top_left_measurement: "",
    top_right_measurement: "",
    bottom_left_measurement: "",
    bottom_right_measurement: "",
    hinge_top_measurement: "",
    hinge_bottom_measurement: "",
    swing_top_measurement: "",
    swing_bottom_measurement: "",
    direction: "",
    floor: "",
    door_type: "",
    door_no: "",
    assignee: "",
  });
  useEffect(() => {
    dispatch(getUsers());
  }, []);
  useEffect(() => {
    if (value != undefined) {
      if (value.length > 0) {
        setInstallationManagers(() => {
          return value.filter((ele) => {
            return ele.roleId == process.env.REACT_APP_AGENT_ROLE_ID;
          });
        });
      }
    }
  }, [value]);
  const [errors, setErrors] = useState({});

  const handleImage = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    document.title = slug ? "Edit Door" : "Add Door";
  }, []);

  const validateForm = () => {
    const newErrors = {};
    let valid = true;

    const regexPattern = /hf\d{7}/i;
    // formData.floor.length == 0
    if (formData.description == null) {
      newErrors.description = "Description is required";
      valid = false;
    }

    if (!formData.description.trim().match(regexPattern)) {
      newErrors.description =
        "Please Enter Valid Harmony Code for Door,e.g. HFXXXXXXX";
      valid = false;
    }

    // if (!formData.width.trim()) {
    //   newErrors.name = 'Width is required';
    //   valid = false;
    // }
    // if (!formData.length.trim()) {
    //   newErrors.name = 'Name is required';
    //   valid = false;
    // }
    // if (!formData.height.trim()) {
    //   newErrors.name = 'Name is required';
    //   valid = false;
    // }
    // if (!formData.top_gap.trim()) {
    //   newErrors.name = 'Name is required';
    //   valid = false;
    // }
    // if (!formData.bottom_gap.trim()) {
    //   newErrors.name = 'Name is required';
    //   valid = false;
    // }
    // if (!formData.left_gap.trim()) {
    //   newErrors.name = 'Name is required';
    //   valid = false;
    // }
    // if (!formData.right_gap.trim()) {
    //   newErrors.name = 'Name is required';
    //   valid = false;
    // }
    // if (!formData.direction.trim()) {
    //   newErrors.name = 'Name is required';
    //   valid = false;
    // }
    if (formData.floor.length == 0) {
      newErrors.floor = "Name is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  useEffect(() => {
    // If it's an edit operation, you can fetch user data using 'slug' and set it in the form.
    if (isEdit) {
      dispatch(getDoor(slug)).then((res) => {
        if(!res.error) {
          setFormData({
            description: res.payload.description,
            width: res.payload.width,
            height: res.payload.height,
            top_left_measurement: res.payload.top_left_measurement,
            top_right_measurement: res.payload.top_right_measurement,
            bottom_left_measurement: res.payload.bottom_left_measurement,
            bottom_right_measurement: res.payload.bottom_right_measurement,
            hinge_top_measurement: res.payload.hinge_top_measurement,
            hinge_bottom_measurement: res.payload.hinge_bottom_measurement,
            swing_top_measurement: res.payload.swing_top_measurement,
            swing_bottom_measurement: res.payload.swing_bottom_measurement,
            direction: res.payload.direction,
            floor: res.payload.floorId,
            assignee: res.payload.assigneeId,
            top_left_offset: res.payload.top_left_offset,
            top_right_offset: res.payload.top_right_offset,
            bot_left_offset: res.payload.bot_left_offset,
            bot_right_offset: res.payload.bot_right_offset,
            hinge_top_offset: res.payload.hinge_top_offset,
            hinge_bot_offset: res.payload.hinge_bot_offset,
            swing_top_offset: res.payload.swing_top_offset,
            swing_bot_offset: res.payload.swing_bot_offset,
  
          });
          dispatch(getFloor(res.payload.floor?.id + "?for_door=1")).then((res) => {
            setSite(res.payload?.site?.id);
  
            if (res.payload?.site?.organizationId) {
              dispatch(getOrganization(res.payload?.site?.organizationId)).then(
                (res) => {
                  setOrgName(res.payload?.name);
                }
              );
            }
          });
        }
        
      });
    }
  }, [isEdit, slug]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    if (isEdit) {
      dispatch(updateDoor({ id: slug, data: formData })).then((res) => {
        if (!res.error) {
          navigate("/doors");
        }
      });
    } else {
      dispatch(addDoor({ data: formData })).then((res) => {
        
        if (!res.error) {
          navigate("/doors");
        }
      });
    }
  };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };


  useEffect(() => {
    dispatch(getSites());
  }, []);
  useEffect(() => {
    if (site) {
      dispatch(getFloorsOfSite(site + "&for_door=1")).then((res) => {
        setFloors(res.payload.data);
      });
    }
  }, [site]);

  return (
    <div className="OverAllSites">
      <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
        <IconButton onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon />
        </IconButton>
        <Headings fontSize={24}>{isEdit ? "Edit Door" : "Add Door"}</Headings>
      </div>
      <div className="Addsitetabs">
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Harmony Code"
                variant="filled"
                fullWidth
                name="description"
                value={formData.description}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                error={!!errors.description}
                helperText={errors.description}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Width"
                variant="filled"
                fullWidth
                name="width"
                value={formData.width}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                error={!!errors.width}
                helperText={errors.width}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Height"
                variant="filled"
                fullWidth
                name="height"
                value={formData.height}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                error={!!errors.height}
                helperText={errors.height}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Door Type"
                variant="filled"
                fullWidth
                name="door_type"
                value={formData.door_type}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Door Number"
                variant="filled"
                fullWidth
                name="door_no"
                value={formData.door_no}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Top Left Gap"
                variant="filled"
                fullWidth
                InputLabelProps={{ shrink: true }}
                name="top_left_measurement"
                value={formData.top_left_measurement}
                onChange={handleChange}
                error={!!errors.top_left_measurement}
                helperText={errors.top_left_measurement}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Top Right Gap"
                variant="filled"
                fullWidth
                name="top_right_measurement"
                value={formData.top_right_measurement}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                error={!!errors.top_right_measurement}
                helperText={errors.top_right_measurement}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Bottom Left Gap"
                variant="filled"
                fullWidth
                name="bottom_left_measurement"
                value={formData.bottom_left_measurement}
                onChange={handleChange}
                error={!!errors.bottom_left_measurement}
                InputLabelProps={{ shrink: true }}
                helperText={errors.bottom_left_measurement}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Bottom Right Gap"
                variant="filled"
                fullWidth
                name="bottom_right_measurement"
                value={formData.bottom_right_measurement}
                onChange={handleChange}
                error={!!errors.bottom_right_measurement}
                helperText={errors.bottom_right_measurement}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Hinge Top Gap"
                variant="filled"
                fullWidth
                InputLabelProps={{ shrink: true }}
                name="hinge_top_measurement"
                value={formData.hinge_top_measurement}
                onChange={handleChange}
                error={!!errors.hinge_top_measurement}
                helperText={errors.hinge_top_measurement}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Hinge Bottom Gap"
                variant="filled"
                fullWidth
                name="hinge_bottom_measurement"
                value={formData.hinge_bottom_measurement}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                error={!!errors.hinge_bottom_measurement}
                helperText={errors.hinge_bottom_measurement}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Swing Top Gap"
                variant="filled"
                fullWidth
                name="swing_top_measurement"
                value={formData.swing_top_measurement}
                onChange={handleChange}
                error={!!errors.swing_top_measurement}
                InputLabelProps={{ shrink: true }}
                helperText={errors.swing_top_measurement}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Swing Bottom Gap"
                variant="filled"
                fullWidth
                name="swing_bottom_measurement"
                value={formData.swing_bottom_measurement}
                onChange={handleChange}
                error={!!errors.swing_bottom_measurement}
                helperText={errors.swing_bottom_measurement}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
             <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Top Left Gap"
                variant="filled"
                fullWidth
                InputLabelProps={{ shrink: true }}
                name="top_left_measurement"
                value={formData.top_left_measurement}
                onChange={handleChange}
                error={!!errors.top_left_measurement}
                helperText={errors.top_left_measurement}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Top Right Gap"
                variant="filled"
                fullWidth
                name="top_right_measurement"
                value={formData.top_right_measurement}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                error={!!errors.top_right_measurement}
                helperText={errors.top_right_measurement}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Bottom Left Gap"
                variant="filled"
                fullWidth
                name="bottom_left_measurement"
                value={formData.bottom_left_measurement}
                onChange={handleChange}
                error={!!errors.bottom_left_measurement}
                InputLabelProps={{ shrink: true }}
                helperText={errors.bottom_left_measurement}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Bottom Right Gap"
                variant="filled"
                fullWidth
                name="bottom_right_measurement"
                value={formData.bottom_right_measurement}
                onChange={handleChange}
                error={!!errors.bottom_right_measurement}
                helperText={errors.bottom_right_measurement}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Hinge Top Gap"
                variant="filled"
                fullWidth
                InputLabelProps={{ shrink: true }}
                name="hinge_top_measurement"
                value={formData.hinge_top_measurement}
                onChange={handleChange}
                error={!!errors.hinge_top_measurement}
                helperText={errors.hinge_top_measurement}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Hinge Bottom Gap"
                variant="filled"
                fullWidth
                name="hinge_bottom_measurement"
                value={formData.hinge_bottom_measurement}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                error={!!errors.hinge_bottom_measurement}
                helperText={errors.hinge_bottom_measurement}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Swing Top Gap"
                variant="filled"
                fullWidth
                name="swing_top_measurement"
                value={formData.swing_top_measurement}
                onChange={handleChange}
                error={!!errors.swing_top_measurement}
                InputLabelProps={{ shrink: true }}
                helperText={errors.swing_top_measurement}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Swing Bottom Gap"
                variant="filled"
                fullWidth
                name="swing_bottom_measurement"
                value={formData.swing_bottom_measurement}
                onChange={handleChange}
                error={!!errors.swing_bottom_measurement}
                helperText={errors.swing_bottom_measurement}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Top Left Offset"
                variant="filled"
                fullWidth
                disabled={user?.roleId != process.env.REACT_APP_ADMIN_ROLE_ID}
                InputLabelProps={{ shrink: true }}
                name="top_left_offset"
                value={formData.top_left_offset}
                onChange={handleChange}
                error={!!errors.top_left_offset}
                helperText={errors.top_left_offset}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Top Right Offset"
                variant="filled"
                disabled={user?.roleId != process.env.REACT_APP_ADMIN_ROLE_ID}
                fullWidth
                name="top_right_offset"
                value={formData.bot_left_offset}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                error={!!errors.top_right_offset}
                helperText={errors.top_right_offset}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Bottom Left Offset"
                variant="filled"
                disabled={user?.roleId != process.env.REACT_APP_ADMIN_ROLE_ID}
                fullWidth
                name="bottom_left_offset"
                value={formData.bot_left_offset}
                onChange={handleChange}
                error={!!errors.bot_left_offset}
                InputLabelProps={{ shrink: true }}
                helperText={errors.bot_left_offset}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Bottom Right offset"
                variant="filled"
                disabled={user?.roleId != process.env.REACT_APP_ADMIN_ROLE_ID}
                fullWidth
                name="bot_right_offset"
                value={formData.bot_right_offset}
                onChange={handleChange}
                error={!!errors.bot_right_offset}
                helperText={errors.bot_right_offset}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Hinge Top Offset"
                variant="filled"
                fullWidth
                InputLabelProps={{ shrink: true }}
                disabled={user?.roleId != process.env.REACT_APP_ADMIN_ROLE_ID}
                name="hinge_top_offset"
                value={formData.hinge_top_offset}
                onChange={handleChange}
                error={!!errors.hinge_top_offset}
                helperText={errors.hinge_top_offset}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Hinge Bottom Offset"
                variant="filled"
                fullWidth
                disabled={user?.roleId != process.env.REACT_APP_ADMIN_ROLE_ID}
                name="hinge_bot_offset"
                value={formData.hinge_bot_offset}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                error={!!errors.hinge_bot_offset}
                helperText={errors.hinge_bot_offset}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Swing Top offset"
                variant="filled"
                fullWidth
                disabled={user?.roleId != process.env.REACT_APP_ADMIN_ROLE_ID}
                name="swing_top_offset"
                value={formData.swing_top_offset}
                onChange={handleChange}
                error={!!errors.swing_top_offset}
                InputLabelProps={{ shrink: true }}
                helperText={errors.swing_top_offset}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Swing Bottom offset"
                variant="filled"
                fullWidth
                disabled={user?.roleId != process.env.REACT_APP_ADMIN_ROLE_ID}
                name="swing_bot_offset"
                value={formData.swing_bot_offset}
                onChange={handleChange}
                error={!!errors.swing_bot_offset}
                helperText={errors.swing_bot_offset}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            {/* <Grid item xs={6}>
            <TextField
              label="Email"
              variant="filled"
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Phone Number"
              variant="filled"
              fullWidth
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
            />
          </Grid>*/}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                select
                label="Direction"
                variant="filled"
                fullWidth
                name="direction"
                value={formData.direction}
                onChange={handleChange}
                error={!!errors.direction}
                helperText={errors.direction}
                InputLabelProps={{ shrink: true }}
              >
                <MenuItem value={"CW"}>Clock Wise</MenuItem>
                <MenuItem value={"CCW"}>Counter Clock Wise</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                select
                label="Assign To"
                variant="filled"
                fullWidth
                name="assignee"
                value={formData.assignee}
                onChange={handleChange}
                error={!!errors.assignee}
                helperText={errors.assignee}
              >
                {installationManagers?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.firstName + " " + (option.lastName || "")}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                select
                label="Site"
                variant="filled"
                fullWidth
                name="site"
                value={site}
                onChange={(e) => setSite(e.target.value)}
                error={!!errors.buildingType}
                InputLabelProps={{ shrink: true }}
                helperText={orgName ? `Site of organization ${orgName}` : ``}
              >
                {sites?.map((option) => (
                  <MenuItem key={option.data.id} value={option.data.id}>
                    {option.data.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                select
                label="Floor"
                variant="filled"
                fullWidth
                sx={{ display: site ? null : "none" }}
                name="floor"
                value={formData.floor}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                error={!!errors.floor}
                helperText={errors.floor}
              >
                {floors?.map((option) => (
                  <MenuItem key={option.data.id} value={option.data.id}>
                    {option.data.description}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <ImageUploadButton
                onChange={handleImage}
                name={"avatar"}
                path={"doors/door_image"}
                form={formData}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                className="customButtomOverAll"
                disabled={loading}
                type="submit"
                variant="contained"
                color="primary"
              >
                {loading ? (
                  <CircularProgress color="inherit" />
                ) : isEdit ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default AddDoorPage;

const ImageUploadButton = ({ onChange, name, path, form }) => {
  const dispatch = useDispatch();
  const { imageLoading } = useSelector((state) => state.doors);

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    const extension = extractExtensionFrom(selectedFile.name);
    const id = uuidv4();
    const filename = `${id}.${extension}`;
    const privateUrl = `${path}/${filename}`;

    const publicUrl = API_URL()+`file/download?privateUrl=${privateUrl}`;
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("filename", filename);

    dispatch(uploadDoorImage(formData)).then((res) => {
      onChange("door_image", {
        id: id,
        name: selectedFile.name,
        sizeInBytes: selectedFile.size,
        privateUrl,
        publicUrl,
        new: true,
      });
    });
  };

  // const handleUpload = () => {
  //   // You can implement the image upload logic here
  //   if (selectedImage) {
  //     // Example: Send the selectedImage to your backend for processing
  //     console.log('Uploading image:', selectedImage.name);
  //   }
  // };

  return (
    <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="image-upload-input"
        type="file"
        onChange={handleImageChange}
      />
      <label htmlFor="image-upload-input">
        <Button
          className="customButtomOverAll"
          variant="contained"
          component="span"
        >
          {imageLoading ? <CircularProgress /> : "Upload Image"}
        </Button>
      </label>
      <img
        src={form.door_image?.publicUrl}
        style={{
          display: form.door_image?.publicUrl ? null : "none",
          width: 100,
          height: 100,
        }}
      />
    </div>
  );
};

function extractExtensionFrom(filename) {
  if (!filename) {
    return null;
  }

  const regex = /(?:\.([^.]+))?$/;
  return regex.exec(filename)[1];
}
