import React from 'react';
import "./Fonts.css";


export const Headings = (props) => {
  const { fontSize, children, color, onClick, fontWeight, textTransform, fontFamily, lineHeight, textAlign } = props;
  return (
    <p style={{ fontSize: fontSize, fontWeight: fontWeight ? fontWeight : 500, fontFamily: fontFamily ? fontFamily : "Futura PT", color: color || "black", textTransform: textTransform || "none", lineHeight: lineHeight ? lineHeight : null, textAlign: textAlign || null }} className="mr-initials" onClick={onClick ? onClick : null}>{children}</p>
  );
};


export const SubHeadings = (props) => {
  const { fontSize, children, color, onClick, fontWeight, fontFamily, lineHeight } = props;
  return (
    <p style={{ fontSize: fontSize, fontWeight: fontWeight || 450, color: color && color, fontFamily: fontFamily || "Futura PT", lineHeight: lineHeight ? lineHeight : null }} className="mr-initials" onClick={onClick ? onClick : null}>{children}</p>
  );
};

export const Text = (props) => {
  const { fontSize, children, onClick, color, fontfamily, lineHeight } = props;
  return (
    <p style={{ fontSize: fontSize, fontFamily: fontfamily ? fontfamily : "Futura PT", color: color || null, lineHeight: lineHeight ? lineHeight : null, fontWeight: 400 }} className="mr-initials" onClick={onClick ? onClick : null}>{children}</p>
  );
};