import React, { useState, useEffect } from "react";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import "./Forms.css";
import { getOrganizations } from "../../Store/Features/Customer";
import { getSitesOfOrganization } from "../../Store/Features/Sites";
import { getFloorsOfSite } from "../../Store/Features/Floors";
import { getDoorsofFloor } from "../../Store/Features/Door";
import {getOrgDevice} from "../../Store/Features/Sensors"
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import {

  getCodes,
 
  deleteCode,
} from "../../Store/Features/device_codes"
import FormControl from "@mui/material/FormControl";

const qrCodeRegex = /^\d{3}-\d{4}-\d{8}$/;
const nfcRegex = /^[A-F0-9]{2}:[A-F0-9]{2}:[A-F0-9]{2}:[A-F0-9]{2}$/i;

export const GetSite = ({ form, setForm, data,setCheck }) => {
  const dispatch = useDispatch();

  const { value,loading } = useSelector((state) => state.customers);
  const loadingSite = useSelector((state) =>state.sites.loading)
  const loadingFloor = useSelector((state) => state.floors.loading)
  const loadingDoor = useSelector((state) => state.doors.loading)
  const loadingSensors = useSelector((state) => state.sensors.loading)
  const [sites, setSites] = useState([]);
  const [floors, setFloors] = useState([]);
  const [doors, setDoors] = useState([]);
  const [sensors,setSensors] = useState([]);
  const codes = useSelector((state) => state.codes.value)
  
  useEffect(() => {
    dispatch(getOrganizations());
  }, []);

  useEffect(()=>{
    dispatch(getCodes());
    
  },[])

  useEffect(() => {
    console.log("codes",codes)
  },[codes])

  useEffect(() => {
    if (form.org) {
      dispatch(getSitesOfOrganization(form.org)).then((res) => {
       
        setSites(res.payload.data);
      });
    }
  }, [form.org]);

  useEffect(() => {
    if (form.site) {
      dispatch(getFloorsOfSite(form.site)).then((res) => {
       
        setFloors(res.payload.data);
      });
    }
  }, [form.site]);

  useEffect(() => {
    if (form.floor) {
      dispatch(getDoorsofFloor(form.floor)).then((res) => {
    
        setDoors(res.payload.data);
      });
    }
  }, [form.floor]);

  useEffect(()=> {
    if(form.door.length > 0) {
      dispatch(getOrgDevice("hf-01")).then(response => {
        setSensors(response.payload.end_devices)
      })
    }
  },[form.door])

  const checkRegistered = () => {
    const sensor = form.sensor_code
  
    // console.log("sensor",sensor?.lowerCase())
    let check = codes.some((ele) =>ele.device_id.toLowerCase() == sensor.toLowerCase())
    // console.log(check,sensor)
    setCheck(check)
  }

  useEffect(()=>{
    if(form.sensor_code) {

      checkRegistered()
    }
    // console.log("aaa")
 
  },[form.sensor_code])


  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="SensorConnection">
      <div className="Content">
        <div className="SensorInfoExtra">
          <div style = {{display:"flex",gap: 10}} >
            <FormControl>
            <InputLabel id="organization-label">Organization</InputLabel>
              <Select
                  labelId="organization-label"
                  label="Organization"
                  InputLabelProps={{ shrink: true }}
                  variant="filled"
                  value={form.org?.length > 0 ? form.org : ''}
                  onChange={handleChange}
                  name="org"
                  sx={{width: 200}}
                  placeholder={"Select an organization"}
              >
                {value?.map((o) => (
                  <MenuItem key={o.id} value={o.id}>
                    {o.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          {loading ? <CircularProgress />: null}
          </div>
          <div style = {{gap: 10,display:( form.org?.length > 0) ? "flex" : "none"}}>
            <FormControl>
              <InputLabel id="site-label">Site</InputLabel>
              <Select labelId="site-label"
                      label="Site"
                      variant="filled"
                      value={form.site}
                      onChange={handleChange}
                      name = "site"
                      sx = {{width: 200}}
                      placeholder="Select an site"
              >
                {sites?.map((o) => (
                  <MenuItem key={o.data.id} value={o.data.id}>
                    {o.data.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          {loadingSite ? <CircularProgress />: null}
          </div>
          <div style = {{gap: 10,display:( form.org?.length > 0) && ( form.site?.length > 0)? "flex" : "none"}}>
            <FormControl>
              <InputLabel id="floor-label">Floor</InputLabel>
              <Select
                  labelId="floor-label"
                  label="Floor"
                  variant="filled"
                  value={form.floor}
                  onChange={handleChange}
                  name = "floor"
                  sx = {{width: 200}}
                  placeholder="Select an floor"
              >
                {floors?.map((o) => (
                  <MenuItem key={o.data.id} value={o.data.id}>
                    {o.data.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          {loadingFloor ? <CircularProgress />: null}
          </div>
          <div style = {{gap: 10,display:(form.org?.length > 0) && (form.site?.length > 0) && ((form.floor?.length > 0))? "flex" : "none"}}>
            <FormControl>
              <InputLabel id="door-label">Door</InputLabel>
              <Select
                  labelId="door-label"
                  label="Door"
                  variant="filled"
                  value={form.door}
                  onChange={handleChange}
                  name = "door"
                  sx = {{width: 200}}
                  placeholder="Select an door"
              >
                {doors?.map((o) => (
                  <MenuItem key={o.data.id} value={o.data.id}>
                    {o.data.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          {loadingDoor ? <CircularProgress />: null}
          </div>
          <div style = {{gap: 10,display:(form.org?.length > 0) && (form.site?.length > 0) && ((form.floor?.length > 0)) && (form.door.length > 0) ? "flex" : "none"}}>
            <FormControl>
              <InputLabel id="device-label">Device</InputLabel>
              <Select
                  labelId="device-label"
                  label="Device"
                  variant="filled"
                  value={form.sensor_code}
                  onChange={handleChange}
                  name = "sensor_code"
                  sx = {{width: 200}}
                  placeholder="Select an Device"
              >
                {sensors?.map((sensor) => (
                  <MenuItem key={sensor.ids.device_id} value={sensor.ids.device_id}>
                    {sensor.ids.device_id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          {loadingSensors ? <CircularProgress />: null}
          </div>
         
        </div>
      </div>
    </div>
  );
};
