import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./SidePanels.css";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import { useDispatch } from "react-redux";
import {
  getSite,
  getSensorsOfSite,
  getDoorsOfSite,
} from "../../Store/Features/Sites";
import { useParams } from "react-router-dom";

export default function AssetDrawer(props) {
  const [site, setSite] = useState();
  const dispatch = useDispatch();
  const { siteId } = useParams();
  const { open, setOpen, asset } = props;
  const [sensors, setSensors] = useState([]);
  const [doors, setDoors] = useState([]);

  useEffect(() => {
    dispatch(getSite(siteId)).then((res) => {
      setSite(res.payload);
    });
  }, []);

  // useEffect(() => {
  //   dispatch(getSensorsOfSite(siteId)).then((res) => {
  //     setSensors(res.payload);
  //   });
  // }, []);
  // useEffect(() => {
  //   console.log("siteId", siteId);
  //   dispatch(getDoorsOfSite(siteId)).then((res) => {
  //     console.log("res.payload", res.payload);
  //     setDoors(res.payload);
  //   });
  // }, []);

  return (
    <Box className="floorInfoDrawer" sx={{ heigth: 670, backgroundColor: "white", borderRadius: "14px" }}>
      <Box className="marker-layout">
        <Box className="heading-marker">
          <Headings fontSize={18}>
            {site?.name || ""}
          </Headings>
        </Box>
        <Divider />

        <div className="fx-space">
          <SubHeadings
            lineHeight={"36px"}
            fontSize={16}
            color={"black"}
            fontWeight={500}
          >
            Building Type
          </SubHeadings>
          <SubHeadings
            lineHeight={"36px"}
            fontFamily={"Futura PT Book"}
            fontSize={16}
            color={"#212B36"}
            fontWeight={400}
          >
            {site?.sector}
          </SubHeadings>
        </div>
        <div className="fx-space">
          <SubHeadings
            lineHeight={"36px"}
            fontSize={16}
            color={"black"}
            fontWeight={500}
          >
            No. of doors
          </SubHeadings>
          <SubHeadings
            lineHeight={"36px"}
            fontFamily={"Futura PT Book"}
            fontSize={16}
            color={"#212B36"}
            fontWeight={400}
          >
            {site?.doorsCount}
          </SubHeadings>
        </div>

        <div className="fx-space">
          <SubHeadings
            lineHeight={"36px"}
            fontSize={16}
            color={"black"}
            fontWeight={500}
          >
            No. of sensors
          </SubHeadings>
          <SubHeadings
            lineHeight={"36px"}
            fontFamily={"Futura PT Book"}
            fontSize={16}
            color={"#212B36"}
            fontWeight={400}
          >
            {site?.sensorsCount}
          </SubHeadings>
        </div>
        <div className="fx-space">
          <SubHeadings
            lineHeight={"36px"}
            fontSize={16}
            color={"black"}
            fontWeight={500}
          >
            Address
          </SubHeadings>
          <div style={{ width: "40%" }}>
            <SubHeadings
              lineHeight={"36px"}
              fontFamily={"Futura PT Book"}
              fontSize={16}
              color={"#212B36"}
              fontWeight={400}
            >
              {site?.address}
            </SubHeadings>
          </div>
        </div>
      </Box>
    </Box>
  );
}
