import React from "react";
import "./Layout.css";
import { Outlet } from "react-router-dom";
import { Headings } from "../Fonts/Fonts";
import { useNavigate } from "react-router-dom";
// import ThreeGlobe from 'three-globe';
// import globe from "../../Assets/globe_3d.png"
import * as THREE from "three";
import Globe from "../CustomElement/Globe"

export const AuthLayout = () => {
  const navigate = useNavigate();
  

// const myScene = new THREE.Scene();
// myScene.add(myGlobe);
  return (
    <>
      <div className="background">
        {/* <iframe
          autoplay={1}
          width="420"
          height="315"
          src="https://www.youtube.com/embed/WYeFqSjvGaY?autoplay=1&controls=0&showinfo=0&autohide=1"
        ></iframe> */}
        <div className="login-home">
          
          <Outlet />
          <Globe />
        </div>
        <div className="footer-pages">
          <div className="footer-links">
            <a
              style={{ textDecoration: "none" }}
              target="_blank"
              href="https://harmonyfire.com/about-us/"
            >
              <Headings color={"#637381"}>About</Headings>
            </a>
            <span style={{ color: "#637381" }}>|</span>
            <a
              target="_blank"
              style={{
                textDecoration: "none",
                "&:focus": {
                  color: "black",
                },
              }}
              href="https://harmonyfire.com/contact/"
            >
              {" "}
              <Headings color={"#637381"}>Contact</Headings>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
