import * as React from 'react';
import { useState } from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Divider } from '@mui/material';
import "./SidePanels.css";
import { Headings, SubHeadings, Text } from '../../Shared/Fonts/Fonts';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import { getStatusMessages } from "../../Utils/Functions/getStatusMessages";

export default function NotificationDrawer(props) {

  const { open, setOpen } = props;
  const [notifications, setNotifications] = useState([
    {
      status: "404",
      date: "March 14, 2023",
      time: "8:21pm",
      siteId: "000d214f-e3d2-4bf3-9d45-467d3ec69767",
      floorId: "c0f57ff4-20c1-45f2-9690-67ea968a06b5",
      doorId: "2b5eb6ba-c15b-4ff3-adaf-50afe82d3320",
      name: "Nottingham Res 14",
    },
    {
      status: "303",

      date: "March 15, 2023",
      time: "8:22pm",
      siteId: "000d214f-e3d2-4bf3-9d45-467d3ec69767",
      floorId: "c0f57ff4-20c1-45f2-9690-67ea968a06b5",
      doorId: "2b5eb6ba-c15b-4ff3-adaf-50afe82d3320",
      name: "Nottingham Res 15",
    },
  ]);

  const list = (anchor) => (
    <Box
      sx={{ width: "auto", padding: "10px" }}
      role="presentation"
      className="NotificationDrawer"
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <NotificationsNoneOutlinedIcon sx={{ color: "#105998" }} />
          <Headings color={"#212B36"} fontSize={16}>
            Notification
          </Headings>
        </div>
        <IconButton onClick={() => setOpen(false)}><CloseOutlinedIcon sx={{ fontSize: 16 }} /></IconButton>

      </div>


      <div
        style={{ display: "flex", flexDirection: "column", overflowY: "auto", gap: 10 }}
      >
        {notifications?.map((notification) => (
          <Notification {...notification} />
        ))}
      </div>
    </Box >
  );

  return (
    <Drawer
      anchor="right"
      open={open}
      hideBackdrop={true}
      sx={{ width: "327px", height: "100%", zIndex: 999999, padding: "20px" }}
      className="notification-container"
    >
      {list()}
    </Drawer>
  );
}


const Notification = (props) => {
  const { status, message, name, date, time, siteId, floorId, doorId } = props;

  return (
    <div
      className="notification-single"
      style={{
        borderLeft: `2px solid ${getStatus(status).color}`,
      }}

    >
      <div className="notification-single-upper">
        <div className="notification-single-upper-first">
          <Headings>{name}</Headings>
          <WarningAmberIcon
            sx={{ color: getStatus(status).color, fontSize: 16 }}
          />
        </div>
        <div style={{ display: "flex", width: "45%" }}>
          <Headings textAlign={"start"} color={getStatus(status).color}>{getStatusMessages(status)}</Headings>
        </div>
      </div>
      <div className="notification-single-down">
        <SubHeadings
          fontFamily={"Futura PT Book"}
          color="#919EAB"
          fontSize={"14px"}
        >
          {date}
        </SubHeadings>
        <SubHeadings
          fontFamily={"Futura PT Book"}
          color="#919EAB"
          fontSize={"14px"}
        >
          {time}
        </SubHeadings>
      </div>
    </div>
  );
};


const getStatus = (stat) => {
  let status = parseInt(stat);
  if (status >= 200 && status < 300) {
    return {
      color: "#34A853",
      background: "rgba(52, 168, 83, 0.1)",
    };
  } else if (status >= 300 && status < 400) {
    return {
      color: "rgba(221, 173, 1, 1)",
      background: "rgba(221, 173, 1, 0.1)",
    };
  } else if (status >= 400 && status < 500) {
    return {
      color: "#EB4335",
    };
  } else {
    return {
      color: "#34A853",
      background: "rgba(52, 168, 83, 0.1)",
    };
  }
};
