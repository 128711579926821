import * as React from 'react';
import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Headings, SubHeadings } from '../../Shared/Fonts/Fonts';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import collapseUp from "../../Assets/DropDownUp.svg";
import collapseDown from "../../Assets/DropDownDown.svg";

import {
  getSite,
  getSensorsOfSite,
  getDoorsOfSite,
} from "../../Store/Features/Sites";

const drawerBleeding = 80;

const Root = styled('div')(({ theme }) => ({
  height: '100%',

  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],

}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',

}));

function SwipeableEdgeDrawer(props) {
  const { window, } = props;
  const [site, setSite] = useState({});
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { siteId } = useParams();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const matches = useMediaQuery("(max-width:769px)");
  useEffect(() => {
    dispatch(getSite(siteId)).then((res) => {
      setSite(res.payload);
    });
  }, []);

  // This is used only for the example
  const container = window !== undefined ? () => window().document.body : undefined;

  const clickedHeader = () => {
      console.log("header clicked");
  }

  return (
    <Root >
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: 'visible',
            bottom: "20px",
            width: "95%",
            right: "2.5%",
            left: "2.5%"

          },
        }}
        className="swiper"
      />

      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        className="swiper"
        sx={{
          "& .MuiSwipeableDrawer-paper": {
            width: "95%"
          }
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -80,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            borderBottomLeftRadius: open ? 0 : 20,
            borderBottomRightRadius: open ? 0 : 20,

            visibility: 'visible',
            right: 0,
            left: 0,
            display: matches ? null : "none",
            pointerEvents: 'all',
          }}
          onClick={toggleDrawer(!open)}
        >

          <div
              style={{ height: "80px", width: "100%", padding: "20px", boxSizing: "border-box", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Headings >{site?.name}</Headings>
            <img src={open ? collapseDown : collapseUp} />
          </div>

        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,

            height: '100%',
            overflow: 'auto',
            right: 10,
            left: 10,
          }}
        >
          <div className="fx-space">
            <SubHeadings
              lineHeight={"36px"}
              fontSize={16}
              color={"black"}
              fontWeight={500}
            >
              Building Type
            </SubHeadings>
            <SubHeadings
              lineHeight={"36px"}
              fontFamily={"Futura PT Book"}
              fontSize={16}
              color={"#212B36"}
              fontWeight={400}
            >
              {site?.sector}
            </SubHeadings>
          </div>
          <div className="fx-space">
            <SubHeadings
              lineHeight={"36px"}
              fontSize={16}
              color={"black"}
              fontWeight={500}
            >
              No. of doors
            </SubHeadings>
            <SubHeadings
              lineHeight={"36px"}
              fontFamily={"Futura PT Book"}
              fontSize={16}
              color={"#212B36"}
              fontWeight={400}
            >
              {site?.doorsCount}
            </SubHeadings>
          </div>

          <div className="fx-space">
            <SubHeadings
              lineHeight={"36px"}
              fontSize={16}
              color={"black"}
              fontWeight={500}
            >
              No. of sensors
            </SubHeadings>
            <SubHeadings
              lineHeight={"36px"}
              fontFamily={"Futura PT Book"}
              fontSize={16}
              color={"#212B36"}
              fontWeight={400}
            >
              {site?.sensorsCount}
            </SubHeadings>
          </div>
          <div className="fx-space">
            <SubHeadings
              lineHeight={"36px"}
              fontSize={16}
              color={"black"}
              fontWeight={500}
            >
              Address
            </SubHeadings>
            <div style={{ width: "40%" }}>
              <SubHeadings
                lineHeight={"36px"}
                fontFamily={"Futura PT Book"}
                fontSize={16}
                color={"#212B36"}
                fontWeight={400}
              >
                {site?.address}
              </SubHeadings>
            </div>
          </div>
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;