import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import { format } from "date-fns";
ChartJS.register(
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
);

const DynamicLineChartWeek = ({ data, chartTitle, xLabel, yLabel }) => {
  const labels = data?.map((entry) => entry.createdAt.split("T")[0]);
  const doorOpenCounts = data?.map((entry) => entry?.doorOpenCount);
  const reversLebels = labels?.reverse();
  const reversCounts = doorOpenCounts?.reverse();

  const formattedLabels = reversLebels?.map((date) => {
    return format(new Date(date), "MM/dd");
  });



  const chartData = {
    labels: formattedLabels,
    datasets: [
      {
        label: "Door Utilisation",
        data: reversCounts,
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        fill: false,
        pointBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,

        spanGaps: true,
      },
    ],
  };
  

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        title: {
          display: false,
          text: 'Chart Title'  
        }
      },
    scales: {
      x: [
        {
          type: "time",
          time: {
            unit: "day",
            displayFormats: {
              month: "EEE", // Format for month display
            },
            min: labels[0], // Set the min date to the first date in your data

            tooltipFormat: "YYYY-MM-DD",
          },
          title: {
            display: true,
            text: "Time",
          },

          type: "category",
          ticks: {
            autoSkip: true,
          },
        },
      ],
      y: [
        {
          title: {
            display: true,
            text: "Door Open",
          },
        },
      ],

      
    },
  };
  

  return <Line data={chartData} options={chartOptions} />;
};

export default DynamicLineChartWeek;
