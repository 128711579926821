import * as React from "react";
import { forwardRef, useImperativeHandle, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { TextField, Label } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import Edit from "../../Assets/Edit.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { getNotesOfDoor, addNote,deleteNote,getNote,updateNote } from "../../Store/Features/Notes";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Headings } from "../../Shared/Fonts/Fonts";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import {

  Menu,
  MenuItem,
  IconButton,





} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NotesModal = (props) => {
  const [openAdd, setOpenAdd] = React.useState(false);
  const { user } = useSelector((state) => state.auth);
  const { assetId } = useParams();
  const [id, setId] = useState();
  const dispatch = useDispatch();
  const { sensors, open, setOpen } = props;
  const [note, setNote] = React.useState({
    AddedBy: user?.id,
    description: "",
    door : assetId
  });
  const { loading, value } = useSelector((state) => state.notes);

  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    setId(e.id);
  };

  useEffect(() => {
    if (open) {
      dispatch(getNotesOfDoor(assetId));
    }
  }, [open]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          border: "none",
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Notes for Door
            </Typography>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#105998" }}
              onClick={() => setOpenAdd(true)}
            >
              Add Note
            </Button>
          </Box>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <CustomDataGrid setOpenAdd ={setOpenAdd} setId = {setId} />
          </Typography>
          <CustomModal
            floorData={note}
            isOpen={openAdd}
            setIsOpen={setOpenAdd}
            setFloorData={setNote}
            id = {id}
            setId= {setId}
          />
        </Box>
      </Modal>
    </>
  );
};

export default NotesModal;

function CustomDataGrid({setOpenAdd,isOpen,setId}) {
  const { loading, value } = useSelector((state) => state.notes);
  const {assetId} = useParams()
  const dispatch = useDispatch()
 const onDelete = (id)=> {
  dispatch(deleteNote(id)).then((res) => {
    if(!res.error) {
      dispatch(getNotesOfDoor(assetId))
    }
  })
 }
  const columns = [
    {
      field: "AddedBy",
      headerName: "Added By",
      flex: 1,
      renderCell: (params) => {
        return <Headings>{params.row.AddedBy?.firstName}</Headings>;
      },
    },
    { field: "description", headerName: "Note", flex: 1 },
    {
      field: "door",
      headerName: "Door",
      flex: 1,
      renderCell: (params) => {
        return <Headings>{params.row.door?.description}</Headings>;
      },
    },
    {
      field: "actions",
      headerName: "",
      flex: 0.3,
      sortable: false,
      renderCell: (params) => (
        <ActionsDropdown
          roleId={params.row.id}
          onDelete={onDelete}
          isOpen={isOpen}
          setIsOpen={setOpenAdd}
          setId={setId}
        />
      ),
    },
    // Add more columns as needed
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={value}
        columns={columns}
        pageSize={5} // Adjust as needed
        loading={loading}
      />
    </div>
  );
}

const CustomModal = ({
  floorData,
  setFloorData,
  handleImageUpload,
  isOpen,
  setIsOpen,
  id,
  setId
}) => {
  const dispatch = useDispatch();
  const { assetId } = useParams();
  const edit = id ? true : false;
  const handleSubmit = () => {
    if(edit) {
      dispatch(updateNote({id: id,data: floorData})).then((res) => {
        dispatch(getNotesOfDoor(assetId));
        setId(null)
        setFloorData((prevData) => ({ ...prevData, description: "" }));
        handleClose();
      });
      
    }else {
      dispatch(addNote(floorData)).then((res) => {
        dispatch(getNotesOfDoor(assetId));
        setId(null)
        setFloorData((prevData) => ({ ...prevData, description: "" }));
        handleClose();
      });
    }
    
  };

  useEffect(()=> {
    if(id && isOpen) {
      dispatch(getNote(id)).then(res => setFloorData(prev => ({...prev,description: res.payload.description})))
    }
  },[id,isOpen])

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Update the floorData state based on the field name
    // Assuming the keys in floorData match the field names
    setFloorData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <Modal open={isOpen} onClose={handleClose} className="custom-modal">
      <div
        className="modal-content"
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Headings>Note</Headings>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#105998" }}
            onClick={handleSubmit}
          >
            {edit ? "Update Note" :"Add Note"}
          </Button>
        </Box>

        <TextareaAutosize
          label="Note"
          name="description"
          minRows={10}
          value={floorData.description}
          onChange={handleChange}
          fullWidth
          style={{ width: 400 }}
        />
      </div>
    </Modal>
  );
};


const ActionsDropdown = ({
  roleId,
  onDelete,
  isOpen,
  setIsOpen,
  device_id,
  setSensor,
  setId
}) => {


  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    onDelete(roleId);
    handleClose();
  };
  const openModal = () => {
    setId(roleId)
    setIsOpen(true);
  };

  return (
    <div>
      <IconButton variant="outlined" onClick={handleClick}>
        <img src={Edit} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon fontSize="small" />
          Delete
        </MenuItem>
        <MenuItem onClick={() => openModal()}>
          <EditIcon fontSize="small" />
          Edit
        </MenuItem>
      
      </Menu>
    </div>
  );
};