import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Door.css";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import { useNavigate } from "react-router-dom";
import { Table } from "../../Components/Table/Tables";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button, Menu, MenuItem, IconButton } from "@mui/material";
import Edit from "../../Assets/Edit.svg";

import {

  getCodes,
 
  deleteCode,
} from "../../Store/Features/device_codes"
import { useDispatch, useSelector } from "react-redux";

import search from "../../Assets/search.svg";


const RegisteredDevices = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.codes);
  const { user } = useSelector((state) => state.auth);

  const codes = useSelector((state) => state.codes.value)
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [doorId, setDoorId] = useState();
  const matches = useMediaQuery("(max-width:769px)");

 

  // Function to handle changes in the search input
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter the sites array based on the search query
  const filterCode = (codes.length > 0) && codes.filter((door) => {
    // Iterate through each field in the site object
    let each = false;
    for (const key in door) {
      if (door.hasOwnProperty(key)) {
       
        // address sector data status
        // description height width length top_gap bottom_gap left_gap right_gap direction createdById
        if (key == "qr_code" || key == "nfc_code" || key == "device_id") {
          // .noOfSensors
          const value = door[key] + "";
     
          // Check if the field value contains the search query (case-insensitive)
          if (
            value &&
            value.toString().toLowerCase().match(searchQuery.toLowerCase())
          ) {
            each = true; // Return true if a match is found
          }
        } else if (!searchQuery) {
          const value = door[key] + "";
         
          // Check if the field value contains the search query (case-insensitive)
          if (
            value &&
            value.toString().toLowerCase().match(searchQuery.toLowerCase())
          ) {
            each = true; // Return true if a match is found
          }
        }
      }
    }
    return each;
  });
  useEffect(() => {
    document.title = "Doors";
  }, []);
  const handleDeleteRole = (id) => {
    dispatch(deleteCode(id)).then(() => {
      dispatch(getCodes());
    });
  };

  const columns = [
    // {
    //   field: "floor", headerName: "Floor", flex: 1, renderCell: (params) => (
    //     <p>{params.row.floor.description}</p>
    //   )
    // },
    {
      field: "qr_code",
      headerName: "Qr",
      flex: 1,
   
     
    },

    {
      field: "nfc_code",
      headerName: "NFC",
      flex: 1,
  
    
    },

    {
      field: "device_id",
      headerName: "Device EUI",
      flex: 1,
      
   
    },
  
    // {field: "status", headerName: "doorPi", flex: 1 },
    // {
    //   field: "createdById",
    //   headerName: "Commissioned By",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <Headings
    //         fontSize={16}
    //         fontWeight={200}
    //         textTranformation="capitalize"
    //       >
    //         {params.row.data.createdById}
    //       </Headings>
    //     );
    //   },
    // },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <ActionsDropdown
            roleId={params.row.id}
            onDelete={handleDeleteRole}
            
          />
        );
      },
    },
  ];

  const mobileColumns = [
    {
      field: "qr_code",
      headerName: "QR",
      flex: 1,
      sortable: true,
    //   renderCell: (params) => {
    //     return (
    //       <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
    //         <Headings
    //           fontSize={16}
    //           color={"#212B36"}
    //           fontFamily="Futura PT"
    //           fontWeight={400}
    //           textTranformation="capitalize"
    //         >
    //           {params.row.data.description}
    //         </Headings>
         
    //       </div>

    //     );
    //   },

    //   sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    },
    {
      field: "nfc_code",
      headerName: "NFC",
      flex: 1,
    //   renderCell: (params) => {
    //     const { color, background } = getStatus(params.row.status);
    //     console.log(color, background);
    //     return (
    //       <div style={{ display: "flex", flexDirection: "column", gap: 10, padding: "10px" }}>
    //         <div
    //           style={{
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //             // minWidth: "74px",
    //             height: "25px",
    //             backgroundColor: background,
    //             padding: "2px 20px",
    //             boxSizing: "border-box"
    //           }}
    //         >
    //           <Headings
    //             fontFamily="Futura PT"
    //             color={color}
    //             fontSize={16}
    //             fontWeight={200}
    //             textTranformation="capitalize"
    //           >
    //             {getStatusMessage(params.row.status)}
    //           </Headings>
    //         </div>
    //         <img
    //           style={{
    //             width: 50,
    //             height: 50,
    //             border: "1px solid transparent",
    //             display: params.row.data?.door_image[0]?.publicUrl
    //               ? null
    //               : "none",
    //           }}
    //           src={params.row.data?.door_image[0]?.publicUrl}
    //         />
    //       </div>
    //     );
    //   },
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
        field: "actions",
        headerName: "",
        sortable: false,

        flex: 0.5,
        renderCell: (params) => (
          <ActionsDropdown
            roleId={params.row.data.id}
            onDelete={handleDeleteRole}
          
         
          />
        ),
      }

  ];

  useEffect(() => {
    dispatch(getCodes());
  }, []);


  return (
    <div className="OverAllSites">
      <div className="searchspace">
        <div className="Heading">
          <Headings fontSize={24}>Device Code Management</Headings>
            <button
              className="addSiteButton customButtomOverAll"
              onClick={() => navigate("/registeredDevices/register")}
            >
              <Headings>Register Device</Headings>
            </button>
      
        </div>
        <div className="inputdiv">
          <img src={search} alt="No search" className="searchimage" />
          <input
            className="searchinput"
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div style={{ height: 400, width: "100%" }}>
        <Table
          rows={filterCode}
          columns={matches ? mobileColumns : columns}
          
          rowSelection={false}
          loading={loading}
          
          matches={matches}
        />
      </div>
      
    </div>
  );
};

export default RegisteredDevices;

const ActionsDropdown = ({ roleId, onDelete, setOpen, setDoorId }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const openModal = (event) => {
    setDoorId(roleId);
    setOpen(true);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    onDelete(roleId);
    handleClose();
  };

  return (
    <div>
      <IconButton variant="outlined" onClick={handleClick}>
        <img src={Edit} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon fontSize="small" />
          Delete
        </MenuItem>
        <MenuItem onClick={() => navigate(`/registeredDevices/${roleId}`)}>
          <EditIcon fontSize="small" />
          Edit
        </MenuItem>
      
      </Menu>
    </div>
  );
};

const getStatus = (stat) => {
  let status = parseInt(stat);
  if (status >= 200 && status < 300) {
    return {
      color: "#34A853",
      background: "rgba(52, 168, 83, 0.1)",
    };
  } else if (status >= 300 && status < 400) {
    return {
      color: "rgba(221, 173, 1, 1)",
      background: "rgba(221, 173, 1, 0.1)",
    };
  } else if (status >= 400 && status < 500) {
    return {
      color: "rgba(235, 67, 53, 1)",
      background: "rgba(235, 67, 53, 0.1)",
    };
  } else {
    return {
      color: "#34A853",
      background: "rgba(52, 168, 83, 0.1)",
    };
  }
};


const getDirection = (direction) => {
  switch (direction) {
    case "1":
      return "Clock Wise";
    case "2":
      return "Counter Clock Wise";
    default:
      return "Clock Wise";
  }
};