import { createSlice } from "@reduxjs/toolkit";
import {
  postApi,
  getApi,
  deleteApi,
  putApi,
  uploadService,
} from "../../Utils/serviceWorker";
import { createAsyncThunk } from "@reduxjs/toolkit";
import jsCookie from "js-cookie";
import { toast } from "react-toastify";

export const getSites = createAsyncThunk(
  "sites/get",
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`sites?${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getSite = createAsyncThunk(
  "user/get",
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`sites/${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addSite = createAsyncThunk(
  "site/add",
  async (credentials, thunkAPI) => {
    try {
      const response = await postApi(`sites`, true, credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateSite = createAsyncThunk(
  "site/update",
  async (credentials, thunkAPI) => {
    try {
      const response = await putApi(
        `sites/${credentials.id}`,
        true,
        credentials
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteSite = createAsyncThunk(
  "Site/User",
  async (credentials, thunkAPI) => {
    try {
      const response = await deleteApi(`sites/${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getSensorsOfSite = createAsyncThunk(
  "Site/Sensor",
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(
        `sites/sensoragainstsiteid/${credentials}`,
        true
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getDoorsOfSite = createAsyncThunk(
  "Site/Door",
  async (credentials, thunkAPI) => {
    try {
      // console.log("credentials", credentials);
      const response = await getApi(
        `sites/doorsagainstsiteid/${credentials}`,
        true
      );

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getSitesOfOrganization = createAsyncThunk(
  "Site/User",
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`sites?organization=${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getSitesOfInstalltion = createAsyncThunk(
  "Site/Installation",
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`sites?assignee=${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const initialState =  {
  value: [],
  Doors: [],
  Sensors: [],
  loading: false,
  error: null,
}

export const Sites = createSlice({
  name: "Sites",
  initialState,
  reducers: {
    getSitesOfUser: (state, action) => {
      let temp = [...state.value];
      temp = temp.filter((s) => s.data.createdById === action.payload);
      state.value = temp;
    },
    reset: () => initialState
  },
  
  extraReducers: (builder) => {
    builder.addCase(getSites.pending, (state) => {
   
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSites.fulfilled, (state, action) => {
      state.loading = false;
      let temp = [];

      state.value = action.payload.data;
    });
    builder.addCase(getSites.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getSitesOfInstalltion.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSitesOfInstalltion.fulfilled, (state, action) => {
      state.loading = false;
      let temp = [];

      state.value = action.payload.data;
    });
    builder.addCase(getSitesOfInstalltion.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(updateSite.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateSite.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Updated Site Successfully");
    });
    builder.addCase(updateSite.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(addSite.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addSite.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Created Site Successfully");
    });
    builder.addCase(addSite.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getDoorsOfSite.fulfilled, (state, action) => {
      let temp = [...state.value];
      const index = temp.findIndex((ele) => ele.data.id == action.meta.arg);
      if (index >= 0) {
        temp[index].data.noOfDoors = action.payload.length;
      }

      state.value = temp;
    });
    builder.addCase(getSitesOfOrganization.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSitesOfOrganization.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload.data;
    });
    builder.addCase(getSitesOfOrganization.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getSensorsOfSite.fulfilled, (state, action) => {
      let temp = [...state.value];
      const index = temp.findIndex((ele) => ele.data.id == action.meta.arg);
      if (index >= 0) {
        temp[index].noOfSensors = action.payload.length;
      }

      state.value = temp;
    });
  },
});

export const { getSitesOfUser } = Sites.actions;
