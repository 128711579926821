import { createSlice } from "@reduxjs/toolkit";
import { postApi, getApi, deleteApi, putApi } from "../../Utils/serviceWorker";
import { createAsyncThunk } from "@reduxjs/toolkit";
import jsCookie from "js-cookie";
import { toast } from "react-toastify";

export const getSensors = createAsyncThunk(
  "sensors/get",
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi("sensors", true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getFloorOdSensor = createAsyncThunk(
  "floor/get/sensor",
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`floors/${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getSensor = createAsyncThunk(
  "sensor/get",
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`sensors/${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addSensor = createAsyncThunk(
  "sensor/add",
  async (credentials, thunkAPI) => {
    try {
      const response = await postApi(`sensors`, true, credentials);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateSensor = createAsyncThunk(
  "sensor/update",
  async (credentials, thunkAPI) => {
    try {
      const response = await putApi(
        `sensors/${credentials.id}`,
        true,
        credentials
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteSensor = createAsyncThunk(
  "sensor/delete",
  async (credentials, thunkAPI) => {
    try {
      const response = await deleteApi(`sensors/${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getSensorsbyDoor = createAsyncThunk(
  "sensors/door",
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(
        `sensors?door=${credentials}&field=createdAt&sort=desc`,
        true
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getSensorOfOrg = createAsyncThunk(
  "sensors/org",
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(
        `sites/sensoragainstorganization/${credentials}`,
        true
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getSensorsbyCode = createAsyncThunk(
  "sensors/Code",
  async (credentials, thunkAPI) => {

    const query = credentials.startsWith("hf") || credentials.startsWith("HF")
    if(query) {
      try {
        const response = await getApi(`doors?description=${credentials}`, true);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }else {
      try {
        const response = await getApi(`sensors?sensor_code=${credentials}`, true);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }

  }
);

export const scheduleDownlinkMessage = createAsyncThunk(
  "sensors/downlink",
  async (credentials, thunkAPI) => {
    try {
      const response = await postApi(
        `sensors/schedule_downlink`,
        true,
        credentials
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getOrgDevice = createAsyncThunk(
  "sensors/org/ttn",
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(
        `sensors/getapplicationdevices/${credentials}`,
        true
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getDeviceCommission = createAsyncThunk(
  "sensors/commission",
  async (credentials, thunkAPI) => {
    try {
      const response = await postApi(
        `sensors/getCommissioned`,
        true,
        {device_id:credentials}
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  value: [],
  loadingOnline:false,
  loading: false,
  error: null,
}


export const Sensors = createSlice({
  name: "sensor",
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getSensors.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSensors.fulfilled, (state, action) => {
      state.loading = false;

      state.value = action.payload.rows;
    });
    builder.addCase(getSensors.rejected, (state, action) => {
      state.loading = false;

      state.error = action.payload;
    });
    builder.addCase(updateSensor.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateSensor.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Updated Sensor Successfully");
    });
    builder.addCase(updateSensor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(addSensor.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addSensor.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Created Sensor Successfully");
    });
    builder.addCase(addSensor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getFloorOdSensor.pending, (state) => {
      state.error = null;
    });
    builder.addCase(getFloorOdSensor.fulfilled, (state, action) => {
      let temp = [...state.value];

      let indexes = [];
      temp?.map((ele, index) =>
        ele.door?.floorId == action.meta.arg ? indexes.push(index) : null
      );
  
      indexes?.map((ele) => (temp[ele].floor = action.payload));

      state.value = temp;
    });
    builder.addCase(getFloorOdSensor.rejected, (state, action) => {
      state.error = action.payload;
    });

    builder.addCase(getSensorOfOrg.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getSensorOfOrg.fulfilled, (state, action) => {
      state.loading = false;

      state.value = action.payload.rows;
    });
    builder.addCase(getSensorOfOrg.rejected, (state, action) => {
      state.loading = false;

      state.error = action.payload;
    });
    builder.addCase(getOrgDevice.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getOrgDevice.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getOrgDevice.rejected, (state, action) => {
      state.loading = false;

      state.error = action.payload;
    });
    builder.addCase(scheduleDownlinkMessage.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(scheduleDownlinkMessage.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Sent Schedule");
    });
    builder.addCase(scheduleDownlinkMessage.rejected, (state, action) => {
      state.loading = false;

      state.error = action.payload;
    });
    builder.addCase(getDeviceCommission.pending, (state) => {
      state.loadingOnline = true;
      state.error = null;
    });
    builder.addCase(getDeviceCommission.fulfilled, (state, action) => {
      state.loadingOnline = false;
      
    });
    builder.addCase(getDeviceCommission.rejected, (state, action) => {
      state.loadingOnline = false;

      state.error = action.payload;
    });
  },
});
