import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Door.css";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import { useNavigate } from "react-router-dom";
import { Table } from "../../Components/Table/Tables";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Button, Menu, MenuItem, IconButton, debounce} from "@mui/material";
import Edit from "../../Assets/Edit.svg";
import {
  getDoors,
  deleteDoor,
  getDoorsOfInstalltion,
  getDoorsOfOrg,
} from "../../Store/Features/Door";
import { useDispatch, useSelector } from "react-redux";
import { getStatusMessage } from "../../Utils/Functions/getStatusMessages";
import { getFloor } from "../../Store/Features/Floors";
import search from "../../Assets/search.svg";
import CustomModal from "../../Components/Modal/DoorModal";
import Inspection from "../../Components/Modal/Inspection";

const Doors = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.doors);
  const { user } = useSelector((state) => state.auth);
  const doors = useSelector((state) => state.doors.value);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [doorId, setDoorId] = useState();
  const matches = useMediaQuery("(max-width:769px)");
  const [open, setOpen] = useState(false);
  const [openInspection, setOpenIspection] = useState(false);
  const [error, setError] = useState();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  });

  // Function to handle changes in the search input
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleRowClick = (e) => {
    if (user?.roleId == process.env.REACT_APP_AGENT_ROLE_ID) {
      navigate(
        `/sites/${e.row.data.floor?.siteId}/floor/${e.row.data?.floorId}/asset/${e.row.data?.id}/sensor/addSensor`
      );
    } else {
      navigate(
        `/sites/${e.row.data.floor?.siteId}/floor/${e.row.data?.floorId}/asset/${e.row.data?.id}`
      );
    }
  };
  // Filter the sites array based on the search query
  const filteredDoors = doors.filter((door) => {
    // Iterate through each field in the site object
    let each = false;
    if (
      user?.roleId != process.env.REACT_APP_AGENT_ROLE_ID ||
      door.commission_flag == 0
    ) {
      for (const key in door.data) {
        if (door.data.hasOwnProperty(key)) {
          // address sector data status
          // description height width length top_gap bottom_gap left_gap right_gap direction createdById
          if (key == "description" || key == "direction") {
            // .noOfSensors
            const value = door.data[key] + "";

            // Check if the field value contains the search query (case-insensitive)
            if (
              value &&
              value.toString().toLowerCase().match(searchQuery.toLowerCase())
            ) {
              each = true; // Return true if a match is found
            }
          } else if (!searchQuery) {
            const value = door.data[key] + "";

            // Check if the field value contains the search query (case-insensitive)
            if (
              value &&
              value.toString().toLowerCase().match(searchQuery.toLowerCase())
            ) {
              each = true; // Return true if a match is found
            }
          }
        }
      }
      return each;
    }
  });
  useEffect(() => {
    document.title = "Doors";
  }, []);
  const handleDeleteRole = (id) => {
    dispatch(deleteDoor(id)).then(() => {
      dispatch(getDoors());
    });
  };

  const columns = [
    // {
    //   field: "floor", headerName: "Floor", flex: 1, renderCell: (params) => (
    //     <p>{params.row.floor.description}</p>
    //   )
    // },
    {
      field: "data",
      headerName: "Harmony Code",
      flex: 1,
      sortComparator: (v1, v2) => v1.description.localeCompare(v2),
      renderCell: (params) => {
        return (
          <Headings
            fontSize={16}
            fontFamily="Futura PT Book"
            fontWeight={200}
            textTranformation="capitalize"
          >
            {params.row.data?.description}
          </Headings>
        );
      },
    },

    {
      field: "organization",
      headerName: "Organization",
      flex: 1,

      renderCell: (params) => {
        return (
          <Headings
            fontSize={16}
            fontFamily="Futura PT Book"
            fontWeight={200}
            textTranformation="capitalize"
          >
            {params.row?.organization}
          </Headings>
        );
      },
    },
    {
      field: "site",
      headerName: "Site",
      flex: 1,

      renderCell: (params) => {
        return (
          <Headings
            fontSize={16}
            fontFamily="Futura PT Book"
            fontWeight={200}
            textTranformation="capitalize"
          >
            {params.row?.site}
          </Headings>
        );
      },
    },
    {
      field: "floor",
      headerName: "Floor",
      flex: 1,

      renderCell: (params) => {
        return (
          <Headings
            fontSize={16}
            fontFamily="Futura PT Book"
            fontWeight={200}
            textTranformation="capitalize"
          >
            {params.row?.floor}
          </Headings>
        );
      },
    },

    {
      field: "door_type",
      headerName: "Door Type",
      flex: 1,

      renderCell: (params) => {
        return (
          <Headings
            fontSize={16}
            fontFamily="Futura PT Book"
            fontWeight={200}
            textTranformation="capitalize"
          >
            {params.row.data?.door_type}
          </Headings>
        );
      },
    },

    // {
    //   field: "direction",
    //   headerName: "Direction",
    //   flex: 1,
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <Headings
    //         fontSize={16}
    //         fontWeight={200}
    //         fontFamily="Futura PT Book"
    //         textTranformation="capitalize"
    //       >
    //         {getDirection(params.row.data?.direction)}
    //       </Headings>
    //     );
    //   },
    // },
    {
      field: "status",
      headerName: "Door Status",
      flex: 1.5,
      renderCell: (params) => {
        const { color, background } = getStatus(params.row.status);

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "74px",
              height: "25px",
              backgroundColor: background,
              padding: "2px 20px",
            }}
          >
            <Headings
              fontSize={16}
              color={color}
              fontFamily={"Futura PT Book"}
              fontWeight={400}
            >
              {getStatusMessage(params.row.status)}
            </Headings>
          </div>
        );
      },
    },

    // {field: "status", headerName: "doorPi", flex: 1 },
    // {
    //   field: "createdById",
    //   headerName: "Commissioned By",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <Headings
    //         fontSize={16}
    //         fontWeight={200}
    //         textTranformation="capitalize"
    //       >
    //         {params.row.data.createdById}
    //       </Headings>
    //     );
    //   },
    // },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      renderCell: (params) => {
        return (
          <ActionsDropdown
            roleId={params.row.data.id}
            onDelete={handleDeleteRole}
            setOpen={setOpen}
            setDoorId={setDoorId}
            setOpenIspection={setOpenIspection}
          />
        );
      },
    },
  ];

  const mobileColumns = [
    {
      field: "data",
      headerName: "Door",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <Headings
              fontSize={16}
              color={"#212B36"}
              fontFamily="Futura PT"
              fontWeight={400}
              textTranformation="capitalize"
            >
              {params.row.data.description}
            </Headings>
            <Headings
              fontSize={16}
              color={"#212B36"}
              fontFamily="Futura PT Book"
              fontWeight={400}
              textTranformation="capitalize"
            >
              {getDirection(params.row.data?.direction)}
            </Headings>
          </div>
        );
      },

      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    },
    {
      field: "status",
      headerName: "Door Status",
      flex: 1,
      renderCell: (params) => {
        const { color, background } = getStatus(params.row.status);

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              padding: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // minWidth: "74px",
                height: "25px",
                backgroundColor: background,
                padding: "2px 20px",
                boxSizing: "border-box",
              }}
            >
              <Headings
                fontFamily="Futura PT"
                color={color}
                fontSize={16}
                fontWeight={200}
                textTranformation="capitalize"
              >
                {getStatusMessage(params.row.status)}
              </Headings>
            </div>
            {/* NOTE: Door image is not being set of coming from store*/}
            {/*<img*/}
            {/*  style={{*/}
            {/*    width: 50,*/}
            {/*    height: 50,*/}
            {/*    border: "1px solid transparent",*/}
            {/*    display: params.row.data?.door_image[0]?.publicUrl*/}
            {/*      ? null*/}
            {/*      : "none",*/}
            {/*  }}*/}
            {/*  src={params.row.data?.door_image[0]?.publicUrl}*/}
            {/*/>*/}
          </div>
        );
      },
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,

      flex: 0.5,
      renderCell: (params) => (
        <ActionsDropdown
          roleId={params.row.data.id}
          onDelete={handleDeleteRole}
          setOpen={setOpen}
          setDoorId={setDoorId}
          setOpenIspection={setOpenIspection}
        />
      ),
    },
  ];

  useEffect(() => {
    if (user?.id) {
        dispatch(getDoors());
    }
  }, [user, paginationModel]);

  return (
    <div className="OverAllSites">
      <div className="searchspace">
        <div className="Heading">
          <Headings fontSize={24}>Doors</Headings>
          {/* {user?.roleId == process.env.REACT_APP_AGENT_ROLE_ID ? null : (
            <button
              className="addSiteButton customButtomOverAll"
              onClick={() => navigate("/doors/add")}
            >
              <Headings>Add Doors</Headings>
            </button>
          )} */}
        </div>
        <div className="inputdiv">
          <img src={search} alt="No search" className="searchimage" />
          <input
            className="searchinput"
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div style={{ height: 400, width: "100%" }}>
        <Table
          rows={filteredDoors}
          columns={matches ? mobileColumns : columns}
          unique_identifier={"id"}
          rowSelection={false}
          loading={loading}
          handleRowClick={handleRowClick}
          matches={matches}
          columnVisibilityModel={{
            actions:
              user.roles.id == process.env.REACT_APP_ADMIN_ROLE_ID
                ? true
                : false,
          }}
        />
      </div>
      <CustomModal
        isOpen={open}
        setIsOpen={setOpen}
        id={doorId}
        error={error}
      />
      <Inspection
        isOpen={openInspection}
        setIsOpen={setOpenIspection}
        id={doorId}
        error={error}
      />
    </div>
  );
};

export default Doors;

const ActionsDropdown = ({
  roleId,
  onDelete,
  setOpen,
  setDoorId,
  setOpenIspection,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const openModal = (event) => {
    setDoorId(roleId);
    setOpen(true);
  };

  const openInspection = (event) => {
    setDoorId(roleId);
    setOpenIspection(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    onDelete(roleId);
    handleClose();
  };

  return (
    <div>
      <IconButton variant="outlined" onClick={handleClick}>
        <img src={Edit} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon fontSize="small" />
          Delete
        </MenuItem>
        <MenuItem onClick={() => navigate(`/doors/${roleId}`)}>
          <EditIcon fontSize="small" />
          Edit
        </MenuItem>
        <MenuItem onClick={openModal}>
          <VisibilityIcon fontSize="small" />
          View Assets
        </MenuItem>
        <MenuItem onClick={openInspection}>
          <VisibilityIcon fontSize="small" />
          Add Inspection
        </MenuItem>
      </Menu>
    </div>
  );
};

const getStatus = (stat) => {
  let status = parseInt(stat);
  if (status >= 200 && status < 300) {
    return {
      color: "#34A853",
      background: "rgba(52, 168, 83, 0.1)",
    };
  } else if (status >= 300 && status < 400) {
    return {
      color: "rgba(221, 173, 1, 1)",
      background: "rgba(221, 173, 1, 0.1)",
    };
  } else if (status >= 400 && status < 500) {
    return {
      color: "rgba(235, 67, 53, 1)",
      background: "rgba(235, 67, 53, 0.1)",
    };
  } else {
    return {
      color: "#FFA500",
      background: "rgba(254, 216, 177, 0.1)",
    };
  }
};

const getDirection = (direction) => {
  switch (direction) {
    case "1":
      return "Clock Wise";
    case "2":
      return "Counter Clock Wise";
    default:
      return "Clock Wise";
  }
};
