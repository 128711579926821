import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import { format } from "date-fns";
ChartJS.register(
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
);

const DynamicLineChartMagnetometer = ({ data, chartTitle, xLabel, yLabel }) => {
  const labels = data?.map((entry) => entry.createdAt.split("T")[0]);
  const XData = data?.map((entry) => entry?.xmm);
  const YData = data?.map((entry) => entry?.ymm);
  const ZData = data?.map((entry) => entry?.zmm);
  const reversLebels = labels?.reverse();
  const reversX = XData?.reverse();
  const reverseY = YData?.reverse();
  const reverseZ = ZData?.reverse();
  const formattedLabels = reversLebels?.map((date) => {
    return format(new Date(date), "MM/dd");
  });

  const chartData = {
    labels: formattedLabels,
    datasets: [
      {
        label: "X MM",
        data: reversX,
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        fill: false,
        pointBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,

        spanGaps: true,
      },
      {
        label: "Y MM",
        data: reverseY,
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        fill: false,
        pointBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,

        spanGaps: true,
      },
      {
        label: "Z MM",
        data: reverseZ,
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        fill: false,
        pointBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,

        spanGaps: true,
      },
    ],
  };
  

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        title: {
          display: false,
          text: 'Chart Title'  
        }
      },
    scales: {
      x: [
        {
          type: "time",
          time: {
            unit: "month",
            displayFormats: {
              month: "MMM", // Format for month display
            },
            min: labels[0], // Set the min date to the first date in your data

            tooltipFormat: "YYYY-MM-DD",
          },
          title: {
            display: true,
            text: "Time",
          },

          type: "category",
          ticks: {
            autoSkip: true,
          
            minRotation: 0,
            fontSize: 6,
            fontFamily: 'Futura Pt' 

          },
        },
      ],
      y: [
        {
          title: {
            display: true,
            text: "Door Open",
          },
        },
      ],

      
    },
  };

  return <Line data={chartData} options={chartOptions} />;
};

export default DynamicLineChartMagnetometer;
