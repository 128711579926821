import React, { useState, useEffect } from "react";
import { Headings, SubHeadings, Text } from '../../Shared/Fonts/Fonts';
import "./Forms.css";
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Spinner from "../../Assets/Spinner.png";
import { ImageLoader } from "../../Shared/CustomElement/CustomLoader";
import {getDeviceCommission} from "../../Store/Features/Sensors"
import { useDispatch,useSelector } from "react-redux";
import { toast } from "react-toastify";

export const SensorReceive = ({ form, setForm, check,setCheck,setActiveStep }) => {
  const {loadingOnline}  = useSelector(state => state.sensors)
  const dispatch = useDispatch();
  const [count, setCount] = useState(60);

    
  
    useEffect(() => {
      const startTime = Date.now();
      let timeout;

      const interval = setInterval(() => {
        const timeLeft = Math.max(0, 60 - (Date.now() - startTime) / 1000);
        setCount(Math.round(timeLeft));
  
        if(timeLeft === 0) {
          clearInterval(interval); 
        }
      }, 1000);
      const poll = () => {
        dispatch(getDeviceCommission(form.sensor_code))
          .then(res => {
            if (res.payload) {
             
              setCheck(res.payload);
              return; 
            }
           
            
            if (Date.now() - startTime > 60*1000) {
              toast.error("Did not recieve any success commission from device.");
              setActiveStep(4)
              return; 
            }
  
            timeout = setTimeout(poll, 10*1000); 
          });
      };
       timeout = poll();
      return () => clearTimeout(timeout);
    }, []);
  return (
    <div className="SensorConnection" style={{ justifyContent: "flex-start" }}>
      <div className="Content" style={{ width: "100%", flexDirection: 'column' }}>
        <div className="SensorInfo" style={{ gap: 20, alignSelf: "center", alignItems: "center" }} >
          <Headings >Network waiting for Data</Headings>
          <ImageLoader />
          <Headings >{count}s</Headings>
        </div>
      </div>

    </div >

  );
};


