import React from 'react';
import jsCookie from 'js-cookie';
import { Navigate, useLocation } from "react-router-dom";




const ProtectedRoute = ({ children }) => {

  const token = jsCookie.get('token');

  let location = useLocation().pathname;

  
  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;

};

export default ProtectedRoute;


