import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import AddressForm from "../../Components/Forms/AddressForm";
import { Headings } from "../../Shared/Fonts/Fonts";
import { useParams } from "react-router-dom";
import "./AddSite.css";
import {
  getSites,
  getSite,
  addSite,
  updateSite,
  deleteSite,

} from "../../Store/Features/Sites";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useGeocode from "react-geocode";

import { IconButton } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { getOrganizations } from "../../Store/Features/Customer";

export default function AddSiteFormWizard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { value } = useSelector((state) => state.customers);
  const { siteId } = useParams();
  const { loading } = useSelector((state) => state.sites);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    sector: '',
    solution: '',
    organization: user.organizationId || null,
    latitude: '',
    longitude: '',
    addedBy: user?.id,
  });

  useEffect(() => {
    if (siteId) {
      dispatch(getSite(siteId)).then((res) =>
        setFormData({
          name: res.payload.name,
          address: res.payload.address,
          organization: res.payload.organization?.id, // Set the email field accordingly
          latitude: res.payload.latitude, // Set the phoneNumber field accordingly
          longitude: res.payload.longitude,
          sector: res.payload.sector,
          solution: res.payload.solution,
          addedBy: res.payload.addedById,
          addedBy: user?.id, // Set the additionalDetails field accordingly
        })
      );
    }
  }, []);
  useEffect(() => {
    document.title = siteId ? "Edit Sites" : "Add Sites";
  }, []);

  const handleNext = () => {
    if (siteId) {
      dispatch(updateSite({ id: siteId, data: formData })).then(() => {
        navigate("/sites");
      });
    } else {
      dispatch(addSite({ data: formData })).then(() => {
        navigate("/sites");
      });
    }
  };

  useEffect(() => {
    dispatch(getOrganizations());
  }, []);

  return (
    <Box sx={{ width: "100%", padding: "30px", boxSizing: "border-box" }}>
      <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
        <IconButton onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon />
        </IconButton>
        <Headings fontSize={24}>{siteId ? "Edit Site" : "Add Site"}</Headings>
      </div>
      <div className="Addsitetabs">
        <AddressForm
          formData={formData}
          setFormData={setFormData}
          handleNext={handleNext}
          siteId={siteId}
          loading={loading}
          organization={value}
        />
      </div>
    </Box>
  );
}
