import React from 'react';
import { Select as MuiSelect, MenuItem } from '@mui/material';
import { Headings } from '../Fonts/Fonts';
import "./Custom.css";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function Select({ options, onChange, value, title, key, placeholder, area }) {
  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;
    if (onChange) {
      
      onChange(selectedOption);
    }
  };


  return (
    <MuiSelect
      value={value}
      onChange={handleOptionChange}
      sx={{
        border: 'none', bgcolor: 'transparent', '&:focus': { bgcolor: 'transparent' }, "&: .MuiInputBase-root > .MuiOutlinedInput-notchedOutline": {
          border: "none"
        },
        display: "flex",

        gap: "10px",
        fontFamily: "Futura PT",
        width: "250px"
      }}
      displayEmpty
      // inputProps={{ 'aria-label': 'Select' }}
      placeholder={title}
      // IconComponent={() => <KeyboardArrowDownIcon sx={{ fontSize: "20px" }} />}
    >
      <MenuItem key={0} value={0}>
        <Headings textTransform={"capitalize"} fontSize={16} fontWeight={400} fontFamily={"Futura PT Book"} >{placeholder}</Headings>
      </MenuItem>
      {options?.map((option) => (
        <MenuItem key={option.data.id} value={option.data.id}>
          <Headings fontSize={16} fontWeight={400} fontFamily={"Futura PT Book"} > {area == "sites" ? option.data.name : area == "floors" ? option.data.description : area == "doors" ? option.data.description
            : null} </Headings>
        </MenuItem>
      ))}
    </MuiSelect>
  );
}

export default Select;