import React from "react";
import "./Door.css";
import DoorBackOutlinedIcon from "@mui/icons-material/DoorBackOutlined";
import DoorFrontOutlinedIcon from "@mui/icons-material/DoorFrontOutlined";
import { Headings } from "../../Shared/Fonts/Fonts";
import {getStatusMessage} from "../../Utils/Functions/getStatusMessages"
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';

export const DoorCardTimeOut = (props) => {
    const {
        status,
        last_inspection,
        due_inspection,
        week_Utilisation,
        lifetime_Utilisation,
        report_interval,
        open_interval,
        description,
        direction,
    doorType,
      } = props;
  
  console.log(status,
    last_inspection,
    due_inspection,
    week_Utilisation,
    lifetime_Utilisation,
    report_interval,
    description,
    doorType,
    open_interval,)
  return (
    <div className="doorCard">
      <div className="doorCardUpper">
        <WatchLaterOutlinedIcon sx = {{color: "white"}} />
        
      </div>
      <div className="doorCardDown">
        <div className="doorCardDownUpTime">
          <Headings color = {"white"} fontSize={24} fontWeight={450}>
          Open Timeout
          </Headings>
          <Headings color = {"white"} fontSize={14} fontWeight={450}>
          When a door has been open longer than this time, an alert will be triggered.
          </Headings>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "74px",
            height: "25px",
            border:"1px solid white",
            backgroundColor: "transparent",
            padding: "2px 20px",
            borderRadius: "4px",
          }}
        >
          <Headings
            fontFamily="Futura PT"
            color={"white"}
            fontSize={16}
            fontWeight={200}
            textTranformation="capitalize"
          >
            {open_interval} Minutes
          </Headings>
        </div>
      </div>
    </div>
  );
};


