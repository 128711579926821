import React, { useState, useRef } from "react";
import "./SidePanels.css";
import { Headings, SubHeadings } from "../../Shared/Fonts/Fonts";
import { Divider, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getFloor } from "../../Store/Features/Floors";
import { getDoorsofFloor } from "../../Store/Features/Door";
import CanvasLoader from "../../Components/Canvas/Canvas";
import NotesModal from "../../Components/Modal/Notes";
export const AssetSidePanel = ({
  siteName,
  assetName,
  floorNumber,
  SitesLocation,
  image,
  sensors,
}) => {
  const [open, setOpen] = useState(false);
  const sidePanelAssetRef = useRef();
  return (
    <React.Fragment>
      
      <div className="sidePanelAsset" ref={sidePanelAssetRef}>
        <div className="UpperSection">
          <div className="names">
            <Headings fontSize={18}>{siteName || ""}</Headings>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <SubHeadings fontSize={16} color="#637381">
              {SitesLocation || ""}
            </SubHeadings>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <SubHeadings fontSize={16} color="#637381">
                Floor {floorNumber || ""}
              </SubHeadings>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "4px",
                  height: "4px",
                  borderRadius: "50%",
                  backgroundColor: "#637381",
                }}
              />
              <SubHeadings fontSize={16} color="#637381">
                {assetName || ""}
              </SubHeadings>
            </div>
          </div>
        </div>
        <Divider sx={{ width: "100%" }} />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            gap: 30,
          }}
        >
          
          <CanvasLoader parentRef={sidePanelAssetRef} />
       
       
          <img
            style={{
              width: "100%",
              height: "231.03px",
              border: "none",
              display: image ? null : "none",
            }}
            src={image}
          />
        </div>
        <div
          style={{
            width: "100%",
            height: 150,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            className="addSiteButton customButtomOverAll"
            onClick={() => setOpen(true)}
          >
            <Headings>Add Note</Headings>
          </button>
        </div>
      </div>
      <NotesModal open={open} setOpen={setOpen} sensors={sensors} />
    </React.Fragment>
  );
};
