import React, { useState, useEffect } from "react";

// import {useDispatch,useSelector} from "react-redux"
import { SiteDrawer } from "../../Components/Drawers/SiteDrawer";
import { Container } from "@mui/material";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import JsonFormatter from "react-json-formatter";
import { Table } from "../../Components/Table/Tables";
import { useNavigate } from "react-router-dom";
import "./Sites.css";
import { Button, Menu, MenuItem,Tooltip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import search from "../../Assets/search.svg";
import { getWebhooks, getSensorsbyCode } from "../../Store/Features/Webhook";
import { Box, Typography } from "@mui/material";
import { getStatusMessage,getStatusMessages } from "../../Utils/Functions/getStatusMessages";
import {
  getNotifications,
  getNotagainstorg,
  getAllNotifications,
  reset,
} from "../../Store/Features/notifications";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";

import sortIcon from "../../Assets/sortIcon.svg";

export function SortedDescendingIcon() {
  return <img src={sortIcon} className="icon" />;
}

export function SortedAscendingIcon() {
  return <img src={sortIcon} className="icon" />;
}

export function UnsortedIcon() {
  return <img src={sortIcon} className="icon" />;
}

export const Webhooks = () => {
  const { user } = useSelector(state => state.auth);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDevices, setFilteredDevices] = useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [sortModel, setSortModel] = useState([
    { field: 'createdAt', sort: 'desc'},
  ]);
  
  const { loading, count } = useSelector((state) => state.notifications);
  const events = useSelector((state) => state.notifications.value);
  
  const [json, setJson] = useState();
  const [open, setOpen] = useState(false);


  const dispatch = useDispatch();

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
 

  useEffect(() => {
    document.title = "Webhooks";
  }, []);

  useEffect(() => {

    
      if(sortModel && sortModel.sort) {
        if (user?.organizationId) {
          dispatch(
            getNotagainstorg({ page: paginationModel.page, limit: paginationModel.pageSize, orgId: user?.organizationId,sort: sortModel[0].sort,field: sortModel[0].field })
          );
        }else {
          dispatch(getAllNotifications({ page: paginationModel.page, limit: paginationModel.pageSize,sort: sortModel[0].sort,field: sortModel[0].field }));
        }
        
      }
       
        
      
  
      
  }, [sortModel,paginationModel]);



  

  const columns = [
    {
      field: "siteId",
      headerName: "Site",
      flex: 0.5,
      sortable: true,
      renderCell: (params) => {
        return (
          <div style={{ paddingLeft: 10 }}>
            {" "}
            <Headings
              fontSize={16}
              color={"#212B36"}
              fontFamily={"Futura PT Book"}
              fontWeight={400}
            >
              {params.row.site.name}
            </Headings>
          </div>
        );
      },
    },
    {
      field: "doorId",
      headerName: "door",
      flex: 0.5,
      sortable: true,
      renderCell: (params) => {
        return (
          <div style={{ paddingLeft: 10 }}>
            {" "}
            <Headings
              fontSize={16}
              color={"#212B36"}
              fontFamily={"Futura PT Book"}
              fontWeight={400}
            >
              {params.row.door.description}
            </Headings>
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Received At",
      flex: 0.5,
      sortable: true,
      renderCell: (params) => {
        return (
          <div style={{ paddingLeft: 10 }}>
            {" "}
            <Headings
              fontSize={16}
              color={"#212B36"}
              fontFamily={"Futura PT Book"}
              fontWeight={400}
            >
              {params.row.createdAt.split("T")[1].split(".")[0]} {params.row.createdAt.split("T")[0]}
            </Headings>
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "Message",
      flex: 2,
      sortable: true,
      renderCell: (params) => {
        return (
          <div style={{ paddingLeft: 10 }}>
            {" "}
            <Headings
              fontSize={16}
              color={"#212B36"}
              fontFamily={"Futura PT Book"}
              fontWeight={400}
            >
              {getStatusMessages(params.row.status)}
            </Headings>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Device Status",
      flex: 1,
      renderCell: (params) => {
        const { color, background } = getStatus(params.row.status);
    
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 10, padding: "10px" }}>
            <Tooltip title={`${getStatusMessages(params.row.status)}`}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // minWidth: "74px",
                height: "25px",
                backgroundColor: background,
                padding: "2px 20px",
                boxSizing: "border-box"
              }}
            >
              <Headings
                fontFamily="Futura PT"
                color={color}
                fontSize={16}
                fontWeight={200}
                textTranformation="capitalize"
              >
                {getStatusMessage(params.row.status)}
              </Headings>
            </div>
            <img
              style={{
                width: 50,
                height: 50,
                border: "1px solid transparent",
                display: params.row.data?.door_image[0]?.publicUrl
                  ? null
                  : "none",
              }}
              src={params.row.data?.door_image[0]?.publicUrl}
            />
            </Tooltip>
          </div>
        );
      }
    },
    
  ];

  const handleCellClick = (e) => {
    setJson(e.row.Data);
    setOpen(true);
  };


  // const handleSeacrch = (e) => {
    
  //   if (e.key === 'Enter') {
  //     if(searchQuery.length > 0) {
  //       dispatch(getWebhooks(`Sensor=${searchQuery}&page=${paginationModel.page}&limit=${paginationModel.pageSize}&field=${sortModel[0].field}&sort=${sortModel[0].sort}`))
  //     } else {
  //       dispatch(getWebhooks(`page=${paginationModel.page}&limit=${paginationModel.pageSize}&field=${sortModel[0].field}&sort=${sortModel[0].sort}`))
  //     }
     
  //   }
   
  // }

 

  return (
    <div className="OverAllSites" >
      <div className="searchspace">
        <div className="Heading">
          <Headings fontSize={24}>Event List</Headings>
        </div>
        {/* <div className="inputdiv">
          <img src={search} alt="No search" className="searchimage" />
          <input
            className="searchinput"
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onKeyDown={handleSeacrch}
            onChange={handleSearchChange}
          />
        </div> */}
      </div>
      <DataGrid
        rows={events}
        columns={columns}
        rowCount={count}
        loading={loading}
        pageSizeOptions={[5, 10,20,25,50,100]}
        sx={{
          padding: "10px 30px 10px 30px",
          width: "100%",
          backgroundColor: "white",
          border: "none",
          borderRadius: "0px",
          "& .MuiDataGrid-iconButtonContainer": {
            visibility: "visible",
          },
          "& .MuiDataGrid-sortIcon": {
            opacity: "inherit !important",
          },
          "& .MuiDataGrid-cell": {
            padding: "15px 0px 15px 0px",
            color: "#212B36",
            fontFamily: "Futura PT Book !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            fontFamily: "Futura PT Book",
            fontWeight: "450",
            fontSize: 16,
            padding: "0px 0px",
          },
          "& .MuiTablePagination-select": {
            background: "#EEE",
          },
        }}
     
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        disableColumnMenu // Hide the column menu
        disableColumnSelector
        // pageSize
        // autoHeight
        rowHeight={54}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        sortModel={sortModel}
        onSortModelChange={(model) => {return model.length > 0 ? setSortModel(model) : null}}
      />
      <BasicModal open={open} setOpen={setOpen} json={json} />
    </div>
  );
};

const ActionsDropdown = ({ siteId, onDelete }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleView = () => {
    navigate(`/sites/${siteId}`);
  };

  const handleDeleteClick = () => {
    onDelete(siteId);
    setAnchorEl(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClick}>
        Actions
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon fontSize="small" />
          Delete
        </MenuItem>
        <MenuItem onClick={() => navigate(`/sites/edit/${siteId}`)}>
          <EditIcon fontSize="small" />
          Edit Detail
        </MenuItem>
      </Menu>
    </div>
  );
};

const getStatus = (stat) => {
  let status = parseInt(stat);
  if (status >= 200 && status < 300) {
    return {
      color: "#34A853",
      background: "rgba(52, 168, 83, 0.1)",
    };
  } else if (status >= 300 && status < 400) {
    return {
      color: "rgba(221, 173, 1, 1)",
      background: "rgba(221, 173, 1, 0.1)",
    };
  } else if (status >= 400 && status < 500) {
    return {
      color: "rgba(235, 67, 53, 1)",
      background: "rgba(235, 67, 53, 0.1)",
    };
  } else {
    return {
      color: "#34A853",
      background: "rgba(52, 168, 83, 0.1)",
    };
  }
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: 400,
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

export default function BasicModal({ open, setOpen, json }) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


 
  const jsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <JsonFormatter json={json} tabWith={4} jsonStyle={jsonStyle} />
        </Box>
      </Modal>
    </div>
  );
}
