import { createSlice } from '@reduxjs/toolkit';
import { postApi, getApi, deleteApi, putApi, uploadService } from "../../Utils/serviceWorker";
import { createAsyncThunk } from '@reduxjs/toolkit';
import jsCookie from 'js-cookie';
import { toast } from 'react-toastify';

export const getOrganizations = createAsyncThunk(
  'organization/get',
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`organization`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getOrganization = createAsyncThunk(
  'organization/get',
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`organization/${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addOrganization = createAsyncThunk(
  'organization/add',
  async (credentials, thunkAPI) => {
    try {
      const response = await postApi(`organization/`, true, credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateOrganization = createAsyncThunk(
  'organization/update',
  async (credentials, thunkAPI) => {
    try {
      const response = await putApi(`organization/${credentials.id}`, true, credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteOrganization = createAsyncThunk(
  'organization/delete',
  async (credentials, thunkAPI) => {
    try {
      const response = await deleteApi(`organization/${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


export const getInstallationManager = createAsyncThunk('role/User', async (credentials, thunkAPI) => {
  try {
    const response = await getApi(`users?roleId=${process.env.REACT_APP_AGENT_ROLE_ID}`, true);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const getCustomerOfUser = createAsyncThunk(
  'customer/get',
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`customers?user=${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const initialState = {
  value: [],

  loading: false,
  error: null
}


export const Customers = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganizations.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getOrganizations.fulfilled, (state, action) => {
      state.loading = false;

      state.value = action.payload.rows;
    });
    builder.addCase(getOrganizations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;


    });
    builder.addCase(updateOrganization.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateOrganization.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Updated Organization Successfully");
    });
    builder.addCase(updateOrganization.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(addOrganization.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addOrganization.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Created Organization Successfully");
    });
    builder.addCase(addOrganization.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });



  },
});