import React, { useEffect } from "react";
import { Scatter } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import { format } from "date-fns";
ChartJS.register(
  ArcElement,
  Tooltip,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend
);

const DynamicScatterChartMsgType = ({ data, chartTitle, xLabel, yLabel }) => {
  const labels = data?.map((entry) => entry.createdAt.split("T")[0]);
  const doorOpenCounts = data?.map((entry) => entry?.msgType);
  const reversedLabels = labels?.reverse();
  const reversedCounts = doorOpenCounts?.reverse();
  const formattedLabels = reversedLabels?.map((date) => {
    return format(new Date(date), "MM/dd");
  });

  const scatterData = reversedLabels?.map((entry) => ({
    x: formattedLabels,
    y: entry.msgType,
  }));

  const chartData = {
    datasets: [
      {
        label: "Msg Type Weekly",
        data: scatterData,
        backgroundColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        pointBorderWidth: 0,
        pointRadius: 3,
        pointHitRadius: 10,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: chartTitle || 'Chart Title',
      },
    },
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: xLabel || "Time",
        },
        ticks: {
          autoSkip: true,
          minRotation: 0,
          fontSize: 6,
          fontFamily: "Futura Pt",
        },
      },
      y: {
        title: {
          display: true,
          text: yLabel || "Door Open",
        },
      },
    },
  };

  return <Scatter data={chartData} options={chartOptions} />;
};

export default DynamicScatterChartMsgType;