import React, { useEffect, useState } from "react";

import { Button, Divider, Link } from "@mui/material";

import { Table } from "../../Components/Table/Tables";

import { Menu, MenuItem } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { getSites, getSitesOfOrganization } from "../../Store/Features/Sites";

import AddressToArea from "../../Utils/Functions/getLocations";
import {
  getNotifications,
  getNotagainstorg,
  getAllNotifications,
  reset,
} from "../../Store/Features/notifications";
import { useDispatch, useSelector } from "react-redux";
import { getFloorsOfSite } from "../../Store/Features/Floors";
import { getDoorsofFloor } from "../../Store/Features/Door";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useNavigate } from "react-router-dom";
import {
  getStatusMessage,
  getStatusMessages,
} from "../../Utils/Functions/getStatusMessages";

import CircularProgress from "@mui/material/CircularProgress";
import { Select as MuiSelect } from "@mui/material";
import { Headings } from "../../Shared/Fonts/Fonts";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./Dashbaord.css";
import search from "../../Assets/search.svg";
import { getOrganizations,getOrganization } from "../../Store/Features/Customer";
import SitesMap from "../../Components/Maps/SitesMap";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const { value } = useSelector((state) => state.customers);
  const { user } = useSelector((state) => state.auth);
  const notifications = useSelector((state) => state.notifications.value);
  const loadingOrg = useSelector(state => state.customers.loading)
  const [org,setOrg] = useState()
  const [customer, setCustomer] = useState(0);
  const [sites, setSites] = useState([]);
  const [floors, setFloors] = useState([]);
  const [doors, setDoors] = useState([]);
  const [showNotification, setShowNotification] = useState(true);
  const matches = useMediaQuery("(max-width:769px)");
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [site, setSite] = useState(0);
  const [floor, setFloor] = useState(0);

  const [sortModel, setSortModel] = useState([
    { field: 'createdAt', sort: 'desc'},
  ]);
  const [door, setDoor] = useState(0);
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredSites =
    sites?.length > 0 &&
    sites?.filter((site) => {
      // Iterate through each field in the site object
      let each = false;
      for (const k in site) {
        if (site.hasOwnProperty(k)) {
          if (k == "organization" || k == "doorsCount") {
            const value = site[k] + "";

            // Check if the field value contains the search query (case-insensitive)
            if (
              value &&
              value.toString().toLowerCase().match(searchQuery.toLowerCase())
            ) {
              each = true; // Return true if a match is found
            }
          } else if (!searchQuery) {
            const value = site[k] + "";

            // Check if the field value contains the search query (case-insensitive)
            if (
              value &&
              value.toString().toLowerCase().match(searchQuery.toLowerCase())
            ) {
              each = true; // Return true if a match is found
            }
          }
        }
      }
      for (const key in site.data) {
        if (site.data.hasOwnProperty(key)) {
          // address sector data status
          if (
            key == "address" ||
            key == "sector" ||
            key == "noOfSensors" ||
            key == "name" ||
            key == "noOfDoors" ||
            key == "organization"
          ) {
            // .noOfSensors
            const value = site.data[key] + "";
         
            // Check if the field value contains the search query (case-insensitive)
            if (
              value &&
              value.toString().toLowerCase().match(searchQuery.toLowerCase())
            ) {
              each = true; // Return true if a match is found
            }
          } else if (!searchQuery) {
            const value = site.data[key] + "";
          
            // Check if the field value contains the search query (case-insensitive)
            if (
              value &&
              value.toString().toLowerCase().match(searchQuery.toLowerCase())
            ) {
              each = true; // Return true if a match is found
            }
          }
        }
      }
      return each;
    });

  useEffect(() => {
  
      if (
        user?.roleId == process.env.REACT_APP_USER_ROLE_ID ||
        user?.roleId == process.env.REACT_APP_ORG_ADMIN_ROLE_ID
      ) {
        setCustomer(user?.organizationId);
      } else {
        dispatch(getOrganizations());
      }
  
   
  }, []);

  useEffect(()=> {
    if(user.organizationId != null) {
      dispatch(getOrganization(user.organizationId)).then(res => {
        if(!res.error) {
          setOrg(res.payload)
        }
      });
    }
  },[])

  useEffect(() => {
    if (site != null && site != 0) {
      dispatch(getFloorsOfSite(site)).then((res) => {
        setFloors(res.payload?.data);
      });
    }
  }, [site]);
  useEffect(() => {
    dispatch(reset());
  }, [customer]);
  useEffect(() => {
    if (customer != null && customer != 0) {
      if (site && site != 0) {
        if (door != null && door != 0) {
          dispatch(
            getNotifications({ page: 0, limit: 6, id: door, query: "door",sort: "desc",field: "createdAt" })
          );
        } else if (floor != null && floor != 0) {
          dispatch(
            getNotifications({ page: 0, limit: 6, id: floor, query: "floor",sort: "desc",field: "createdAt" })
          );
        } else {
          dispatch(
            getNotifications({ page: 0, limit: 6, id: site, query: "site",sort: "desc",field: "createdAt" })
          );
        }
      } else {
        if (user?.organizationId) {
          dispatch(
            getNotagainstorg({ page: 0, limit: 6, orgId: user?.organizationId,sort: "desc",field: "createdAt" })
          );
        }else {
          if (customer) {
            dispatch(getNotagainstorg({ page: 0, limit: 6, orgId: customer,sort: "desc",field: "createdAt" }));
          }
        }

        
      }
    } else {
      if (
        user?.roleId == process.env.REACT_APP_USER_ROLE_ID ||
        user?.roleId == process.env.REACT_APP_ORG_ADMIN_ROLE_ID
      ) {
        dispatch(
            getNotagainstorg({ page: 0, limit: 6, orgId: user?.organizationId,sort: "desc",field: "createdAt" })
        );
      }
      else {
        dispatch(getAllNotifications({page: 0, limit: 6, sort: "desc", field: "createdAt"}));
      }
    }
  }, [site, user, customer, door, floor]);

  useEffect(() => {
    if (floor != null && floor != 0) {
      dispatch(getDoorsofFloor(floor)).then((res) => {
        setDoors(res.payload.data);
      });
    }
  }, [floor]);

  useEffect(() => {
    if (site == 0) {
      setDoor(0);
      setFloor(0);
    }
  }, [site]);

  return (
    <div className="dashboard">
      <div className="headers-admin-dashboard">
        <Headings>
          {user?.roleId == process.env.REACT_APP_ORG_ADMIN_ROLE_ID ||
          user?.roleId == process.env.REACT_APP_USER_ROLE_ID
            ? loadingOrg ? null :`${org?.name ? org.name : null} `
            : "Admin "}
           Dashboard
        </Headings>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 23,
          }}
          className="dashboard-filters"
        >
          <div
            style={{
              // display: "flex",
              alignItems: "center",
              gap: 23,
              display:
                user?.roleId == process.env.REACT_APP_USER_ROLE_ID ||
                user?.roleId == process.env.REACT_APP_ORG_ADMIN_ROLE_ID
                  ? "none"
                  : "flex",
            }}
          >
            <Select
              options={value}
              onChange={setCustomer}
              value={customer}
              title={"Organisation"}
              placeholder={"Organisation"}
            />
            {/* <Search /> */}
          </div>
          <div className="inputdiv">
            <img src={search} alt="No search" className="searchimage" />
            <input
              className="searchinput"
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>
      <div className="adminDashboard-content">
        <div className="right-content">
          <div className="right-top-content">
            <div className="right-top-left-content">
              <div className="right-top-right-heading-content">
                <Headings>Outstanding Alerts</Headings>
              </div>
              <div className="adminDashboard-Map">
                <SitesMap zoom={4} />
              </div>
            </div>
            {/* {user?.roleId == process.env.REACT_APP_ADMIN_ROLE_ID  && (
              <div className="right-top-right-content">
                <Headings fontSize={16} fontWeight={450}>
                  Management of Installer
                </Headings>
                <div className="right-top-right-bottom-content">
                  {[1, 2, 3, 4].map((item, index) => {
                  return (
                    <div className="adminDashboard-installation">
                      <div className="textArea">
                        <Headings
                          fontSize={16}
                          fontFamily={"Futura PT Book"}
                          fontWeight={450}
                        >
                          Cardiff residential block
                        </Headings>
                        <Headings
                          fontFamily={"Futura PT Book"}
                          fontSize={16}
                          fontWeight={450}
                        >
                          53 Doors
                        </Headings>
                      </div>
                      <Divider sx={{ width: "100%" }} />
                    </div>
                  );
                })} 
                </div>
              </div>
            )} */}
          </div>

          <div className="right-bottom-content">
            <Sites
              customer={customer}
              sites={filteredSites}
              setSites={setSites}
            />
          </div>
        </div>
        <div className="left-content">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Headings fontSize={16} fontWeight={450}>
              Event History
            </Headings>
            <button
              className="viewEvents customButtomOverAll"
              onClick={() => navigate("/webhooks")}
            >
              <Headings>View All Events</Headings>
            </button>
          </div>
          <div className="left-top-right-bottom-content">
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <Select
                options={sites}
                onChange={setSite}
                value={site}
                sx={{ width: "200px" }}
                title={"Sites Dropdown"}
                placeholder={"Sites Dropdown"}
              />
              <Select
                options={floors}
                onChange={setFloor}
                value={floor}
                type={"sub"}
                sx={{
                  width: "200px",
                  display: site != null && site != 0 ? null : "none",
                }}
                title={"Sites Dropdown"}
                placeholder={"Sites Dropdown"}
              />
              <Select
                options={doors}
                onChange={setDoor}
                value={door}
                type={"sub"}
                sx={{
                  width: "200px",
                  display:
                    site != null && site != 0 && floor != null && floor != 0
                      ? null
                      : "none",
                }}
                title={"Sites Dropdown"}
                placeholder={"Sites Dropdown"}
              />
            </div>

            {notifications?.map((item, index) => {
              return (
                 
                  <div className="adminDashboard-installation">
                    <div className="textArea-Right-Content">
                      <Link sx={{ cursor: "pointer", textDecoration: "none" }}>
                        <Headings
                          fontSize={16}
                          fontFamily={"Futura PT"}
                          color={getStatus(item.status).color}
                          fontWeight={450}
                          onClick={() => {
                            navigate(
                              `/sites/${item.siteId}/floor/${item.floorId}/asset/${item.doorId}`
                            );
                          }}
                        >
                          {getStatusMessages(item.status)}
                        </Headings>
                      </Link>
                      <div className="textArea">
                        <Headings
                          fontSize={16}
                          fontFamily={"Futura PT Book"}
                          fontWeight={450}
                        >
                          {item.createdAt.split("T")[1].split(":")[0] +
                            ":" +
                            item.createdAt.split("T")[1].split(":")[1]}
                        </Headings>
                        <Headings
                          fontFamily={"Futura PT Book"}
                          fontSize={16}
                          fontWeight={450}
                        >
                          {item.createdAt.split("T")[0]}
                        </Headings>
                      </div>
                    </div>
                    <Divider sx={{ width: "100%" }} />
                  </div>
                )
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;



function Select({
  options,
  onChange,
  value,
  title,
  key,
  placeholder,
  area,
  sx,
  type,
}) {
  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;
    if (onChange) {
      onChange(selectedOption);
    }
  };

  return (
    <MuiSelect
      value={value ? value : 0}
      onChange={handleOptionChange}
      sx={{
        border: "none",
        bgcolor: "transparent",
        "&:focus": { bgcolor: "transparent" },
        "&: .MuiInputBase-root > .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        // display: "flex",

        gap: "10px",
        fontFamily: "Futura PT",
        width: "200px",
        display: sx?.display ? sx.display : 'flex',
      }}
      displayEmpty
      // inputProps={{ 'aria-label': 'Select' }}
      placeholder={title}
      // IconComponent={() => <KeyboardArrowDownIcon sx={{ fontSize: "20px" }} />}
    >
      <MenuItem key={0} value={0}>
        <Headings fontSize={16} fontWeight={400} fontFamily={"Futura PT Book"}>
          All
        </Headings>
      </MenuItem>
      {options?.map((option) => (
        <MenuItem
          key={option.id ? option.id : option.data.id}
          value={option.id ? option.id : option.data.id}
        >
          <Headings
            fontSize={16}
            fontWeight={400}
            fontFamily={"Futura PT Book"}
            textTransform={"capitalize"}
          >
            {type == "sub"
              ? option.description
                ? option.description
                : option.data.description
              : option.name
              ? option.name
              : option.data.name}
          </Headings>
        </MenuItem>
      ))}
    </MuiSelect>
  );
}

// import {useDispatch,useSelector} from "react-redux"

export const Sites = (props) => {
  const dispatch = useDispatch();

  const matches = useMediaQuery("(max-width:769px)");
  const navigate= useNavigate();
  const loading = useSelector((state) => state.sites.loading);

  const { customer, sites, setSites } = props;
  const { user } = useSelector((state) => state.auth);
  
  

  // useEffect(() => {

  //   if (customer != null && customer != 0) {
  //     dispatch(getSitesOfOrganization(customer)).then((res) => {
  //       if (!res.error) {
  //         setSites(res.payload.data);
  //       }
  //     });
  //   } else {

  //     dispatch(getSites()).then((res) => {
  //       if (!res.error) {
  //         setSites(res.payload.data);
  //       }
  //     });
  //   }
  // }, [customer]);

  useEffect(() => {
    if (user.id) {
      if (
        user?.roleId == process.env.REACT_APP_USER_ROLE_ID ||
        user?.roleId == process.env.REACT_APP_ORG_ADMIN_ROLE_ID
      ) {
        dispatch(getSites(`organization=${user.organizationId}`)).then((res) => {
          if (!res.error) {
            setSites(res.payload.data);
          }
        });
      }
      else {
        const org = customer != 0 ? `organization=${customer}`: '' 
        dispatch(getSites(org)).then((res) => {
          if (!res.error) {
            setSites(res.payload.data);
          }
        });
      }
      
    }
  }, [user,customer]);

  useEffect(() => {
    document.title = "Sites";
  }, []);

  const columns = [
    {
      field: "data",
      headerName: "Organisation",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <Headings
            fontSize={16}
            color={"#212B36"}
            fontFamily="Futura PT Book"
            fontWeight={400}
            textTranformation="capitalize"
          >
            {params.row.data?.organization?.name}
          </Headings>
        );
      },

      sortComparator: (v1, v2) =>
        v1.organization?.name.localeCompare(v2.organization?.name),
    },
    {
      field: "address",
      headerName: "Location",
      flex: 1,

      renderCell: (params) => {
        return (
          <Headings
            fontSize={16}
            color={"#212B36"}
            fontFamily="Futura PT Book"
            fontWeight={400}
            textTranformation="capitalize"
          >
            {AddressToArea(params.row.data.address)}
          </Headings>
        );
      },
    },

    {
      field: "doorsCount",
      headerName: "Description",
      flex: 1,

      renderCell: (params) => (
        <Headings
          fontSize={16}
          color={"#212B36"}
          fontFamily="Futura PT Book"
          fontWeight={400}
          textTranformation="capitalize"
        >
          {" "}
          {params.row.data.sector} - {params.row.doorsCount}{" "}
        </Headings>
      ),
    },
    {
      field: "event",
      headerName: "Event",
      flex: 1,
      renderCell: (params) => (
        <Headings
          fontSize={16}
          color={"#212B36"}
          fontFamily="Futura PT Book"
          fontWeight={400}
          textTranformation="capitalize"
        >
        {  
            params.row.lastEvent != 404 ? (
              params.row.lastEvent.createdAt.split("T")[1].split(":")[0] +
              ":" +
              params.row.lastEvent.createdAt.split("T")[1].split(":")[1] + 
              ` ${params.row.lastEvent.createdAt.split("T")[0]}`
            ) : "No Event Generated Yet"
          
          }
        </Headings>
      ),
      
      // sortComparator: (v1, v2) => console.log("noOfDoors", v1.noOfDoors.localeCompare(""))
    },

    {
      field: "status",
      headerName: "Sensor Status",
      flex: 1.5,
      renderCell: (params) => {
        const { color, background } = getStatus(params.row.status);
      
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "74px",
              height: "25px",
              backgroundColor: background,
              padding: "2px 20px",
            }}
          >
            <Headings
              fontFamily="Futura PT"
              color={color}
              fontSize={16}
              fontWeight={200}
              textTranformation="capitalize"
            >
              {getStatusMessage(params.row.status)}
            </Headings>
          </div>
        );
      },
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
  ];

  const mobileColumns = [
    {
      field: "data",
      headerName: "Organisation",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <Headings
              fontSize={16}
              color={"#212B36"}
              fontFamily="Futura PT Book"
              fontWeight={400}
              textTranformation="capitalize"
            >
              {params.row.data?.organization?.name}
            </Headings>
            <Headings
              fontFamily="Futura PT Book"
              fontSize={16}
              fontWeight={200}
              textTranformation="capitalize"
            >
              {params.row.data.address.split(" ")[3]}
            </Headings>
          </div>
        );
      },

      sortComparator: (v1, v2) =>
        v1.data.address.localeCompare(v2.data.address),
    },
    {
      field: "status",
      headerName: "Sensor Status",
      flex: 1,
      renderCell: (params) => {
        const { color, background } = getStatus(params.row.status);

        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minWidth: "74px",
                height: "25px",
                backgroundColor: background,
                padding: "2px 20px",
              }}
            >
              <Headings
                fontFamily="Futura PT"
                color={color}
                fontSize={16}
                fontWeight={200}
                textTranformation="capitalize"
              >
                {getStatusMessage(params.row.status)}
              </Headings>
            </div>

            <Headings
              fontFamily="Futura PT Book"
              color={color}
              fontSize={16}
              fontWeight={400}
              textTranformation="capitalize"
            >
              {getStatusMessage(params.row.data.updatedAt)}
            </Headings>
          </div>
        );
      },
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
  ];

  const handleRowClick = (e) => {
    navigate(`/sites/${e.id}`);
  };

  return (
    <Table
      height={true}
      rows={sites}
      columns={matches ? mobileColumns : columns}
      rowSelection={false}
      unique_identifier={"id"}
      columnVisibilityModel = {{
        data: user.roles.id == process.env.REACT_APP_ADMIN_ROLE_ID ? true : false,
      }}
      loading={loading}
      handleRowClick={handleRowClick}
    />
  );
};

const ActionsDropdown = ({ siteId, onDelete }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleView = () => {
    navigate(`/sites/${siteId}`);
  };

  const handleDeleteClick = () => {
    onDelete(siteId);
    setAnchorEl(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className="customButtomOverAll"
        variant="outlined"
        onClick={handleClick}
      >
        Actions
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon fontSize="small" />
          Delete
        </MenuItem>
        <MenuItem onClick={() => navigate(`/sites/edit/${siteId}`)}>
          <EditIcon fontSize="small" />
          Edit Detail
        </MenuItem>
        <MenuItem onClick={handleView}>
          <VisibilityIcon fontSize="small" />
          View
        </MenuItem>
      </Menu>
    </div>
  );
};

const getStatus = (stat) => {
  let status = parseInt(stat);
  if (status >= 200 && status < 300) {
    return {
      color: "#34A853",
      background: "rgba(52, 168, 83, 0.1)",
    };
  } else if (status >= 300 && status < 400) {
    return {
      color: "rgba(221, 173, 1, 1)",
      background: "rgba(221, 173, 1, 0.1)",
    };
  } else if (status >= 400 && status < 500) {
    return {
      background: "rgba(235, 67, 53, 0.1)",
      color: "#EB4335",
    };
  } else {
    return {
      color: "#FFA500",
      background: "rgba(254, 216, 177, 0.1)",
    };
  }
};
