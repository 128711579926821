import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";

import DeleteIcon from "@mui/icons-material/Delete";
import "./Users.css";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "../../Components/Table/Tables";
import { Button, Menu, MenuItem } from "@mui/material";
import { getUsers, deleteUser, updateUser, getOrganizationUsers } from "../../Store/Features/Users";
import { useDispatch, useSelector } from "react-redux";
import search from "../../Assets/search.svg";
import Switch from '@mui/material/Switch';
import { IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Edit from "../../Assets/Edit.svg";


const Users = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.users);
  const { user } = useSelector(state => state.auth);
  const users = useSelector((state) => state.users.value);
  const [searchQuery, setSearchQuery] = useState("");
  const { organizationId } = useParams();
  const matches = useMediaQuery("(max-width:769px)");


  // Function to handle changes in the search input
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter the sites array based on the search query
  const filteredUsers = users?.length > 0 ? users?.filter((user) => {
    // Iterate through each field in the site object
    let each = false;
    for (const key in user) {
      if (user.hasOwnProperty(key)) {

        // address sector data status
        // description height width length top_gap bottom_gap left_gap right_gap direction createdById
        if (
          key == "id" ||
          key == "firstName" ||
          key == "lastName" ||
          key == "phoneNumber" ||
          key == "email"
        ) {
          if (key == "roles") {
            const value = user[key].title + "";

            // Check if the field value contains the search query (case-insensitive)
            if (
              value &&
              value.toString().toLowerCase().match(searchQuery.toLowerCase())
            ) {
              each = true; // Return true if a match is found
            }
          } else {
            const value = user[key] + "";

            // Check if the field value contains the search query (case-insensitive)
            if (
              value &&
              value.toString().toLowerCase().match(searchQuery.toLowerCase())
            ) {
              each = true; // Return true if a match is found
            }
          }
        } else if (!searchQuery) {
          const value = user[key] + "";

          // Check if the field value contains the search query (case-insensitive)
          if (
            value &&
            value.toString().toLowerCase().match(searchQuery.toLowerCase())
          ) {
            each = true; // Return true if a match is found
          }
        }
      }
    }
    return each;
  }) : [];
  const handleDeleteUser = (id) => {
    dispatch(deleteUser(id)).then(() => {
      if (organizationId || user?.roleId == process.env.REACT_APP_ORG_ADMIN_ROLE_ID) {
        if (organizationId) {
          dispatch(getOrganizationUsers(organizationId));
        } else {
          dispatch(getOrganizationUsers(user.organizationId));
        }
      } else {
        dispatch(getUsers());
      }
    });
  };
  const isRowHidden = (params) => {
    // Your condition to hide the row goes here

    return params.row.id == user?.id; // Hide row with id 2 as an example
  };


  const getRowClassName = (params) => {
    return isRowHidden(params) ? 'hidden-row' : '';
  };

  const columns = [
    // { field: "id", headerName: "ID", flex: 1, hide: true },
    {
      field: "firstName",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Headings
          fontSize={16}
          color={"#212B36"}
          fontFamily={"Futura PT Book"}
          fontWeight={400}
        >
          {params.row.firstName + " " + (params.row.lastName || "")}
        </Headings>
      ),
    },
    {
      field: "organization",
      headerName: "Organization",
      flex: 1,
      renderCell: (params) => (
        <Headings
          fontSize={16}
          color={"#212B36"}
          fontFamily={"Futura PT Book"}
          fontWeight={400}
        >
          {params.row.organizationId !=null ? params.row.organization?.name : "Non-Organization-User"}
        </Headings>
      ),
    },
    { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => {
        return (
          <Headings
            fontSize={16}
            color={"#212B36"}
            fontFamily={"Futura PT Book"}
            fontWeight={400}
          >
            {params.row.email}
          </Headings>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      renderCell: (params) => {
        return (
          <Headings
            fontSize={16}
            color={"#212B36"}
            fontFamily={"Futura PT Book"}
            fontWeight={400}
          >
            {params.row.roles?.title}
          </Headings>
        );
      },
    },
    
    {
      field: "disabled",
      headerName: "Disabled",
      flex: 1,
      renderCell: (params) => {
        return (
          <Switch checked={params.row.disabled} onChange={(e) => {
            const { id, ...temp } = params.row;
            dispatch(updateUser({
              id: id, data: {
                "disabled": e.target.checked,
              }
            })).then(() => {
              if (organizationId || user?.roleId == process.env.REACT_APP_ORG_ADMIN_ROLE_ID) {
                if (organizationId) {
                  dispatch(getOrganizationUsers(organizationId));
                } else {
                  dispatch(getOrganizationUsers(user.organizationId));
                }
              } else {
                dispatch(getUsers());
              }
            });
          }} />
        );
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <ActionsDropdown userId={params.row.id} onDelete={handleDeleteUser} />
      ),
    },
  ];

  const mobileColumns = [
    {
      field: "first_name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <Headings
            fontSize={16}
            color={"#212B36"}
            fontFamily={"Futura PT"}
            fontWeight={400}
          >
            {params.row.firstName + " " + (params.row.lastName || "")}
          </Headings>
          <Headings
            fontSize={16}
            color={"#212B36"}
            fontfamily={"Futura PT Book"}
            fontWeight={400}
          >
            {params.row.email}
          </Headings>
        </div>

      ),
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <Headings
              fontSize={16}
              color={"#212B36"}
              fontfamily={"Futura PT"}
              fontWeight={400}
            >
              {params.row.roles?.title}
            </Headings>
            <Switch checked={params.row.disabled} onChange={(e) => {
              const { id, ...temp } = params.row;
              dispatch(updateUser({
                id: id, data: {
                  "disabled": e.target.checked,
                }
              })).then(() => {
                dispatch(getUsers());
              });
            }} />
          </div>
        );
      },
    },
    {
      field: " ",
      headerName: " ",
      flex: 0.2,
      sortable: false,
      renderCell: (params) => (
        <ActionsDropdown userId={params.row.id} onDelete={handleDeleteUser} />
      ),
    },
  ];

  useEffect(() => {
    if (organizationId || user?.roleId == process.env.REACT_APP_ORG_ADMIN_ROLE_ID) {
      if (organizationId) {
        dispatch(getOrganizationUsers(organizationId));
      } else {
        dispatch(getOrganizationUsers(user.organizationId));
      }
    } else {
      dispatch(getUsers());
    }

  }, [organizationId]);

  useEffect(() => {
    document.title = "Users";
  }, []);

  return (
    <div className="OverAllSites">
      <div className="searchspace">
        <div className="Heading">
          <Headings fontSize={24}>Users</Headings>

          <button
            className="addSiteButton customButtomOverAll"
            onClick={() => organizationId || user?.roleId == process.env.REACT_APP_ORG_ADMIN_ROLE_ID ? navigate(`/organizations/${organizationId ? organizationId : user.organizationId}/users/add`) : navigate("/users/add")}
          >
            <Headings>Add User</Headings>
          </button>
          {user?.roleId == process.env.REACT_APP_ADMIN_ROLE_ID && !organizationId && (
            <>

              <button
                className="addSiteButton customButtomOverAll"
                onClick={() => navigate("/organizations")}
              >
                <Headings>Organisations</Headings>
              </button>
            </>
          )}


        </div>
        <div className="inputdiv">
          <img src={search} alt="No search" className="searchimage" />
          <input
            className="searchinput"
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div style={{ height: 400, width: "100%" }}>
        <Table
          rows={filteredUsers}
          columns={matches ? mobileColumns : columns}
          rowSelection={false}
          loading={loading}
          getRowClassName={getRowClassName}
          matches={matches}
        />
      </div>
    </div>
  );
};

export default Users;

const ActionsDropdown = ({ userId, onDelete }) => {
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useSelector(state => state.auth);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    onDelete(userId);
    handleClose();
  };

  return (
    <div>
      <IconButton variant="outlined" onClick={handleClick}>
        <img src={Edit} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>

        <MenuItem onClick={() => organizationId || user?.roleId == process.env.REACT_APP_ORG_ADMIN_ROLE_ID
          ? navigate(`/organizations/${user?.roleId == process.env.REACT_APP_ORG_ADMIN_ROLE_ID ? user?.organizationId :organizationId}/users/${userId}`) : navigate(`/users/edit/${userId}`)}>
          <EditIcon fontSize="small" />
          Edit
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon fontSize="small" />
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};
