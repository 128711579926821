import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";

import DeleteIcon from "@mui/icons-material/Delete";
import "./Roles.css";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import { useNavigate } from "react-router-dom";
import { Table } from "../../Components/Table/Tables";
import { Button, Menu, MenuItem } from "@mui/material";
import { getUsers, deleteUser } from "../../Store/Features/Users";
import { useDispatch, useSelector } from "react-redux";
import search from "../../Assets/search.svg";
import { IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Edit from "../../Assets/Edit.svg";

const Roles = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.users);
  const users = useSelector((state) => state.users.value);
  const [installationManagers, setInstallationManagers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const matches = useMediaQuery("(max-width:769px)");

  // Function to handle changes in the search input
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter the sites array based on the search query
  const filteredInstallationManagers = installationManagers.filter(
    (installationManager) => {
      // Iterate through each field in the site object
      let each = false;
      for (const key in installationManager) {
        if (installationManager.hasOwnProperty(key)) {
      
          // address sector data status
          // description height width length top_gap bottom_gap left_gap right_gap direction createdById
          if (
            key == "id" ||
            key == "firstName" ||
            key == "lastName" ||
            key == "phoneNumber" ||
            key == "email"
          ) {
            if (key == "roles") {
              const value = installationManager[key].title + "";
            
              // Check if the field value contains the search query (case-insensitive)
              if (
                value &&
                value.toString().toLowerCase().match(searchQuery.toLowerCase())
              ) {
                each = true; // Return true if a match is found
              }
            } else {
              const value = installationManager[key] + "";
              
              // Check if the field value contains the search query (case-insensitive)
              if (
                value &&
                value.toString().toLowerCase().match(searchQuery.toLowerCase())
              ) {
                each = true; // Return true if a match is found
              }
            }
          } else if (!searchQuery) {
            const value = installationManager[key] + "";
           
            // Check if the field value contains the search query (case-insensitive)
            if (
              value &&
              value.toString().toLowerCase().match(searchQuery.toLowerCase())
            ) {
              each = true; // Return true if a match is found
            }
          }
        }
      }
      return each;
    }
  );
  useEffect(() => {
    document.title = "Installation Manager";
  }, []);

  const handleDeleteUser = (id) => {
    dispatch(deleteUser(id)).then(() => {
      dispatch(getUsers());
    });
  };

  const columns = [

    {
      field: "first_name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Headings
          fontWeight={400}
          color={"#212B36"}
          fontFamily="Futura PT Book"
        >
          {params.row.firstName + " " + (params.row.lastName || "")}
        </Headings>
      ),
    },
    { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => {
        return (
          <Headings
            fontSize={16}
            color={"#212B36"}
            fontFamily={"Futura PT Book"}
            fontWeight={400}
          >
            {params.row.email}
          </Headings>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      renderCell: (params) => {
        return (
          <Headings
            fontWeight={400}
            color={"#212B36"}
            fontFamily="Futura PT Book"
          >
            {params.row.roles.title}
          </Headings>
        );
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      renderCell: (params) => (
        <ActionsDropdown userId={params.row.id} onDelete={handleDeleteUser} />
      ),
    },
  ];
  const mobileColumns = [
    {
      field: "first_name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <Headings
            fontSize={16}
            color={"#212B36"}
            fontFamily={"Futura PT"}
            fontWeight={400}
          >
            {params.row.firstName + " " + (params.row.lastName || "")}
          </Headings>
          <Headings
            fontSize={16}
            color={"#212B36"}
            fontfamily={"Futura PT Book"}
            fontWeight={400}
          >
            {params.row.email}
          </Headings>
        </div>

      ),
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <Headings
              fontSize={16}
              color={"#212B36"}
              fontfamily={"Futura PT"}
              fontWeight={400}
            >
              {params.row.roles?.title}
            </Headings>
          </div>
        );
      },
    },
    {
      field: " ",
      headerName: " ",
      flex: 0.2,

      sortable: false,
      renderCell: (params) => (
        <ActionsDropdown userId={params.row.id} onDelete={handleDeleteUser} />
      ),
    },
  ];


  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (users?.length > 0) {
      setInstallationManagers(() => {
        return users.filter((ele) => {
          return ele.roleId == process.env.REACT_APP_AGENT_ROLE_ID;
        });
      });
    }
  }, [users]);



  return (
    <div className="OverAllSites">
      <div className="searchspace">
        <Headings className="Heading" fontSize={24}>
          Installation Managers
        </Headings>
        {/* <button className="addSiteButton" onClick={() => navigate("/users/add")}>
          <Headings></Headings>
        </button> */}
        <div className="inputdiv">
          <img src={search} alt="No search" className="searchimage" />
          <input
            className="searchinput"
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div style={{ height: 400, width: "100%" }}>
        <Table
          rows={filteredInstallationManagers}
          columns={matches ? mobileColumns : columns}
          rowSelection={false}
          loading={loading}
          matches={matches}

        />
      </div>
    </div>
  );
};

export default Roles;

const ActionsDropdown = ({ userId, onDelete }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    onDelete(userId);
    handleClose();
  };

  return (
    <div>
      <IconButton variant="outlined" onClick={handleClick}>
        <img src={Edit} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon fontSize="small" />
          Delete
        </MenuItem>
        <MenuItem onClick={() => navigate(`/installation_manager/edit/${userId}`)}>
          <EditIcon fontSize="small" />
          Edit
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>
          <VisibilityIcon fontSize="small" />
          View
        </MenuItem> */}
      </Menu>
    </div>
  );
};
