import { createSlice } from '@reduxjs/toolkit';
import { postApi, getApi, deleteApi, putApi } from "../../Utils/serviceWorker";
import { createAsyncThunk } from '@reduxjs/toolkit';
import jsCookie from 'js-cookie';
import { toast } from 'react-toastify';


export const getInspections = createAsyncThunk(
  'inspections/get',
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`inspections?${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getInspection = createAsyncThunk(
  'inspection/get',
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`inspection/${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addInspection = createAsyncThunk(
  'Inspection/add',
  async (credentials, thunkAPI) => {
    try {
      const response = await postApi(`inspection`, true, credentials);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateInspection = createAsyncThunk(
  'inspection/update',
  async (credentials, thunkAPI) => {
    try {
      const response = await putApi(`inspection/${credentials.id}`, true, credentials.data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteInspection = createAsyncThunk(
  'inspection/delete',
  async (credentials, thunkAPI) => {
    try {
      const response = await deleteApi(`inspection/${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  value: [],

  loading: false,
  error: null
}


export const Inspections = createSlice({
  name: 'inspections',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getInspections.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getInspections.fulfilled, (state, action) => {
      state.loading = false;

      state.value = action.payload.rows;
    });
    builder.addCase(getInspections.rejected, (state, action) => {
      state.loading = false;

      state.error = action.payload;
    });
    builder.addCase(updateInspection.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateInspection.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Updated Inspection Successfully");
    });
    builder.addCase(updateInspection.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(addInspection.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addInspection.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Created Inspection Successfully");
    });
    builder.addCase(addInspection.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});