import React, { useState, useEffect } from 'react';
import authLogo from "../../Assets/authLogo.svg";
import { Card, CardContent, TextField, Button, InputAdornment } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import IconButton from '@mui/material/IconButton';
import './Auth.css';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { login, verifyEmail } from "../../Store/Features/Auth";
import { Headings, Text } from "../../Shared/Fonts/Fonts";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useLocation } from 'react-router-dom';

export function VerifyScreen() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [tokenError, setTokenError] = useState('');
  const { loading } = useSelector(state => state.auth);
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const handleLogin = () => {
    // Validate fields before performing login action
    if (!token || token.length == 0) {
      setTokenError('Email is required');
      return;
    }



    // Perform login action here
    // dispatch(login({ email, password })).then((res) => {
    //   if (res.error) {
    //     setError(res.payload.response.data.message);
    //   } else {
    //     navigate("/");
    //   }
    // });


  };

  useEffect(() => {
 
    if (token) {
      dispatch(verifyEmail(token)).then(res => {
        if (!res.error) {
          navigate("/");
        }

      });
    }
  }, [token]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    document.title = "Verify Token";
  }, []);




  return (
    <Card className="login-card">
      <CardContent className="login-container" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
        {loading ? <CircularProgress color="inherit" /> : <Headings>Could Not Verify Email</Headings>}
      </CardContent>
    </Card>
  );
}
