import {setKey,geocode,RequestType } from 'react-geocode';
import {useState} from "react"


function AddressToArea(address) {
    setKey(process.env.REACT_APP_GOOGLE_API)
    const [area,setArea] = useState()


  const getArea = () => {
    geocode(RequestType.ADDRESS, address)
      .then(response => {
     
        setArea(response.results[0].address_components[3].long_name)
        
      }).catch((error) => {
        console.error(error);
      });
  } 
  getArea()
 return area
    
  
  
}

export default AddressToArea;