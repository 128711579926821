import React, { useState, useEffect } from "react";
import { Headings, SubHeadings, Text } from '../../Shared/Fonts/Fonts';
import "./Forms.css";
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import counterClockWise from "../../Assets/counterClockWise.png";
import clockWiseDoor from "../../Assets/clockWiseDoor.png";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
export const DoorInformation = ({ form, setForm, data }) => {
  return (
    <div className="SensorConnection">
      <Text>Data Recieved! Please Enter Door Information</Text>
      <div style={{ display: "flex", gap: 10, justifyContent: "column", width: "100%" }}>
        <div className="Content" style={{ width: "100%", justifyContent: "flex-start" }}>
          <div className="firstHalfDoorInformation">
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Text>Opening Direction</Text>
            </div>
            <ImageRadios form={form} setForm={setForm} name='direction' />
          </div>
          <Divider orientation="vertical" flexItem>
          </Divider>
          <div className="SecondHalfDoorInformation" >
            <div className="contentForm">
                <Headings>Confirm Door Size
                    <small style={{color: "rgb(235, 67, 53)", backgroundColor: "rgba(235, 67, 53, 0.1)", padding: "2px"}}>(Mtr)</small>
                </Headings>

                <div className="ButtonsGroup">
                <Input form={form} setForm={setForm} name='width' />
                <Input form={form} setForm={setForm} name='height' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Input = ({ setForm, name, form, }) => {
  return (<div className="SensorInputs">
      <labe> <span style={{textTransform: 'capitalize'}}>{name}</span>
        <input placeholder={`${name}`} className="inputSensors form-controll" value={form[name]} onChange={(e) => setForm({ ...form, [name]: e.target.value })} />
      </labe>
  </div>);
};
function ImageRadios({ form, setForm, name }) {
  const handleChange = (event) => {
    setForm({ ...form, direction: event.target.value });
  };
  return (
    <FormControl fullWidth>
      <RadioGroup
        value={form[name]}
        onChange={handleChange}
        name={name}
        sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "0px 0px 0px 10px" }}
      >
        <FormControlLabel
          value="CW"
          control={<Radio sx={{ padding: "0px" }} />}
          // sx={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}
          label={
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "flex-end" }}>
              <RotateLeftIcon />
            </div>
          }
        />
        <FormControlLabel
          value="CCW"
          control={<Radio sx={{ padding: "0px" }} />}
          // sx={{ display: "flex", flexDirection: "row", alignItems: "center", alignItems: "flex-end" }}
          label={
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <RotateRightIcon />
            </div>
          }
        />
      </RadioGroup>
    </FormControl>
  );
}