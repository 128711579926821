import * as React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import { SensorConnection } from "../../Components/AssetForms/SensorConnection";
import { SensorLocation } from "../../Components/AssetForms/SensorLocation";
import { DoorInformation } from "../../Components/AssetForms/DoorInformation";
import { SensorEnabler } from "../../Components/AssetForms/SensorEnable";
import { SensorReceive } from "../../Components/AssetForms/SensorReceive";
import { GapCheck } from "../../Components/AssetForms/GapCheck";
import { Confirm } from "../../Components/AssetForms/Confirm";
import { getSite } from "../../Store/Features/Sites";
import { getFloor } from "../../Store/Features/Floors";
import { getDoor, updateDoor } from "../../Store/Features/Door";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { Paper } from "@mui/material";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { addSensor } from "../../Store/Features/Sensors";
import Check from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { GetSite } from "../../Components/AssetForms/SelectSites";
import { getCodesByQuery } from "../../Store/Features/device_codes";
import { getOrgDevice } from "../../Store/Features/Sensors";
import { toast } from "react-toastify";

const steps = ["Add Sensor"];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};
const qrCodeRegex = /^HF-\d{3}-\d{4}-\d{10}$/;
const nfcCode = /^[A-Z0-9]{16}$/;

export default function AddDevice() {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const { siteId, assetId, floorId } = useParams();
  const loading1 = useSelector((state) => state.doors.loading);
  const loading = useSelector((state) => state.sensors.loading);
  const loading2 = useSelector((state) => state.codes.loading);

  const [check, setCheck] = useState(true);

  const navigate = useNavigate();
  const [data, setData] = useState({
    site: null,
    floor: null,
    door: null,
  });
  const [qrCodeValid, setQrCodeValid] = useState(false);
  const [nfcCodeValid, setnfcCodeValid] = useState(false);
  const [form, setForm] = useState({
    qrCode: "",
    nfcCode: "",
    location: "",
    width: "",
    height: "",
    direction: "",
    top_left_gap: "",
    top_right_gap: "",
    bottom_left_gap: "",
    bottom_right_gap: "",
    right_top_gap: "",
    right_bottom_gap: "",
    left_top_gap: "",
    left_bottom_gap: "",
    rfidCode: "",
    doorId: "",
    sensor_code: "",
    org: "",
    site: "",
    floor: "",
    door: "",
  });

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    if (activeStep == 0) {
      if (check) {
        dispatch(
          addSensor({
            data: {
              sensor_code: form.sensor_code,
              organizationId: form.org,
              door: form.door,
              location: form.location,
              commission_flag: 0,
            },
          })
        ).then((res) => {
          if (!res.error) {
            const newActiveStep =
              isLastStep() && !allStepsCompleted()
                ? // steps.findIndex((step, i) => !(i in completed));
                  navigate("/")
                : activeStep + 1;
            setActiveStep(newActiveStep);
          }
        });
      } else {
        toast.error("This device is not registered in Harmony Fire");
      }
    }
   
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  // const handleComplete = () => {
  //   const newCompleted = completed;
  //   newCompleted[activeStep] = true;
  //   setCompleted(newCompleted);
  //   handleNext();
  // };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  useEffect(() => {
    if (form.site) {
      dispatch(getSite(form.site)).then((res) => {
        setData((prev) => ({
          ...prev,
          site: res.payload,
        }));
      });
    }
  }, [form.site]);

  useEffect(() => {
    if (form.floor) {
      dispatch(getFloor(form.floor)).then((res) => {
        setData((prev) => ({
          ...prev,
          floor: res.payload,
        }));
      });
    }
  }, [form.floor]);

  useEffect(() => {
    if (qrCodeRegex.test(form.qrCode)) {
      setQrCodeValid(true);
    } else {
      setQrCodeValid(false);
    }
  }, [form.qrCode]);

  useEffect(() => {
    if (nfcCode.test(form.nfcCode)) {
      setnfcCodeValid(true);
    } else {
      setnfcCodeValid(false);
    }
  }, [form.nfcCode]);

  useEffect(() => {
    if (form.door) {
      dispatch(getDoor(form.door)).then((res) => {
        setForm((prev) => ({
          ...prev,
          doorId: res.payload.description,
        }));
        setData((prev) => ({
          ...prev,
          door: res.payload,
        }));
      });
    }
  }, [form.door]);

  useEffect(() => {
    document.title = "Sensor Commissioning";
  }, []);

  return (
    <Box
      className="commossoining"
      sx={{
        display: "flex",
        justifyContent: "center",
        aligItems: "center",
        height: "100%",
        gap: "15px",
        padding: "0px 15px",
      }}
    >
      <Paper
        className="commissioningPaper"
        sx={{ width: "70%", height: "500px" }}
      >
        <div className="StepperTitle">
          <Stepper nonLinear activeStep={activeStep}>
            {steps?.map((label, index) => (
              <Step
                key={label}
                completed={completed[index]}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <StepButton
                  color="inherit"
                  onClick={handleStep(index)}
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <Headings>{label}</Headings>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="innerContainer">
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 && (
                <React.Fragment>
                  <GetSite data={data} form={form} setForm={setForm} setCheck= {setCheck} />
                </React.Fragment>
              )}

              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <button
                  className="button customButtomOverAll"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Back
                </button>
                <Box sx={{ flex: "1 1 auto" }} />
                <button
                  className="button customButtomOverAll"
                  onClick={handleNext}
                  disabled={form.door.length > 0 ? false : true}
                >
                  {loading ? <CircularProgress /> : "Add Sensor"}
                </button>
              </Box>
            </React.Fragment>
          )}
        </div>
      </Paper>
      <Paper
        className="detailPaper"
        sx={{
          width: "30%",
          height: "500px",
          padding: "15px 17px",
          boxSizing: "border-box",
        }}
      >
        <div className="detail">
          <Headings textTransform={"capitalize"}>
            {data?.site?.organization?.name}
          </Headings>
          <Headings>{data.site?.name}</Headings>
          <Headings>
            {form.floor.length > 0 && "Floor"} {data.floor?.description}
          </Headings>
          <Headings>{data.door?.description}</Headings>
        </div>
      </Paper>
    </Box>
  );
}

const StepperTitle = ({ label, Index, completed }) => {
  return (
    <div className="StepperTitle">
      <Stepper alternativeLabel activeStep={1} connector={<QontoConnector />}>
        {steps?.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
