import { createSlice } from '@reduxjs/toolkit';
import { postApi, getApi, deleteApi, putApi } from "../../Utils/serviceWorker";
import { createAsyncThunk } from '@reduxjs/toolkit';
import jsCookie from 'js-cookie';
import { toast } from 'react-toastify';


export const getCodes = createAsyncThunk(
  'device_codes/get',
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi("device_codes", true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCodesByQuery = createAsyncThunk(
    'device_codes_query/get',
    async (credentials, thunkAPI) => {
      try {
        const response = await getApi(`device_codes?${credentials}`, true);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );


export const getCode = createAsyncThunk(
  'device_code/get',
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`device_codes/${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addCode = createAsyncThunk(
  'device_code/add',
  async (credentials, thunkAPI) => {
    try {
      const response = await postApi(`device_codes`, true, credentials);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateCode = createAsyncThunk(
  'device_code/update',
  async (credentials, thunkAPI) => {
    try {
      const response = await putApi(`device_codes/${credentials.id}`, true, credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteCode = createAsyncThunk(
  'device_code/delete',
  async (credentials, thunkAPI) => {
    try {
      const response = await deleteApi(`device_codes/${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  value: [],

  loading: false,
  error: null
}

export const Codes = createSlice({
  name: 'Codes',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getCodes.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCodes.fulfilled, (state, action) => {
      state.loading = false;

      state.value = action.payload.rows;
    });
    builder.addCase(getCodes.rejected, (state, action) => {
      state.loading = false;

      state.error = action.payload;
    });
    builder.addCase(updateCode.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateCode.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Updated Device Codes Successfully");
    });
    builder.addCase(updateCode.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(addCode.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addCode.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Created Role Successfully");
    });
    builder.addCase(addCode.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getCode.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(getCode.fulfilled, (state, action) => {
        state.loading = false;
        
      });
      builder.addCase(getCode.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
      builder.addCase(getCodesByQuery.pending, (state) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(getCodesByQuery.fulfilled, (state, action) => {
        state.loading = false;
        
      });
      builder.addCase(getCodesByQuery.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
   
  },
});