// src/components/Globe.js
import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
// import globe_3d from "../../Assets/globe_3d.png";
import * as gio from "giojs";
import { Globedata } from "../../Utils/Functions/SampleData";

const Globe = () => {
  // use a React ref whenever you need to directly target a DOM element
  // - this is required as an argument to the GIO.Controller constructor
  const ref = useRef(null);
  const [country, setCountry] = useState("UK");

  useEffect(() => {
    // useEffect with empty dependency array
    // - this will run once when the component mounts

    const controller = new gio.Controller(ref.current, {
      control: {
        stats: false,
        disableUnmentioned: false,
        lightenMentioned: false,
        inOnly: false,
        outOnly: false,
        initCountry: "GB",
        halo: true,
        transparentBackground: true,
        autoRotation: true,
        rotationRatio: 1,
      },
      color: {
        surface: 7918335,
        selected: 1535970,
        "in": 16777215,
        "out": 2141154,
        "halo": 2141154,
        background: "#f1f7fa",
      },
      brightness: {
        ocean: 1,
        mentioned: 0.5,
        related: 0.5,
      },
    });

    // import and add data here if you want the glowing lines
    controller.addData(Globedata);

    controller.init();

    // here's the callback for when the user clicks a country
    // we can use the React setState hook inside the callback
    controller.onCountryPicked((country) => {
      setCountry(country.ISOCode);
    });
  }, []);

  return <div style={{ height: "98%", width: "98%" }} ref={ref}></div>;
};

export default Globe;
