import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { persistReducer } from 'redux-persist';
import { authSlice } from './Features/Auth';
import { Roles } from "./Features/Roles";
import { Users } from "./Features/Users";
import { Sites } from "./Features/Sites";
import { Sensors } from "./Features/Sensors";
import { Doors } from "./Features/Door";
import { Floors } from "./Features/Floors";
import { Notes } from "./Features/Notes";
import { Customers } from './Features/Customer';
import { Webhooks } from "./Features/Webhook";
import { Notifications } from "./Features/notifications";
import {Codes} from "./Features/device_codes"
import {Inspections} from "./Features/Inspection"
import storage from 'redux-persist/lib/storage';

const reducers =[
  authSlice,Roles,,Sites,Sensors,Doors,Floors,Notes,Customers,Webhooks,Codes,Inspections
]

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ["auth"]
};
const rootReducer = combineReducers({
  auth: authSlice.reducer,
  roles: Roles.reducer,
  users: Users.reducer,
  sites: Sites.reducer,
  sensors: Sensors.reducer,
  doors: Doors.reducer,
  floors: Floors.reducer,
  notes: Notes.reducer,
  customers: Customers.reducer,
  webhooks: Webhooks.reducer,
  notifications: Notifications.reducer,
  codes: Codes.reducer,
  inspection:Inspections.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer
});

export const resetStore = () => {
  return (dispatch, getState) => {

    reducers.map(item => {

      dispatch(item.actions.reset());
    });
  };
};

export const resetReduxStore = () => store.dispatch(resetStore());

export const persistor = persistStore(store);

export default store;