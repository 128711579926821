import { createSlice } from '@reduxjs/toolkit';
import { postApi, getApi, deleteApi, putApi, uploadService } from "../../Utils/serviceWorker";
import { createAsyncThunk } from '@reduxjs/toolkit';
import jsCookie from 'js-cookie';
import { toast } from 'react-toastify';

export const getUsers = createAsyncThunk(
  'users/get',
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`users`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getUser = createAsyncThunk(
  'user/get',
  async (credentials, thunkAPI) => {
    try {
      const response = await getApi(`users/${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addUser = createAsyncThunk(
  'user/add',
  async (credentials, thunkAPI) => {
    try {
      const response = await postApi(`users/`, true, credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  'user/update',
  async (credentials, thunkAPI) => {
    try {
      const response = await putApi(`users/${credentials.id}`, true, credentials);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  'role/User',
  async (credentials, thunkAPI) => {
    try {
      const response = await deleteApi(`users/${credentials}`, true);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const uploadAvatar = createAsyncThunk('user/avatar', async (credentials, thunkAPI) => {
  try {
    const response = await uploadService(`file/upload/users/avatar`, true, credentials);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getInstallationManager = createAsyncThunk('role/User', async (credentials, thunkAPI) => {
  try {
    const response = await getApi(`users?roleId=${process.env.REACT_APP_AGENT_ROLE_ID}`, true);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getOrganizationUsers = createAsyncThunk('role/User', async (credentials, thunkAPI) => {
  try {
    const response = await getApi(`users?organization=${credentials}`, true);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const initialState = {
  value: [],
  avatarLoading: false,
  loading: false,
  error: null
}


export const Users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.loading = false;

      state.value = action.payload.rows;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;


    });
    builder.addCase(getOrganizationUsers.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getOrganizationUsers.fulfilled, (state, action) => {
      state.loading = false;
    
      state.value = action.payload.rows;
    });
    builder.addCase(getOrganizationUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });



    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Updated User Successfully");
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(addUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Created User Successfully");
    });
    builder.addCase(addUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(uploadAvatar.pending, (state) => {
      state.avatarLoading = true;
      state.error = null;
    });
    builder.addCase(uploadAvatar.fulfilled, (state, action) => {
      state.avatarLoading = false;
      toast.success("Uploaded Image Successfully");
    });
    builder.addCase(uploadAvatar.rejected, (state, action) => {
      state.avatarLoading = false;
      state.error = action.payload;
    });



  },
});