import React, { useState, useEffect } from "react";
import { Headings, SubHeadings, Text } from '../../Shared/Fonts/Fonts';
import "./Forms.css";
import { Door } from "../Door/Door";

import Divider from '@mui/material/Divider';

export const GapCheck = ({ form, setForm, data }) => {

  return (
    <div className="SensorConnection">
      <div className="Content">
        <div className="SensorInfo" style={{ gap: 50 }}>
          <Door form={form} setForm={setForm} />
        </div>
      </div>
    </div>
  );
};


