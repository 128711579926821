import React, { useState, useEffect } from 'react';
import { Button, Modal, TextField } from '@mui/material';
import "./Modal.css";
import CircularProgress from "@mui/material/CircularProgress";
import { Headings } from '../../Shared/Fonts/Fonts';
import { uploadDoc, updateDoor, getDoor } from '../../Store/Features/Door';
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import {API_URL} from "../../Utils/Functions/utils";
const CustomModal = ({ isOpen, setIsOpen, id, error }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const { loading } = useSelector(state => state.doors.loading);

  const handleClose = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    if (id) {
      dispatch(getDoor(id)).then((res) => {
        setFormData({
          description: res.payload.description,
          width: res.payload.width,
          height: res.payload.height,
          length: res.payload.length,
          direction: res.payload.direction,
          floor: res.payload.floorId,
          assignee: res.payload.assigneeId,
          installation_certificate: res.payload.installation_certificate,
          manufacturer_certificate: res.payload.manufacturer_certificate,
          maintaince_records: res.payload.maintaince_records
        });
      });
    }
  }, [id]);
  const updateDoors = () => {
    dispatch(updateDoor({ id: id, data: formData })).then(res => {
      if (!res.error) {
        setIsOpen(false);
      }
    });
  };

  const handleDoc = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose} className="custom-modal">
        {loading ? <CircularProgress color="inherit" /> : (
          <div className="modal-content-door" >
            <div className="Button_group_custom">
              <div className="TitleDoor">
                <ImageUploadButton onChange={handleDoc} path1={"doors/installation_certificate"} title={"Install Certitificate"} />
                <Button
                  variant="contained"
                  color="info"
                  sx={{ display: formData?.installation_certificate?.length > 0 ? null : "none" }}
                  className="button customButtomOverAll buttonCustomModal"
                >
                  <a style={{ color: "inherit", textDecoration: "none" }} href={`${formData.installation_certificate?.length > 0 ? formData?.installation_certificate[0].publicUrl : null}`} target="_blank" download>
                    Download
                  </a>
                </Button>
              </div>
              <div className="TitleDoor">
                <ImageUploadButton1 onChange={handleDoc} path1={"doors/manufacturer_certificate"} title={"Manufacture Certitificate"} />
                <Button
                  variant="contained"
                  color="info"
                  sx={{ display: formData?.manufacturer_certificate?.length > 0 ? null : "none" }}
                  className="button customButtomOverAll buttonCustomModal"



                >
                  <a style={{ color: "inherit", textDecoration: "none" }} href={`${formData.manufacturer_certificate?.length > 0 ? formData?.manufacturer_certificate[0].publicUrl : null}`} target="_blank" download>
                    Download
                  </a>

                </Button>
              </div>
              <div className="TitleDoor">


                <ImageUploadButton2 onChange={handleDoc} path1={"doors/maintaince_records"} title={"Maintainance Records"} />
                <Button
                  variant="contained"
                  color="info"
                  sx={{ display: formData?.maintaince_records?.length > 0 ? null : "none" }}
                  className="button customButtomOverAll buttonCustomModal"


                >
                  <a style={{ color: "inherit", textDecoration: "none" }} href={`${formData.maintaince_records?.length > 0 ? formData?.maintaince_records[0].publicUrl : null}`} target="_blank" download>
                    Download
                  </a>
                </Button>
              </div>
            </div>
            <label htmlFor="image-button">
              <Button
                variant="outlined"
                component="span"
                fullWidth
                onClick={updateDoors}
              >
                Update Door Assets
              </Button>
            </label>
            <Headings color={"red"}>{error}</Headings>
          </div>
        )}

      </Modal>
    </div>
  );
};

export default CustomModal;
const ImageUploadButton = ({ onChange, path1, title }) => {
  const dispatch = useDispatch();

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    const extension = extractExtensionFrom(selectedFile?.name);
    const id = uuidv4();
    const filename = `${id}.${extension}`;
    const privateUrl = `${path1}/${filename}`;
    const uri = `/file/upload/${path1}`;
    const publicUrl = API_URL()+`file/download?privateUrl=${privateUrl}`;
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("filename", filename);
    
    dispatch(uploadDoc({ path: path1, data: formData })).then((res) => {
      onChange(path1.split("/")[1], {
        id: id,
        name: selectedFile.name,
        sizeInBytes: selectedFile.size,
        privateUrl,
        publicUrl,
        new: true,
      });
    });
  };

  // const handleUpload = () => {
  //   // You can implement the image upload logic here
  //   if (selectedImage) {
  //     // Example: Send the selectedImage to your backend for processing
  //     console.log('Uploading image:', selectedImage.name);
  //   }
  // };

  return (
    <div>
      <input
        accept=".xlsx,.xls,.doc, .docx,.ppt,.pptx,.pdf"
        style={{ display: "none" }}
        id="image-upload-input"
        type="file"
        onChange={handleImageChange}
      />
      <label htmlFor="image-upload-input">
        <Button
          type="button"
          className="button customButtomOverAll buttonCustomModal"
          variant="contained"
          component="span"
          sx={{ textTransform: "capitalize" }}
        >
          <Headings textTransform={"capitalize"} color={"white"}>
            {title}
          </Headings>
        </Button>
      </label>
    </div>
  );
};
const ImageUploadButton1 = ({ onChange, name, path1, title }) => {
  const dispatch = useDispatch();

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    const extension = extractExtensionFrom(selectedFile?.name);
    const id = uuidv4();
    const filename = `${id}.${extension}`;
    const privateUrl = `${path1}/${filename}`;
    const uri = `/file/upload/${path1}`;
    const publicUrl = API_URL()+`file/download?privateUrl=${privateUrl}`;
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("filename", filename);
    console.log("path", path1);
    dispatch(uploadDoc({ path: path1, data: formData })).then((res) => {
      onChange(path1.split("/")[1], {
        id: id,
        name: selectedFile.name,
        sizeInBytes: selectedFile.size,
        privateUrl,
        publicUrl,
        new: true,
      });
    });
  };

  // const handleUpload = () => {
  //   // You can implement the image upload logic here
  //   if (selectedImage) {
  //     // Example: Send the selectedImage to your backend for processing
  //     console.log('Uploading image:', selectedImage.name);
  //   }
  // };

  return (
    <div>
      <input
        accept=".xlsx,.xls,.doc, .docx,.ppt,.pptx,.pdf"
        style={{ display: "none" }}
        id="image-upload-input1"
        type="file"
        onChange={handleImageChange}
      />
      <label htmlFor="image-upload-input1">
        <Button
          type="button"
          className="button customButtomOverAll buttonCustomModal"
          variant="contained"
          component="span"
          sx={{ textTransform: "capitalize" }}
        >
          <Headings textTransform={"capitalize"} color={"white"}>
            {title}
          </Headings>
        </Button>
      </label>
    </div>
  );
};
const ImageUploadButton2 = ({ onChange, name, path1, title }) => {
  const dispatch = useDispatch();

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    const extension = extractExtensionFrom(selectedFile?.name);
    const id = uuidv4();
    const filename = `${id}.${extension}`;
    const privateUrl = `${path1}/${filename}`;
    const uri = `/file/upload/${path1}`;
    const publicUrl = API_URL()+`file/download?privateUrl=${privateUrl}`;
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("filename", filename);
    console.log("path", path1);
    dispatch(uploadDoc({ path: path1, data: formData })).then((res) => {
      onChange(path1.split("/")[1], {
        id: id,
        name: selectedFile.name,
        sizeInBytes: selectedFile.size,
        privateUrl,
        publicUrl,
        new: true,
      });
    });
  };

  // const handleUpload = () => {
  //   // You can implement the image upload logic here
  //   if (selectedImage) {
  //     // Example: Send the selectedImage to your backend for processing
  //     console.log('Uploading image:', selectedImage.name);
  //   }
  // };

  return (
    <div>
      <input
        accept=".xlsx,.xls,.doc, .docx,.ppt,.pptx,.pdf"
        style={{ display: "none" }}
        id="image-upload-input2"
        type="file"
        onChange={handleImageChange}
      />
      <label htmlFor="image-upload-input2">
        <Button
          type="button"
          className="button customButtomOverAll buttonCustomModal"
          variant="contained"
          component="span"
          sx={{ textTransform: "capitalize" }}
        >
          <Headings textTransform={"capitalize"} color={"white"}>
            {title}
          </Headings>
        </Button>
      </label>
    </div>
  );
};

function extractExtensionFrom(filename) {
  if (!filename) {
    return null;
  }

  const regex = /(?:\.([^.]+))?$/;
  return regex.exec(filename)[1];
}