import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TextField, Button, Grid, MenuItem } from "@mui/material";
import { Headings } from "../../Shared/Fonts/Fonts";
import { v4 as uuidv4 } from "uuid";

import {

  getCodes,
 
  deleteCode,
  getCode,
  updateCode,
  addCode,
} from "../../Store/Features/device_codes"
import { getSites } from "../../Store/Features/Sites";
import { useDispatch, useSelector } from "react-redux";
import { getFloorsOfSite, getFloor } from "../../Store/Features/Floors";
import { IconButton } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { getUsers } from "../../Store/Features/Users";
import { Select } from "@material-ui/core";
import {getOrganization} from "../../Store/Features/Customer"

const RegisteredDevicesPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.codes.loading);
  const sites = useSelector((state) => state.sites.value);
  const { value } = useSelector((state) => state.users);
  const [installationManagers, setInstallationManagers] = useState([]);
  const [site, setSite] = useState(null);
  const [floors, setFloors] = useState([]);
  const [orgName,setOrgName] = useState()
  const [doors, setDoors] = useState([]);
  const navigate = useNavigate();
  const { deviceCodeId } = useParams();
  const isEdit = deviceCodeId !== undefined;
  const [formData, setFormData] = useState({
   nfc_code: "",
   qr_code: "",
   device_id: ""
  });
  // useEffect(() => {
  //   dispatch(getUsers());
  // }, []);
  // useEffect(() => {
  //   if (value != undefined) {
  //     if (value.length > 0) {
  //       setInstallationManagers(() => {
  //         console.log("value", value);
  //         return value.filter((ele) => {
  //           return ele.roleId == process.env.REACT_APP_AGENT_ROLE_ID;
  //         });
  //       });
  //     }
  //   }
  // }, [value]);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    document.title = deviceCodeId ? "Edit Register Code" : "Add Register Code";
  }, []);

  const validateForm = () => {
    const qrCodeRegex = /^HF-\d{3}-\d{4}-\d{10}$/;
    const nfcCode = /^[A-Z0-9]{16}$/;
    const euiPattern = /^eui-1e[0-9a-z]{0,18}$/i;

    const newErrors = {};
    let valid = true;
  
  
    // formData.floor.length == 0
    if (formData.qr_code == null || formData.qr_code.length == 0) {
      newErrors.qr_code = "Qr Code is required";
      valid = false;
    }

  if(!formData.qr_code.match(qrCodeRegex)) {
      newErrors.qr_code = "Please Enter Valid Qr code for Door,e.g. HF-XXX-XXXX-XXXXXXXXXX";
      valid = false;
    };
    // if (formData.nfc_code == null || formData.nfc_code.length == 0) {
    //   newErrors.nfc_code = "Nfc Code is required";
    //   valid = false;
    // }

    // if(!formData.nfc_code.match(nfcCode)) {
    //   newErrors.nfc_code = "Please Enter Valid Nfc Code for Door,e.g. DDXXXXXXXXXXXXXX";
    //   valid = false;
    // };
    if (formData.device_id == null || formData.device_id.length == 0) {
      newErrors.device_id = "Device Eui is required";
      valid = false;
    }

    if(!formData.device_id.match(euiPattern)) {
      newErrors.device_id = "Please Enter Valid Device Eui for Door,e.g. eui-1eXXXXXXXXXXX";
      valid = false;
    };


    // if (!formData.width.trim()) {
    //   newErrors.name = 'Width is required';
    //   valid = false;
    // }
    // if (!formData.length.trim()) {
    //   newErrors.name = 'Name is required';
    //   valid = false;
    // }
    // if (!formData.height.trim()) {
    //   newErrors.name = 'Name is required';
    //   valid = false;
    // }
    // if (!formData.top_gap.trim()) {
    //   newErrors.name = 'Name is required';
    //   valid = false;
    // }
    // if (!formData.bottom_gap.trim()) {
    //   newErrors.name = 'Name is required';
    //   valid = false;
    // }
    // if (!formData.left_gap.trim()) {
    //   newErrors.name = 'Name is required';
    //   valid = false;
    // }
    // if (!formData.right_gap.trim()) {
    //   newErrors.name = 'Name is required';
    //   valid = false;
    // }
    // if (!formData.direction.trim()) {
    //   newErrors.name = 'Name is required';
    //   valid = false;
    // }
  
    
    setErrors(newErrors);
    return valid;
  };

  useEffect(() => {
    // If it's an edit operation, you can fetch user data using 'slug' and set it in the form.
    if (isEdit) {
      dispatch(getCode(deviceCodeId)).then((res) => {
        setFormData({
          nfc_code: res.payload.nfc_code,
          qr_code: res.payload.qr_code,
          device_id: res.payload.device_id,
        });
      });
    }
  }, [isEdit]);
  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    if (isEdit) {
      dispatch(updateCode({ id: deviceCodeId, data: formData })).then((res) => {
        if (!res.error) {
          navigate("/registeredDevices");
        }
      });
    } else {
      dispatch(addCode({ data: formData })).then((res) => {
  
        if (!res.error) {
          navigate("/registeredDevices");
        }
      });
    }
  };


  return (
    <div className="OverAllSites">
      <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
        <IconButton onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon />
        </IconButton>
        <Headings fontSize={24}>{isEdit ? "Edit Codes" : "Add Codes"}</Headings>
      </div>
      <div className="Addsitetabs">
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                label="Qr Code"
                variant="filled"
                fullWidth
                name="qr_code"
                value={formData.qr_code}
                onChange={handleChange}
              
                error={!!errors.qr_code}
                helperText={errors.qr_code}
              />
            </Grid>

          
           

           <Grid item xs={6}>
            <TextField
              label="NFC Code"
              variant="filled"
              fullWidth
              name="nfc_code"
              value={formData.nfc_code}
              onChange={handleChange}
              error={!!errors.nfc_code}
              helperText={errors.nfc_code}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Device EUI"
              variant="filled"
              fullWidth
              name="device_id"
              value={formData.device_id}
              onChange={handleChange}
              error={!!errors.device_id}
              helperText={errors.device_id}
            />
          </Grid>
         
            <Grid item xs={12}>
              <Button className="customButtomOverAll" disabled={loading} type="submit" variant="contained" color="primary">
                {loading ? (
                  <CircularProgress color="inherit" />
                ) : isEdit ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default RegisteredDevicesPage;

