import React, { useEffect, useRef, useState } from "react";
import "./Asset.css";

import { useParams, useNavigate } from "react-router-dom";
import { Paper } from "@mui/material";

import { IconButton } from "@mui/material";
import { Box } from "@mui/material";
import Select from "../../Shared/CustomElement/DropDown";
import { Headings } from "../../Shared/Fonts/Fonts";
import JsonFormatter from "react-json-formatter";
import { Button } from "../../Shared/CustomElement/Button";
import { AssetSidePanel } from "../../Components/Drawers/AssetSidePanel";
import { getSensorsbyDoor } from "../../Store/Features/Sensors";
import { useDispatch } from "react-redux";
import { getDoor } from "../../Store/Features/Door";
import { getFloor } from "../../Store/Features/Floors";
import { useSelector } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { getSites } from "../../Store/Features/Sites";
import { getDoorsofFloor } from "../../Store/Features/Door";
import CircularProgress from "@mui/material/CircularProgress";
import { Select as MuiSelect, MenuItem } from "@mui/material";
import { getWebhooks } from "../../Store/Features/Webhook";
import {
  getStatusMessage,
  getBatteryStatus,
  getStatusMessages,
  getAllStatuses
} from "../../Utils/Functions/getStatusMessages";
import SensorsIcon from '@mui/icons-material/Sensors';
import useMediaQuery from "@mui/material/useMediaQuery";
import Accordion from "@mui/material/Accordion";
import DynamicLineChart from "../../Components/Charts/AllTimeChart";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AdbIcon from "@mui/icons-material/Adb";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";
import { DoorCard } from "../../Components/Door/DoorCards";
import Modal from "@mui/material/Modal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DoorCardTimeOut } from "../../Components/Door/DoorCardTimeOut";
import { DoorCardUtil } from "../../Components/Door/DoorCardUitl";
import DynamicLineChartWeek from '../../Components/Charts/WeekChart'
import { formatDateToGB } from "../../Utils/Functions/utils";
export const Asset = () => {
  const navigate = useNavigate();
  const { siteId, floorId, assetId } = useParams();
  const matches = useMediaQuery("(max-width:769px)");
  const { value } = useSelector((state) => state.sites);
  const path = useParams().pathName;
  const dispatch = useDispatch();
  const [sensors, setSensors] = useState([]);
  const [door, setDoor] = useState(assetId);
  const [floor, setFloor] = useState({});
  const [doors, setDoors] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [doorAllData, setDoorAllData] = useState([]);
  const [doorWeekData,setDoorWeekData] = useState([]);
 
  
  const now = new Date();
const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
const oneWeekAgoISO = oneWeekAgo.toISOString();
  
  const onChangeSite = (e) => {
    if(e != 0) {
      navigate(`/sites/${siteId}/floor/${floorId}/asset/${e}`);
      window.location.reload(false);
    }
   
  };

  // const currentURL = window.location.href;
  // const urlSegments = currentURL.split('/');
  // const idFromURL = urlSegments[urlSegments.length - 1];
  // let canvasData = JSON.parse(localStorage.getItem('canvas'));
  // console.log(canvasData)
  // const indexToRemove = canvasData.objects.findIndex(obj => obj.asset.id === idFromURL);
  // canvasData.objects.splice(indexToRemove, 1);
  // console.log('removed', canvasData)

  // const handleDeleteMarker = () => {
  //   const currentURL = window.location.href;
  //   const urlSegments = currentURL.split("/");
  //   const idFromURL = urlSegments[urlSegments.length - 1];
  //   let canvasData = JSON.parse(localStorage.getItem("canvas"));
  //   const indexToRemove = canvasData.objects.findIndex(
  //     (obj) => obj.asset?.id === idFromURL
  //   );
  //   canvasData.objects.splice(indexToRemove, 1);
  //   localStorage.setItem("canvas", JSON.stringify(canvasData));
  //   navigate(`/sites/${siteId}/floor/${floorId}`);
  //   console.log("Selected Marker Removed", canvasData);
  // };

  useEffect(() => {
    dispatch(getSites());
  }, []);

  useEffect(() => {
    document.title = "Door";
  }, []);

  useEffect(() => {
    dispatch(getFloor(floorId)).then((res) => {
      setFloor(res.payload);
    });
  }, []);
  useEffect(() => {
    dispatch(getDoor(assetId)).then((res) => {
      setDoor(res.payload);
    });
  }, []);

  useEffect(() => {
    dispatch(getDoorsofFloor(floorId)).then((res) => {
      setDoors(res.payload.data);
    });
  }, []);
  useEffect(() => {
    dispatch(getSensorsbyDoor(assetId)).then((res) => {
      let temp = res.payload.rows;
      const sorted = temp?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      setSensors(sorted);
    });
  }, []);

  useEffect(() => {
    if (sensors?.length > 0) {
      let query = "Sensor=";
      let modified = sensors.map((ele) => ele.sensor_code).join("|");

      dispatch(getWebhooks(`${query}${modified}&remove=1`)).then((res) => {
        setDoorAllData(res.payload.rows);
      });

      dispatch(getWebhooks(`${query}${modified}&createdAtRange=${oneWeekAgoISO}&createdAtRange=&remove=1`)).then(res => {
        setDoorWeekData(res.payload.rows)
      })
    }
  }, [sensors]);



  return (
    <div className="AssetPage">
      <div className="StatusPart">
        <div className="navBar">
          <div className="navBar w-100 .p-h-10 bs">
            <div className="TitleBack">
              <IconButton onClick={() => navigate(-1)}>
                <KeyboardBackspaceIcon />
              </IconButton>
              <Headings fontSize={18}>
                {value.find((ele) => ele.data.id == siteId)?.data.name}
              </Headings>
            </div>
            <div className="nav-end">
              <Select
                options={doors}
                onChange={onChangeSite}
                value={assetId}
                title={"Doors"}
                area={"doors"}
              />
            </div>
          </div>
          {/* <div className="ButtonsGorupAsset"> */}
          {/* <button className="deleteButton" onClick={handleDeleteMarker}>Delete Door</button> */}
          {/* <button className="deleteButton" onClick={() => navigate(`/sites/${siteId}/floor/${floorId}/asset/${assetId}/sensors/addSensor`)}>Add Sensor</button> */}
          {/* </div> */}
        </div>

        <div className="Documents p-v-10">
          <div className="options">
            <Button
              color={"#105998"}
              title={"Manufacture Certificate"}
              hide={door?.manufacturer_certificate?.length > 0 ? false : true}
              href={`${
                door?.manufacturer_certificate?.length > 0
                  ? door?.manufacturer_certificate[0].publicUrl
                  : null
              }`}
            />
            <Button
              color={"#105998"}
              title={"Install Certificate"}
              hide={door?.installation_certificate?.length > 0 ? false : true}
              href={`${
                door?.installation_certificate?.length > 0
                  ? door?.installation_certificate[0].publicUrl
                  : null
              }`}
            />
            <Button
              color={"#105998"}
              title={"Maintenance Records"}
              hide={door?.maintaince_records?.length > 0 ? false : true}
              href={`${
                door.maintaince_records?.length > 0
                  ? door?.maintaince_records[0].publicUrl
                  : null
              }`}
            />
          </div>
        </div>
        {matches ? (
          <div style={{ width: "100%" }}>
            <Accordion
              sx={{
                background: "transparent",
                borderBottom: "1px solid black",
              }}
              elevation={0}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ width: "100%" }}
              >
                <Headings>Status Report</Headings>
              </AccordionSummary>
              <AccordionDetails>
                <div className="status-content">
                  <DoorCard {...door} />
                  <DoorCardTimeOut {...door} />
                  <DoorCardUtil {...door} />
                </div>
              </AccordionDetails>
            </Accordion>
            {sensors?.map((sensor) => {
              return (
                <Accordion
                  sx={{
                    background: "transparent",
                    borderBottom: "1px solid black",
                  }}
                  elevation={0}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    sx={{ width: "100%" }}
                  >
                    <Headings>
                      {`${sensor.location} Sensors`} {sensor.sensor_code}
                    </Headings>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TopSensor {...sensor} />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        ) : (
          <div className="status">
            <Headings fontSize={18} fontWeight={800}>
              Status Report
            </Headings>
            <div className="status-content">
              <DoorCard {...door} />
              <DoorCardTimeOut {...door} />
              <div
                style={{ display: "flex", flexDirection: "column", gap: 18 }}
              >
                <DoorCardUtil
                  title={"Last Inspection"}
                  time={door?.last}
                />
                <DoorCardUtil
                  title={"Upcoming Inspection"}
                  time={door?.due}
                />
              </div>
            </div>
            <div style = {{display:"flex",gap: "20px"}}>
           
              <Paper sx = {{display: "flex",flexDirection:"column", height: 300,padding: "15px",width: "25%"}}>
              <div style = {{display:"flex",justifyContent:"space-between"}}>
                <Headings>Door Utilisation</Headings>
                <Headings color = "#407AAD">Weekly</Headings>
              </div>

              < DynamicLineChartWeek
                data={doorWeekData}
                chartTitle="Dynamic Line Chart"
                xLabel="Date"
                yLabel="Value"
              />
              </Paper>
              <Paper sx = {{display: "flex",flexDirection:"column", height: 300,padding: "15px",width: "60%"}}>
              <div style = {{display:"flex",justifyContent:"space-between"}}>
                <Headings>Door Utilisation</Headings>
                <Headings color = "#407AAD">Lifetime</Headings>
              </div>

              <DynamicLineChart
                data={doorAllData}
                chartTitle="Dynamic Line Chart"
                xLabel="Date"
                yLabel="Value"
              />
              </Paper>
             
            </div>
            <div className="sensor-content">
              {sensors?.map((sensor) => {
                return <TopSensor {...sensor} />;
              })}
            </div>
          </div>
        )}
      </div>
      <AssetSidePanel
        siteName={floor?.site?.name}
        assetName={door?.description}
        floorNumber={floor?.floor_number}
        SitesLocation={
          floor?.site?.address
        }
        image={
          door.door_image
            ? door.door_image?.length > 0
              ? door.door_image[0].publicUrl
              : null
            : null
        }
        sensors = {sensors}
      />
    </div>
  );
};






const TopSensor = (props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { siteId, floorId, assetId } = useParams();
  const { status, location, sensor_code, id, complianceDate, complianceDue,qr_code,allStatus } =
    props;
  const { user } = useSelector((state) => state.auth);
  const [code, setCode] = useState();
  const [data,setData] = useState();
  const [loading,setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      setLoading(true)
      dispatch(
        getWebhooks(`limit=1&Sensor=${sensor_code}&field=createdAt&sort=desc`)
      ).then((res) => {
        if (!res.error) {
          if (res.payload.rows?.length > 0) {
            setData(res.payload.rows[0]);
          }
          setLoading(false)
        }
      });
    }
  }, [id]);


  const matches = useMediaQuery("(max-width:769px)");

  return (
    <>
      <div
        className="reportCards"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          width: "31%",
        }}
      >
        <div style={{ display: "flex", gap: 10 }}>
          {!matches && (
            <>
              <Headings
                textTransform="capitalize"
                fontSize={16}
                fontWeight={500}
              >
                {location} Sensor
              </Headings>
              <Headings
                textTransform="capitalize"
                fontSize={16}
                fontWeight={800}
                color={"#637381"}
              >
                {qr_code}
              </Headings>
            </>
          )}
        </div>
        <Paper
          sx={{
            padding: "25px 32px 25px 32px",
            border: "1px solid rgba(238, 238, 238, 1)",
            gap: "15px",
            flexDirection: "column",
            display: "flex",
            // width: "100%",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between",flexWrap:"wrap" }}>
            <Tooltip title={`${getAllStatuses(allStatus)}`}>
              <div className="DoorReport-Upper">
                <div
                  style={{
                    width: 7.03,
                    height: 7.03,
                    backgroundColor: getStatus(status).color,
                    borderRadius: "50%",
                  }}
                />
                <Headings
                  fontSize={16}
                  color="rgba(99, 115, 129, 1)"
                  fontWeigth={450}
                >
                  Report
                </Headings>
                <Headings
                  textTransform="capitalize"
                  fontSize={16}
                  color="rgba(33, 43, 54, 1)"
                  fontWeigth={450}
                >
                  {getStatusMessage(status)}
                </Headings>
              </div>
            </Tooltip>
            {user?.roleId == process.env.REACT_APP_ADMIN_ROLE_ID && (
              <div style = {{display:"flex",gap: 10}}>
                <IconButton
                onClick={
                  () => {
                    setIsOpen(!isOpen);
                    setCode(sensor_code);
                  }
                  // navigate(
                  //   `/sites/${siteId}/floor/${floorId}/asset/${assetId}/device/${sensor_code}`
                  // )
                }
              >
                <AdbIcon />
              </IconButton>

              <IconButton
                onClick={
                  () => {
                    navigate(`/sites/${siteId}/floor/${floorId}/asset/${assetId}/device/${sensor_code}`)
                  }
                 
                  
                }
              >
                <SensorsIcon />
              </IconButton>
              </div>
              
            )}
          </div>
          <div className="DoorReport-Upper">
            <div
              style={{
                width: 7.03,
                height: 7.03,
                backgroundColor: getBatteryStatus(status).color,
                borderRadius: "50%",
              }}
            />
            <Headings
              fontSize={16}
              color="rgba(99, 115, 129, 1)"
              fontWeigth={450}
            >
              Battery
            </Headings>
            <Headings
              fontSize={16}
              color="rgba(33, 43, 54, 1)"
              fontWeigth={450}
            >
              {getBatteryStatus(status).message}
            </Headings>
          </div>
          <div className="DoorReport-down">
            <Headings
              fontSize={16}
              color="rgba(99, 115, 129, 1)"
              fontWeigth={450}
            >
              Reports
            </Headings>
            <div className="DoorReport-Upper">
              <Headings
                fontSize={16}
                color="rgba(99, 115, 129, 1)"
                fontWeigth={450}
              >
                Last
              </Headings>
              <Headings
                fontSize={16}
                color="rgba(33, 43, 54, 1)"
                fontWeigth={450}
              >
                {!loading ?  data?.createdAt ? `${data?.createdAt?.split("T")[1]?.split(":")[0] || null}:${data?.createdAt?.split("T")[1]?.split(":")[1] || null} - ${formatDateToGB(data?.createdAt?.split("T")[0]) || ""}` : status == 420 ? getAllStatuses(status) : "N/A": "N/A"}
              </Headings>
            </div>
            <div className="DoorReport-Upper">
              <Headings
                fontSize={16}
                color="rgba(99, 115, 129, 1)"
                fontWeigth={450}
              >
                Next
              </Headings>
              <Headings
                fontSize={16}
                color="rgba(33, 43, 54, 1)"
                fontWeigth={450}
              >
                {!loading ? status == 420 ? getAllStatuses(status) : data?.expectedTime ? `${data?.expectedTime?.split("T")[1]?.split(":")[0] || null}:${data?.expectedTime?.split("T")[1]?.split(":")[1] || null} - ${formatDateToGB(data?.expectedTime?.split("T")[0]) || ""}` : "N/A" : "N/A"}
              </Headings>
            </div>
          </div>
        </Paper>
      </div>
      <BasicModal open={isOpen} setOpen={setIsOpen} id={code} />
      {/* <NotesModal /> */}
    </>
  );
};

const getStatus = (stat) => {
  let status = parseInt(stat);
  if (status >= 200 && status < 300) {
    return {
      color: "#34A853",
      background: "rgba(52, 168, 83, 0.1)",
    };
  } else if (status >= 300 && status < 400) {
    return {
      color: "rgba(221, 173, 1, 1)",
      background: "rgba(221, 173, 1, 0.1)",
    };
  } else if (status >= 400 && status < 500) {
    return {
      color: "#EB4335",
    };
  } else {
    return {
      color: "#34A853",
      background: "rgba(52, 168, 83, 0.1)",
    };
  }
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: 400,
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

function BasicModal({ open, setOpen, id }) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { value, loading } = useSelector((state) => state.webhooks);
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(
        getWebhooks(`limit=1&Sensor=${id}&field=createdAt&sort=desc`)
      ).then((res) => {
        if (!res.error) {
          if (res.payload.rows?.length > 0) {
            setData(res.payload.rows[0]);
          }
        }
      });
    }
  }, [id]);

  const jsonStyle = {
    propertyStyle: { color: "red" },
    stringStyle: { color: "green" },
    numberStyle: { color: "darkorange" },
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {loading ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <CircularProgress />
            </Box>
          ) : data != {} ? (
            <JsonFormatter json={data} tabWith={4} jsonStyle={jsonStyle} />
          ) : (
            "No Data available"
          )}
        </Box>
      </Modal>
    </div>
  );
}
