import React, { useState, useEffect } from "react";

// import {useDispatch,useSelector} from "react-redux"
import { SiteDrawer } from "../../Components/Drawers/SiteDrawer";
import { Container } from "@mui/material";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import { Table } from "../../Components/Table/Tables";
import { useNavigate } from "react-router-dom";
import "./Sites.css";
import { getStatusMessage } from "../../Utils/Functions/getStatusMessages";
import { Button, Menu, MenuItem, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useMediaQuery from "@mui/material/useMediaQuery";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import search from "../../Assets/search.svg";
import Edit from "../../Assets/Edit.svg";
import AddressToArea from "../../Utils/Functions/getLocations"
import {
  getSites,
  getSensorsOfSite,
  getDoorsOfSite,
  deleteSite,
  getSitesOfUser,
  getSitesOfInstalltion,
  getSitesOfOrganization,
} from "../../Store/Features/Sites";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getCustomerOfUser } from "../../Store/Features/Customer";
import { getDoorsOfInstalltion } from "../../Store/Features/Door";

import { useDispatch, useSelector } from "react-redux";

export const Sites = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const sites = useSelector((state) => state.sites.value);
  const loading = useSelector((state) => state.sites.loading);
  const [sensors, setSensors] = useState([]);
  const matches = useMediaQuery("(max-width:769px)");
  const [doors, setDoors] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [customer, setCustomer] = useState();

  const handleRowClick = (e) => {
    navigate(`/sites/${e.id}`);
  };
  // Function to handle changes in the search input
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter the sites array based on the search query
  const filteredSites = sites?.length > 0 && sites?.filter((site) => {
    // Iterate through each field in the site object
    let each = false;
    for (const k  in site) {
      if(site.hasOwnProperty(k)) {
        if(k == "organization" || k == "doorsCount" || k == "floorsCount" || k == "sensorsCount") {
          const value = site[k] + "";
        
          // Check if the field value contains the search query (case-insensitive)
          if (
            value &&
            value.toString().toLowerCase().match(searchQuery.toLowerCase())
          ) {
            each = true; // Return true if a match is found
          }
        } else if (!searchQuery) {
          const value = site[k] + "";
        
          // Check if the field value contains the search query (case-insensitive)
          if (
            value &&
            value.toString().toLowerCase().match(searchQuery.toLowerCase())
          ) {
            each = true; // Return true if a match is found
          }
        }
      }
    }
    for (const key in site.data) {
      if (site.data.hasOwnProperty(key)) {
        
        // address sector data status
        if (
          key == "address" ||
          key == "sector" ||
          key == "noOfSensors" ||
          key == "name" ||
          key == "noOfDoors"
        ) {
          // .noOfSensors
          const value = site.data[key] + "";
         
          // Check if the field value contains the search query (case-insensitive)
          if (
            value &&
            value.toString().toLowerCase().match(searchQuery.toLowerCase())
          ) {
            each = true; // Return true if a match is found
          }
        } else if (!searchQuery) {
          const value = site.data[key] + "";
          
          // Check if the field value contains the search query (case-insensitive)
          if (
            value &&
            value.toString().toLowerCase().match(searchQuery.toLowerCase())
          ) {
            each = true; // Return true if a match is found
          }
        }
      }
    }
    return each;
  });

  useEffect(() => {
    if (user != null) {
   
        dispatch(getSites());
      
    }
  }, [user]);

  useEffect(() => {
    document.title = "Sites";
  }, []);

  // useEffect(() => {
  //   if (sites.length > 0) {
  //     sites.map((site) => {
  //       dispatch(getSensorsOfSite(site.data.id));
  //       dispatch(getDoorsOfSite(site.data.id));
  //     });
  //   }
  // }, [loading]);

  // useEffect(() => {
  //   if (user?.roleId == process.env.REACT_APP_USER_ROLE_ID || user.roles.id == process.env.REACT_APP_ORG_ADMIN_ROLE_ID) {
  //     if (user != null) {
  //       dispatch(getCustomerOfUser(user?.id)).then((res) => {
  //         setCustomer(res.payload?.rows[0].id);
  //       });
  //     }
  //   }
  // }, [user]);

  const handleDelete = (id) => {
    dispatch(deleteSite(id)).then(() => {
      dispatch(getSites());
    });

    // Implement delete functionality here
  };
  const columns = [
    {
      field: "organization",
      headerName: "Organization",
      flex: 1,
     
      // renderCell: (params) => {
      //   return (
      //     <Headings
      //       fontSize={16}
      //       color={"#212B36"}
      //       fontFamily="Futura PT Book"
      //       fontWeight={400}
      //       textTranformation="capitalize"
      //     >
      //       {/* {params.row.data.address.split(",")[3]} */}
      //       {AddressToArea(params.row.organization)}
      //     </Headings>
      //   );
      // },
    
    },
    {
      field: "data",
      headerName: "Site Name",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <Headings
            fontSize={16}
            color={"#212B36"}
            fontFamily="Futura PT Book"
            fontWeight={400}
            textTranformation="capitalize"
          >
            {params.row.data.name}
          </Headings>
        );
      },

      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    },
   
    {
      field: "location",
      headerName: "Location",
      flex: 1,

      renderCell: (params) => {
        return (
          <Headings
            fontSize={16}
            color={"#212B36"}
            fontFamily="Futura PT Book"
            fontWeight={400}
            textTranformation="capitalize"
          >
            {/* {params.row.data.address.split(",")[3]} */}
            {AddressToArea(params.row.data.address)}
          </Headings>
        );
      },
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: "sector",
      headerName: "Building Type",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Headings
          fontSize={16}
          color={"#212B36"}
          fontFamily="Futura PT Book"
          fontWeight={400}
          textTranformation="capitalize"
        >
          {" "}
          {params.row.data.sector}
        </Headings>
      ),
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: "sensorsCount",
      headerName: "No of Devices",
      flex: 1,
      renderCell: (params) => (
        <Headings
          fontSize={16}
          color={"#212B36"}
          fontFamily="Futura PT Book"
          fontWeight={200}
          textTranformation="capitalize"
        >
          {params.row.sensorsCount}
        </Headings>
      ),
      // sortComparator: (v1, v2) => console.log("noOfDoors", v1.noOfDoors.localeCompare(""))
    },
    {
      field: "floorsCount",
      headerName: "No of Floors",
      flex: 1,
      renderCell: (params) => (
        <Headings
          fontSize={16}
          color={"#212B36"}
          fontFamily="Futura PT Book"
          fontWeight={200}
          textTranformation="capitalize"
        >
          {params.row.floorsCount}
        </Headings>
      ),
      // sortComparator: (v1, v2) => console.log("noOfDoors", v1.noOfDoors.localeCompare(""))
    },

    
    {
      field: "doorsCount",
      headerName: "No of Doors",
      flex: 1,
      renderCell: (params) => (
        <Headings
          fontSize={16}
          color={"#212B36"}
          fontFamily="Futura PT Book"
          fontWeight={200}
          textTranformation="capitalize"
        >
          {params.row.doorsCount}
        </Headings>
      ),
      // sortComparator: (v1, v2) => console.log("noOfDoors", v1.noOfDoors.localeCompare(""))
    },

    {
      field: "status",
      headerName: "Site Status",
      flex: 1,
      renderCell: (params) => {
        const { color, background } = getStatus(params.row.status);

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minWidth: "74px",
              height: "25px",
              backgroundColor: background,
              padding: "2px 20px",
            }}
          >
            <Headings
              fontFamily="Futura PT"
              color={color}
              fontSize={16}
              fontWeight={200}
              textTranformation="capitalize"
            >
              {getStatusMessage(params.row.status)}
            </Headings>
          </div>
        );
      },
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
        field: "actions",
        headerName: "",
        sortable: false,

        flex: 0.5,
        renderCell: (params) => (
          <ActionsDropdown
            siteId={params.row.data.id}
            onDelete={handleDelete}
          />
        ),
      }
      
  ];

  const mobileColumns = [
    {
      field: "data",
      headerName: "Site Name",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <Headings
              fontSize={16}
              color={"#212B36"}
              fontFamily="Futura PT Book"
              fontWeight={400}
              textTranformation="capitalize"
            >
              {params.row.data.name}
            </Headings>
            <Headings
              fontSize={16}
              color={"#212B36"}
              fontFamily="Futura PT Book"
              fontWeight={400}
              textTranformation="capitalize"
            >
              {params.row.data.address.split(" ")[3]}
            </Headings>
          </div>

        );
      },

      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    },
    {
      field: " ",
      headerName: " ",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%" }}>
          <div style={{ backgroundColor: "#F1F7FA ", width: "31px", height: "21px", borderRadius: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Headings
              fontSize={16}
              color={"#105998"}
              fontFamily="Futura PT Book"
              fontWeight={200}
              textTranformation="capitalize"
            >
              {params.row.sensorsCount}
            </Headings>
          </div>

        </div>

      ),
      // sortComparator: (v1, v2) => console.log("noOfDoors", v1.noOfDoors.localeCompare(""))
    },

    {
      field: "status",
      headerName: "Site Status",
      flex: 1,
      renderCell: (params) => {
        const { color, background } = getStatus(params.row.status);

        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 10, padding: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // minWidth: "74px",
                height: "25px",
                backgroundColor: background,
                padding: "2px 20px",
                boxSizing: "border-box"
              }}
            >
              <Headings
                fontFamily="Futura PT"
                color={color}
                fontSize={16}
                fontWeight={200}
                textTranformation="capitalize"
              >
                {getStatusMessage(params.row.status)}
              </Headings>
            </div>
            <Headings
              fontSize={16}
              color={"#212B36"}
              fontFamily="Futura PT Book"
              fontWeight={400}
              textTranformation="capitalize"
            >

              {params.row.data.sector}
            </Headings>
          </div>
        );
      },
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    // user?.roleId != process.env.REACT_APP_AGENT_ROLE_ID ||
    //   user?.roleId != process.env.REACT_APP_USER_ROLE_ID
    //   ? {
    //     field: "actions",
    //     headerName: "",
    //     sortable: false,

    //     flex: 0.5,
    //     renderCell: (params) => (
    //       <ActionsDropdown
    //         siteId={params.row.data.id}
    //         onDelete={handleDelete}
    //       />
    //     ),
    //   }
    //   : { sortable: false, flex: 0.5 },
  ];

  return (
    <div className="OverAllSites" fixed>
      <div className="searchspace">
        <div className="Heading">
          <Headings fontSize={24}>Sites List</Headings>
          {(user?.roleId == process.env.REACT_APP_ADMIN_ROLE_ID ||
            user?.roleId == process.env.REACT_APP_ORG_ADMIN_ROLE_ID) && (
              <button
                className="addSiteButton customButtomOverAll"
                onClick={() => navigate("/sites/addSite")}
              >
                <Headings>Add Site</Headings>
              </button>
            )}
        </div>
        <div className="inputdiv">
          <img src={search} alt="No search" className="searchimage" />
          <input
            className="searchinput"
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <Table
        rows={filteredSites}
        columns={matches ? mobileColumns : columns}
        rowSelection={false}
        columnVisibilityModel = {{
          organization: user.roles.id == process.env.REACT_APP_ADMIN_ROLE_ID ? true : false,
          actions: user.roles.id == process.env.REACT_APP_ADMIN_ROLE_ID ? true : false
          
        }}
        unique_identifier={"id"}
        loading={loading}
        handleRowClick={handleRowClick}
        matches={matches}
      />
    </div>
  );
};

const ActionsDropdown = ({ siteId, onDelete }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleView = () => {
    navigate(`/sites/${siteId}`);
  };

  const handleDeleteClick = () => {
    onDelete(siteId);
    setAnchorEl(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton variant="outlined" onClick={handleClick}>
        <img src={Edit} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon fontSize="small" />
          Delete
        </MenuItem>
        <MenuItem onClick={() => navigate(`/sites/edit/${siteId}`)}>
          <EditIcon fontSize="small" />
          Edit Site
        </MenuItem>
      </Menu>
    </div>
  );
};

const getStatus = (stat) => {
  let status = parseInt(stat);
  if (status >= 200 && status < 300) {
    return {
      color: "#34A853",
      background: "rgba(52, 168, 83, 0.1)",
    };
  } else if (status >= 300 && status < 400) {
    return {
      color: "rgba(221, 173, 1, 1)",
      background: "rgba(221, 173, 1, 0.1)",
    };
  } else if (status >= 400 && status < 500) {
    return {
      color: "#EB4335",
      background: "rgba(235, 67, 53, 0.1)",
    };
  } else {
    return {
      color: "#FFA500",
      background: "rgba(254, 216, 177, 0.1)",
    };
  }
};
