import React from "react";
import "./Door.css";
import DoorBackOutlinedIcon from "@mui/icons-material/DoorBackOutlined";
import DoorFrontOutlinedIcon from "@mui/icons-material/DoorFrontOutlined";
import { Headings } from "../../Shared/Fonts/Fonts";
import {getStatusMessage} from "../../Utils/Functions/getStatusMessages"


export const DoorCard = (props) => {
    const {
        status,
        last_inspection,
        due_inspection,
        week_Utilisation,
        lifetime_Utilisation,
        report_interval,
        open_interval,
        description,
        direction,
    doorType,
      } = props;
  const { color, background } = getStatus(status);
 
  return (
    <div className="doorCard">
      <div className="doorCardUpper">
        {direction == "CW" && <DoorFrontOutlinedIcon sx = {{color: "white"}} />}
        {direction == "CCW" && <DoorBackOutlinedIcon  sx = {{color: "white"}}/>}
      </div>
      <div className="doorCardDown">
        <div className="doorCardDownUp">
          <Headings color = {"white"} fontSize={24} fontWeight={450}>
            {description}
          </Headings>
          <Headings color = {"white"} fontSize={24} fontWeight={450}>
            {doorType}
          </Headings>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "74px",
            height: "25px",
            backgroundColor: background,
            padding: "2px 20px",
            borderRadius: "4px",
          }}
        >
          <Headings
            fontFamily="Futura PT"
            color={color}
            fontSize={16}
            fontWeight={200}
            textTranformation="capitalize"
          >
            {getStatusMessage(status)}
          </Headings>
        </div>
      </div>
    </div>
  );
};

const getStatus = (stat) => {
  let status = parseInt(stat);
  if (status >= 200 && status < 300) {
    return {
      color: "#34A853",
      background: "#E1FFE9",
    };
  } else if (status >= 300 && status < 400) {
    return {
      color: "rgba(221, 173, 1, 1)",
      background: "#FEFFE1",
    };
  } else if (status >= 400 && status < 500) {
    return {
      color: "#EB4335",
      background: "#FFE6E6",
    };
  } else {
    return {
      color: "#FFA500",
      background: "rgba(254, 216, 177, 0.1)",
    };
  }
};
