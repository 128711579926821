import React, { useEffect } from "react";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import "./Custom.css";
import { SubHeadings } from "../Fonts/Fonts";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Divider, Paper } from "@mui/material";
import { getStatusMessages } from "../../Utils/Functions/getStatusMessages";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const FloorHoverComponent = ({
  data,
  position,
  matches,
  setHoveredFloorId,
  floorId,
}) => {
  const navigate = useNavigate();
  const { siteId } = useParams();
  const { Component, color } = useStatus(data.status);
  const message = getStatusMessages(data.status).split("-");
  const hoverStyle = {
    position: "absolute",
    left: matches ? "0px" : position.x,
    top: matches ? "80%" : position.y,
    zIndex: 1,
  };

  useEffect(() => {
    console.log("message", message);
  }, [message]);

  return (
    <Paper className="floor-hover" style={hoverStyle} elevation={0}>
      <svg
        className="floorTip"
        style={{ position: "absolute", top: "50%", left: -13, zIndex: 0 }}
        width="18"
        height="27"
        viewBox="0 0 18 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_507_678)">
          <path
            d="M11.4596 20.4398L2.42993 11.4102L11.4596 2.38049V20.4398Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_507_678"
            x="0.429688"
            y="0.380371"
            width="17.0298"
            height="26.0596"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="2" dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_507_678"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_507_678"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
      {matches && (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <button
            className="addButton"
            onClick={() => navigate(`/sites/${siteId}/floor/${floorId}`)}
          >
            View Floor
          </button>
          <IconButton onClick={() => setHoveredFloorId(null)}>
            <CloseIcon />
          </IconButton>
        </div>
      )}
      <div className="flex-between">
        <div className="flex-flow">
          <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
            <SubHeadings color="black" fontSize="16px">
              Floor {data?.data.floor_no}
            </SubHeadings>
          </div>

          <Component />
        </div>
        <SubHeadings color={color} fontSize="16px">
          {getStatusMessages(data.status, true).split("-")[1]}
        </SubHeadings>
      </div>
      <Divider />
      <div className="flex-between">
        <SubHeadings color="black" fontSize="16px">
          Floor
        </SubHeadings>
        <SubHeadings color="black" fontSize="16px">
          {data?.data.description}
        </SubHeadings>
      </div>

      {/* Add more fields as needed */}
    </Paper>
  );
};

export default FloorHoverComponent;

const useStatus = (stat) => {
  if (stat >= 200 && stat < 300) {
    const Component = () => {
      return (
        <CheckCircleOutlineOutlinedIcon
          sx={{ width: 16, height: 16, color: "green" }}
        />
      );
    };
    return {
      Component,
      color: "green",
    };
  } else if (stat >= 300 && stat < 400) {
    const Component = () => {
      return (
        <WarningAmberIcon
          sx={{ color: "rgba(221, 173, 1, 1)", width: "16px", heigth: "16px" }}
        />
      );
    };
    return {
      Component,
      color: "rgba(221, 173, 1, 1)",
    };
  } else if (stat >= 400 && stat < 500) {
    const Component = () => {
      return (
        <WarningAmberIcon
          sx={{ color: "rgba(235, 67, 53, 1)", width: "16px", heigth: "16px" }}
        />
      );
    };
    return {
      Component,
      color: "red",
    };
  } else {
    const Component = () => {
      return (
        <CheckCircleOutlineOutlinedIcon
          sx={{ width: 16, height: 16, color: "#FFA500" }}
        />
      );
    };
    return {
      Component,
      color: "#FFA500",
    };
  }
};
