import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";

import DeleteIcon from "@mui/icons-material/Delete";
import "./Users.css";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import { useNavigate } from "react-router-dom";
import { Table } from "../../Components/Table/Tables";
import { Button, Menu, MenuItem } from "@mui/material";
import { getOrganizations, deleteOrganization } from "../../Store/Features/Customer";
import { useDispatch, useSelector } from "react-redux";
import search from "../../Assets/search.svg";
import Switch from '@mui/material/Switch';
import { IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Edit from "../../Assets/Edit.svg";


const Organization = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.customers);
  const organization = useSelector((state) => state.customers.value);
  const [searchQuery, setSearchQuery] = useState("");
  const matches = useMediaQuery("(max-width:769px)");

  // Function to handle changes in the search input
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter the sites array based on the search query
  const filteredUsers = organization?.filter((user) => {
    // Iterate through each field in the site object
    let each = false;
    for (const key in user) {
      if (user.hasOwnProperty(key)) {
     
        // address sector data status
        // description height width length top_gap bottom_gap left_gap right_gap direction createdById
        if (
          key == "id" ||
          key == "name" ||
          key == "email" ||
          key == "contact"

        ) {
          if (key == "roles") {
            const value = user[key].title + "";
            
            // Check if the field value contains the search query (case-insensitive)
            if (
              value &&
              value.toString().toLowerCase().match(searchQuery.toLowerCase())
            ) {
              each = true; // Return true if a match is found
            }
          } else {
            const value = user[key] + "";
           
            // Check if the field value contains the search query (case-insensitive)
            if (
              value &&
              value.toString().toLowerCase().match(searchQuery.toLowerCase())
            ) {
              each = true; // Return true if a match is found
            }
          }
        } else if (!searchQuery) {
          const value = user[key] + "";
      
          // Check if the field value contains the search query (case-insensitive)
          if (
            value &&
            value.toString().toLowerCase().match(searchQuery.toLowerCase())
          ) {
            each = true; // Return true if a match is found
          }
        }
      }
    }
    return each;
  });
  const handleDeleteUser = (id) => {
    dispatch(deleteOrganization(id)).then(() => {
      dispatch(getOrganizations());
    });
  };

  const columns = [
    // { field: "id", headerName: "ID", flex: 1, hide: true },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Headings
          fontSize={16}
          color={"#212B36"}
          fontFamily={"Futura PT Book"}
          fontWeight={400}
        >
          {params.row.name}
        </Headings>
      ),
    },
    { field: "contact", headerName: "Phone Number", flex: 1 },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => {
        return (
          <Headings
            fontSize={16}
            color={"#212B36"}
            fontfamily={"Futura PT Book"}
            fontWeight={400}
          >
            {params.row.email}
          </Headings>
        );
      },
    },

    // {
    //   field: "disabled",
    //   headerName: "Disabled",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <Switch checked={params.row.disabled} onChange={(e) => {
    //         const { id, ...temp } = params.row;
    //         dispatch(updateOr({
    //           id: id, data: {
    //             "firstName": temp.firstName,
    //             "lastName": temp.lastName,
    //             "phoneNumber": temp.phoneNumber,
    //             "email": temp.email,
    //             "role": temp.role, "disabled": e.target.checked,
    //             "roles": temp.roleId
    //           }
    //         }));
    //       }} />
    //     );
    //   },
    // },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <ActionsDropdown userId={params.row.id} onDelete={handleDeleteUser} />
      ),
    },
  ];

  const mobileColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Headings
            fontSize={16}
            color={"#212B36"}
            fontFamily={"Futura PT"}
            fontWeight={400}
          >
            {params.row.name}
          </Headings>
          <Headings
            fontSize={16}
            color={"#212B36"}
            fontFamily={"Futura PT"}
            fontWeight={400}
          >
            {params.row.email}
          </Headings>
        </div>

      ),
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Headings
              fontSize={16}
              color={"#212B36"}
              fontfamily={"Futura PT"}
              fontWeight={400}
            >
              {params.row.address}
            </Headings>
            <Headings
              fontSize={16}
              color={"#212B36"}
              fontfamily={"Futura PT Book"}
              fontWeight={400}
            >
              {params.row.contact}
            </Headings>
          </div>

        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.2,
      renderCell: (params) => (
        <ActionsDropdown userId={params.row.id} onDelete={handleDeleteUser} />
      ),
    },
  ];

  const handleRowClick = (e) => {
    navigate(`/organizations/${e.row.id}/users`);
  };

  useEffect(() => {
    dispatch(getOrganizations());
  }, []);

  useEffect(() => {
    document.title = "Users";
  }, []);

  return (
    <div className="OverAllSites">
      <div className="searchspace">
        <div className="Heading">
          <Headings fontSize={24}>Organisations</Headings>
          <button
            className="addSiteButton customButtomOverAll"
            onClick={() => navigate("/organizations/add")}
          >
            <Headings>Add Organisation</Headings>
          </button>
        </div>
        <div className="inputdiv">
          <img src={search} alt="No search" className="searchimage" />
          <input
            className="searchinput"
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}

          />
        </div>
      </div>
      <div style={{ height: 400, width: "100%" }}>
        <Table
          rows={filteredUsers || Organization}
          columns={matches ? mobileColumns : columns}
          rowSelection={false}
          loading={loading}
          handleRowClick={handleRowClick}
          matches={matches}
        />
      </div>
    </div>
  );
};

export default Organization;

const ActionsDropdown = ({ userId, onDelete }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    onDelete(userId);
    handleClose();
  };

  return (
    <div>
      <IconButton variant="outlined" onClick={handleClick}>
        <img src={Edit} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon fontSize="small" />
          Delete
        </MenuItem>
        <MenuItem onClick={() => navigate(`/organizations/${userId}`)}>
          <EditIcon fontSize="small" />
          Edit
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>
          <VisibilityIcon fontSize="small" />
          View
        </MenuItem> */}
      </Menu>
    </div>
  );
};
