import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import "./SidePanels.css";
import { Headings, SubHeadings, Text } from '../../Shared/Fonts/Fonts';



export default function SiteDrawer(props) {

  const { open, setOpen, site } = props;


  const list = (name, country, installDate, solution, sector) => (
    <Box
      sx={{ width: 300, padding: "10px" }}
      role="presentation"
      className="NotificationDrawer"
    >

      <div className="marker-layout">
        <div className="HeadingMarker">
          <div className="HeadingAddress">
            <Headings fontSize={16}>{name}</Headings>
            <IconButton aria-label="delete" color="primary" onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <SubHeadings fontSize={10} color={"#aeb3bc"} >{country}</SubHeadings>

        </div>
        <Divider />
        <div className="BottomSection">
          <div
            className="fx-space"
          >
            <SubHeadings fontSize={10} color={"#aeb3bc"} >Install Date</SubHeadings>
            <SubHeadings fontSize={10} color={"black"} >{site?.installDate}</SubHeadings>
          </div>
          <div
            className="fx-space"
          >
            <SubHeadings fontSize={10} color={"#aeb3bc"} >Solution</SubHeadings>
            <SubHeadings fontSize={10} color={"black"} >{solution}</SubHeadings>
          </div>

          <div
            className="fx-space"
          >
            <SubHeadings fontSize={10} color={"#aeb3bc"} >Sector</SubHeadings>
            <SubHeadings fontSize={10} color={"black"} >{sector}</SubHeadings>
          </div>
        </div>
      </div>
    </Box >
  );

  return (
    <Drawer
      anchor="right"
      open={open}
      hideBackdrop={true}
      sx={{ width: "300px", height: "100%", zIndex: 999999, padding: "20px" }}
    >
      {list("Test Site", "England", "11-21-2005", "fire Door", "Residential")}
    </Drawer>
  );
}